import { Button, Flex, Grid, Menu, MenuButton, MenuList, Stack, Text } from '@chakra-ui/react'
import APP_ROUTES from 'constant/APP_ROUTES'
import COLOR from 'constant/COLOR'
import images from 'constant/PICTURE/images'
import useResponsive from 'hooks/useResponsive'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'

interface Slide {
  name: string
  image: string
  price: string
  dark: string
  description: string
  itemsChar: {
    knight: string
    archer: string
  }
  normal: string
  rare: string
  epic: string
  legendary: string
  mythic: string
  character: string
  items: {
    eLv1: string
    elv2: string
    elv3: string
    HP: string
    MP: string
    citrine: string
    amethyst: string
    ruby: string
    charmX2: string
    charmX3: string
    colorStone: string
  }
  item: string
}

interface CustomSlideProps {
  slides: Slide[]
}
const mover = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
`
const items = [
  {
    key: 'character',
    order: 1,
    id: 1,
    tab: 'Characters',
  },
  {
    key: 'item',
    order: -1,
    id: 2,
    tab: 'Items',
  },
]
const AnimatedImage = styled.img`
  animation: ${mover} 1s infinite alternate;
  transform: translateY(-20px);
`
const CustomSlide: React.FC<CustomSlideProps> = ({ slides }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  const { isMobile, isMobileOrTablet, isTabletOrLaptop, isBigScreen } = useResponsive()
  const [title, setTitle] = useState('Characters')
  const [filter, setFilter] = useState(1)

  const goToNextSlide = () => {
    setCurrentSlideIndex((prevIndex) => (prevIndex === slides.length - 1 ? 0 : prevIndex + 1))
  }

  const goToPrevSlide = () => {
    setCurrentSlideIndex((prevIndex) => (prevIndex === 0 ? slides.length - 1 : prevIndex - 1))
  }

  return (
    <Stack>
      <Flex justifyContent="center" alignItems="center" gap="20px" direction={isTabletOrLaptop ? 'column' : 'row'}>
        <Flex alignItems="center" gap={isMobile ? 0 : '20px'}>
          <Stack
            transition="all 235ms ease-in-out"
            _hover={{
              transform: 'translate3d(0, 2px, 0)',
            }}
            cursor="pointer"
            onClick={goToPrevSlide}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="45" height="64" viewBox="0 0 45 64" fill="none">
              <g filter="url(#filter0_d_2103_3255)">
                <path
                  d="M9.9997 31.9997C9.9997 31.4998 10.1498 30.9999 10.425 30.625L22.9247 11.875C23.3746 11.1998 24.1249 10.7498 24.9997 10.7498H32.4997C33.8748 10.7498 35 11.875 35 13.2497C35 13.75 34.8499 14.2499 34.5747 14.6248L22.9997 31.9997L34.5747 49.375C34.8499 49.7499 35 50.2498 35 50.7497C35 52.1248 33.8748 53.25 32.4997 53.25H24.9997C24.1249 53.25 23.3746 52.8 22.9247 52.1248L10.425 33.3748C10.1498 32.9999 9.9997 32.5 9.9997 31.9997Z"
                  fill="#00EFFF"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_2103_3255"
                  x="0"
                  y="0.749756"
                  width="45"
                  height="62.5002"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset />
                  <feGaussianBlur stdDeviation="5" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.937255 0 0 0 0 1 0 0 0 1 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2103_3255" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2103_3255" result="shape" />
                </filter>
              </defs>
            </svg>
          </Stack>

          <AnimatedImage
            style={{
              width: isMobile ? '210px' : '100%',
              height: isMobile ? '210px' : '100%',
            }}
            src={slides[currentSlideIndex].image}
            alt="Slide"
          />
          <Stack
            transition="all 235ms ease-in-out"
            _hover={{
              transform: 'translate3d(0, 2px, 0)',
            }}
            cursor="pointer"
            onClick={goToNextSlide}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="45" height="63" viewBox="0 0 45 63" fill="none">
              <g filter="url(#filter0_d_2103_2987)">
                <path
                  d="M35.0003 31.5003C35.0003 32.0002 34.8502 32.5001 34.575 32.875L22.0753 51.625C21.6254 52.3002 20.8751 52.7502 20.0003 52.7502H12.5003C11.1252 52.7502 10 51.625 10 50.2503C10 49.75 10.1501 49.2501 10.4253 48.8752L22.0003 31.5003L10.4253 14.125C10.1501 13.7501 10 13.2502 10 12.7503C10 11.3752 11.1252 10.25 12.5003 10.25H20.0003C20.8751 10.25 21.6254 10.7 22.0753 11.3752L34.575 30.1252C34.8502 30.5001 35.0003 31 35.0003 31.5003Z"
                  fill="#00EFFF"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_2103_2987"
                  x="0"
                  y="0.25"
                  width="45"
                  height="62.5002"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset />
                  <feGaussianBlur stdDeviation="5" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.937255 0 0 0 0 1 0 0 0 1 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2103_2987" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2103_2987" result="shape" />
                </filter>
              </defs>
            </svg>
          </Stack>
        </Flex>
        <Stack p="20px">
          <Text color={COLOR.white} fontSize="22px" fontWeight={600} fontFamily="Anek bangla">
            {slides[currentSlideIndex].name}
          </Text>
          <Text color={COLOR.white} fontSize="16px" fontWeight={600} fontFamily="Anek bangla">
            Probability of obtaining:
          </Text>
          <Menu>
            <MenuButton
              _hover={{ bg: COLOR.bgButton }}
              as={Button}
              bg={COLOR.bgButton}
              border="none"
              borderRadius="8px"
              p="22px 10px"
              // rightIcon={<ArrowBox />}
              h="4px"
              w="200px"
              cursor="pointer"
              colorScheme="none"
            >
              <Flex justifyContent="space-between" alignItems="center">
                <Flex gap="5px">
                  <Text
                    sx={{
                      color: COLOR.white,
                    }}
                    fontSize="12px"
                    fontWeight={400}
                    fontFamily="Anek Bangla"
                    textTransform="uppercase"
                  >
                    {title}
                  </Text>
                  <span>
                    {title === 'Characters' && (
                      <Text
                        sx={{
                          color: COLOR.white,
                        }}
                        fontSize="12px"
                        fontWeight={400}
                        fontFamily="Anek Bangla"
                        textTransform="uppercase"
                      >
                        {slides[currentSlideIndex].character}
                      </Text>
                    )}
                    {title === 'Items' && (
                      <Text
                        sx={{
                          color: COLOR.white,
                        }}
                        fontSize="12px"
                        fontWeight={400}
                        fontFamily="Anek Bangla"
                        textTransform="uppercase"
                      >
                        {slides[currentSlideIndex].item}
                      </Text>
                    )}
                  </span>
                </Flex>

                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                  <path
                    d="M1.56986 7.23635L6.38236 12.0489C6.5463 12.2118 6.76807 12.3033 6.99923 12.3033C7.2304 12.3033 7.45217 12.2118 7.61611 12.0489L12.4286 7.23635C12.5797 7.07017 12.6611 6.85225 12.656 6.62769C12.6509 6.40313 12.5596 6.18915 12.4011 6.03004C12.2425 5.87093 12.0289 5.77889 11.8043 5.77297C11.5798 5.76705 11.3616 5.8477 11.1949 5.99823L6.99923 10.1939L2.80361 5.99823C2.72386 5.91053 2.62713 5.83996 2.51928 5.79078C2.41144 5.7416 2.29472 5.71485 2.17622 5.71214C2.05773 5.70944 1.93991 5.73083 1.82993 5.77504C1.71995 5.81924 1.6201 5.88533 1.53644 5.96929C1.45277 6.05325 1.38704 6.15334 1.34322 6.26347C1.29941 6.37361 1.27843 6.4915 1.28155 6.60999C1.28468 6.72847 1.31185 6.84509 1.36141 6.95277C1.41096 7.06044 1.48188 7.15692 1.56986 7.23635Z"
                    fill="url(#paint0_linear_2146_2859)"
                  />
                  <path
                    d="M6.38389 8.00177C6.54783 8.16474 6.7696 8.25622 7.00077 8.25622C7.23193 8.25622 7.4537 8.16474 7.61764 8.00177L12.4301 3.18927C12.5813 3.0231 12.6627 2.80517 12.6576 2.58061C12.6524 2.35606 12.5611 2.14207 12.4026 1.98296C12.2441 1.82386 12.0304 1.73181 11.8059 1.72589C11.5813 1.71997 11.3631 1.80062 11.1964 1.95115L7.00077 6.14677L2.80514 1.95115C2.63843 1.80062 2.42022 1.71997 2.19568 1.72589C1.97114 1.73181 1.75748 1.82386 1.59894 1.98296C1.44039 2.14207 1.34911 2.35606 1.34398 2.58061C1.33885 2.80517 1.42027 3.0231 1.57139 3.18927L6.38389 8.00177Z"
                    fill="url(#paint1_linear_2146_2859)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_2146_2859"
                      x1="6.96875"
                      y1="5.71191"
                      x2="6.96875"
                      y2="12.3033"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#00A1FC" stopOpacity="0" />
                      <stop offset="0.0001" stopColor="#00C9FF" />
                      <stop offset="0.348321" stopColor="#00F0FF" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_2146_2859"
                      x1="7.00077"
                      y1="1.72559"
                      x2="7.00077"
                      y2="8.25622"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#00A1FC" stopOpacity="0" />
                      <stop offset="0.0001" stopColor="#00C9FF" />
                      <stop offset="0.348321" stopColor="#00F0FF" />
                    </linearGradient>
                  </defs>
                </svg>
              </Flex>
            </MenuButton>

            <MenuList borderRadius="12px" p="30px" w="full" zIndex={1} bg={COLOR.bgButton}>
              <Stack>
                {items.map((item, index) => (
                  <Stack key={index}>
                    <Stack
                      transition="all 235ms ease-in-out"
                      _hover={{
                        cursor: 'pointer',
                        padding: '10px',
                      }}
                      onClick={() => {
                        setFilter(item.id)
                        setTitle(item.tab)
                      }}
                      cursor="pointer"
                    >
                      <p
                        style={{
                          padding: '0 20px',
                          borderRadius: '12px',
                        }}
                      >
                        <Stack direction="row" alignItems="center">
                          <Text
                            sx={{
                              color: COLOR.white,
                            }}
                            fontSize="12px"
                            fontWeight={400}
                            fontFamily="Anek Bangla"
                            textTransform="uppercase"
                          >
                            {item.tab}{' '}
                          </Text>
                          {item.tab === 'Characters' && (
                            <Text
                              sx={{
                                color: COLOR.white,
                              }}
                              fontSize="12px"
                              fontWeight={400}
                              fontFamily="Anek Bangla"
                              textTransform="uppercase"
                            >
                              {slides[currentSlideIndex].character}
                            </Text>
                          )}
                          {item.tab === 'Items' && (
                            <Text
                              sx={{
                                color: COLOR.white,
                              }}
                              fontSize="12px"
                              fontWeight={400}
                              fontFamily="Anek Bangla"
                              textTransform="uppercase"
                            >
                              {slides[currentSlideIndex].item}
                            </Text>
                          )}
                        </Stack>
                      </p>
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            </MenuList>
          </Menu>
          {title === 'Items' && (
            <Grid gridTemplateColumns={isMobile ? '1fr' : '1fr 1fr'} ml="10px" gridColumnGap="10px">
              {currentSlideIndex === 2 ? (
                <></>
              ) : (
                <Flex gap="10px">
                  <Text color={COLOR.white} fontSize="16px" fontWeight={400} fontFamily="Anek bangla">
                    <span style={{ marginRight: '10px' }}>&bull;</span>Equipment Lv.1
                  </Text>
                  <Text color={COLOR.white} fontSize="16px" fontWeight={400} fontFamily="Anek bangla">
                    {slides[currentSlideIndex].items.eLv1}
                  </Text>
                </Flex>
              )}
              {currentSlideIndex === 2 ? (
                <></>
              ) : (
                <Flex gap="10px">
                  <Text color={COLOR.white} fontSize="16px" fontWeight={400} fontFamily="Anek bangla">
                    <span style={{ marginRight: '10px' }}>&bull;</span> Equipment Lv.2
                  </Text>
                  <Text color={COLOR.white} fontSize="16px" fontWeight={400} fontFamily="Anek bangla">
                    {slides[currentSlideIndex].items.elv2}
                  </Text>
                </Flex>
              )}
              {currentSlideIndex === 2 ? (
                <></>
              ) : (
                <Flex gap="10px">
                  <Text color={COLOR.white} fontSize="16px" fontWeight={400} fontFamily="Anek bangla">
                    <span style={{ marginRight: '10px' }}>&bull;</span> Equipment Lv.3
                  </Text>
                  <Text color={COLOR.white} fontSize="16px" fontWeight={400} fontFamily="Anek bangla">
                    {slides[currentSlideIndex].items.elv3}
                  </Text>
                </Flex>
              )}
              {currentSlideIndex === 2 ? (
                <></>
              ) : (
                <Flex gap="10px">
                  <Text color={COLOR.white} fontSize="16px" fontWeight={400} fontFamily="Anek bangla">
                    <span style={{ marginRight: '10px' }}>&bull;</span>Recovery Vial HP (Special)
                  </Text>
                  <Text color={COLOR.white} fontSize="16px" fontWeight={400} fontFamily="Anek bangla">
                    {slides[currentSlideIndex].items.HP}
                  </Text>
                </Flex>
              )}
              {currentSlideIndex === 2 ? (
                <></>
              ) : (
                <Flex gap="10px">
                  <Text color={COLOR.white} fontSize="16px" fontWeight={400} fontFamily="Anek bangla">
                    <span style={{ marginRight: '10px' }}>&bull;</span> Recovery Vial MP (Special)
                  </Text>
                  <Text color={COLOR.white} fontSize="16px" fontWeight={400} fontFamily="Anek bangla">
                    {slides[currentSlideIndex].items.MP}
                  </Text>
                </Flex>
              )}
              <Flex gap="10px">
                <Text color={COLOR.white} fontSize="16px" fontWeight={400} fontFamily="Anek bangla">
                  <span style={{ marginRight: '10px' }}>&bull;</span> Citrine Lv.3
                </Text>
                <Text color={COLOR.white} fontSize="16px" fontWeight={400} fontFamily="Anek bangla">
                  {slides[currentSlideIndex].items.citrine}
                </Text>
              </Flex>{' '}
              <Flex gap="10px">
                <Text color={COLOR.white} fontSize="16px" fontWeight={400} fontFamily="Anek bangla">
                  <span style={{ marginRight: '10px' }}>&bull;</span> Amethyst Lv.3
                </Text>
                <Text color={COLOR.white} fontSize="16px" fontWeight={400} fontFamily="Anek bangla">
                  {slides[currentSlideIndex].items.amethyst}
                </Text>
              </Flex>{' '}
              <Flex gap="10px">
                <Text color={COLOR.white} fontSize="16px" fontWeight={400} fontFamily="Anek bangla">
                  <span style={{ marginRight: '10px' }}>&bull;</span> Ruby Lv.3
                </Text>
                <Text color={COLOR.white} fontSize="16px" fontWeight={400} fontFamily="Anek bangla">
                  {slides[currentSlideIndex].items.ruby}
                </Text>
              </Flex>{' '}
              <Flex gap="10px">
                <Text color={COLOR.white} fontSize="16px" fontWeight={400} fontFamily="Anek bangla">
                  <span style={{ marginRight: '10px' }}>&bull;</span> Charm x2 EXP
                </Text>
                <Text color={COLOR.white} fontSize="16px" fontWeight={400} fontFamily="Anek bangla">
                  {slides[currentSlideIndex].items.charmX2}
                </Text>
              </Flex>{' '}
              <Flex gap="10px">
                <Text color={COLOR.white} fontSize="16px" fontWeight={400} fontFamily="Anek bangla">
                  <span style={{ marginRight: '10px' }}>&bull;</span> Charm x3 EXP
                </Text>
                <Text color={COLOR.white} fontSize="16px" fontWeight={400} fontFamily="Anek bangla">
                  {slides[currentSlideIndex].items.charmX3}
                </Text>
              </Flex>{' '}
              <Flex gap="10px">
                <Text color={COLOR.white} fontSize="16px" fontWeight={400} fontFamily="Anek bangla">
                  <span style={{ marginRight: '10px' }}>&bull;</span> Seven-color Stone Lv.5
                </Text>
                <Text color={COLOR.white} fontSize="16px" fontWeight={400} fontFamily="Anek bangla">
                  {slides[currentSlideIndex].items.colorStone}
                </Text>
              </Flex>
            </Grid>
          )}
          {title === 'Characters' && (
            <Grid gridTemplateColumns="1fr" ml="10px" gridColumnGap="10px">
              <Flex gap="10px">
                <Text color={COLOR.white} fontSize="16px" fontWeight={400} fontFamily="Anek bangla">
                  <span style={{ marginRight: '10px' }}>&bull;</span>Knight
                </Text>
                <Text color={COLOR.white} fontSize="16px" fontWeight={400} fontFamily="Anek bangla">
                  {slides[currentSlideIndex].itemsChar.knight}
                </Text>
              </Flex>
              <Flex gap="10px">
                <Text color={COLOR.white} fontSize="16px" fontWeight={400} fontFamily="Anek bangla">
                  <span style={{ marginRight: '10px' }}>&bull;</span>Archer
                </Text>
                <Text color={COLOR.white} fontSize="16px" fontWeight={400} fontFamily="Anek bangla">
                  {slides[currentSlideIndex].itemsChar.archer}
                </Text>
              </Flex>
            </Grid>
          )}

          <Flex gap="5px" alignItems="center" justifyContent="center">
            <img style={{ width: '30px' }} src={images.darkIcon} />

            <Text
              sx={{
                background:
                  'linear-gradient(90deg, #E86E00 -1.64%, #F18900 3.29%, #F99F00 9.22%, #FDAC00 14.15%, #FFB100 19.09%, #E86E00 97.07%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',

                fontFamily: 'Anek Bangla',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: '140%',
              }}
            >
              {slides[currentSlideIndex].description}
            </Text>
          </Flex>
          <Link
            to={APP_ROUTES.getBox}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              p="15px 20px"
              w="130px"
              borderRadius="12px"
              border={`1px solid ${COLOR.bttGetBox}`}
              bg={COLOR.bgButton}
              color={COLOR.bttGetBox}
              _hover={{ bg: 'none' }}
            >
              <Text color={COLOR.bttGetBox} fontFamily="Anek Bangla" fontSize="18px" fontWeight={600}>
                Get Box
              </Text>
            </Button>
          </Link>
        </Stack>
      </Flex>
      <Stack p="20px">
        <Flex alignItems="center" gap="10px">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.32422 10.8825L3.94922 16.728L7.84622 18.978L11.2212 13.1325L7.32422 10.8825ZM8.07422 9.5835L11.9712 11.8335L12.3462 11.184C12.6447 10.6673 12.7257 10.053 12.5712 9.47701C12.4167 8.90026 12.0395 8.40901 11.5227 8.11051C11.006 7.81201 10.3917 7.731 9.81572 7.8855C9.23897 8.04 8.74772 8.41726 8.44922 8.93401L8.07422 9.5835Z"
              fill="url(#paint0_linear_2103_3095)"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.4081 18.1484L2.9041 21.6434C2.8606 21.9472 3.0061 22.2464 3.2716 22.3994C3.5371 22.5532 3.86935 22.5292 4.11085 22.3394L6.88585 20.1562L3.4081 18.1484Z"
              fill="url(#paint1_linear_2103_3095)"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.39952 14.679L6.71076 8.94445C5.79951 8.77495 4.84027 9.1837 4.35052 10.0319L2.10052 13.929C1.89352 14.2875 2.01652 14.7465 2.37502 14.9535C2.73352 15.1605 3.19252 15.0375 3.39952 14.679Z"
              fill="url(#paint2_linear_2103_3095)"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19.718 22.3358C19.718 22.3358 21.2855 21.0833 22.1547 20.3896C22.6887 19.9621 23 19.3156 23 18.6316C23 16.5803 23 10.6531 23 7.36582C23 5.69182 21.89 4.22033 20.2805 3.76058C17.8985 3.07958 14.2137 2.02732 12.6725 1.58632C11.9952 1.39282 11.267 1.52782 10.7037 1.95008C9.82921 2.60633 8.29996 3.75307 8.29996 3.75307C7.96846 4.00132 7.90171 4.47158 8.14996 4.80308C8.39821 5.13458 8.86846 5.20132 9.19996 4.95307L11.6037 3.15007C11.792 3.00907 12.0342 2.96407 12.26 3.02857L19.868 5.20282C20.834 5.47882 21.5 6.36157 21.5 7.36582V18.6316C21.5 18.8596 21.3965 19.0748 21.218 19.2173C20.3495 19.9118 18.782 21.1643 18.782 21.1643C18.4587 21.4223 18.4055 21.8948 18.6642 22.2181C18.9222 22.5413 19.3947 22.5946 19.718 22.3358Z"
              fill="url(#paint3_linear_2103_3095)"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 7.17542C8.4125 6.83192 8.8985 6.57842 9.4265 6.43667C10.3873 6.17942 11.411 6.31367 12.272 6.81092C13.1337 7.30817 13.7623 8.12793 14.0195 9.08793C14.2768 10.0487 14.1418 11.0724 13.6445 11.9342L9.2105 19.6142C9.34325 19.6832 9.48425 19.7402 9.632 19.7822C12.317 20.5494 19.0438 22.4717 19.0438 22.4717C19.2703 22.5362 19.514 22.4904 19.7015 22.3487C19.8898 22.2069 20 21.9857 20 21.7502V8.63192C20 7.62692 19.334 6.74417 18.368 6.46817C15.683 5.70092 8.95625 3.77867 8.95625 3.77867C8.72975 3.71417 8.486 3.75992 8.2985 3.90167C8.11025 4.04342 8 4.26467 8 4.50017V7.17542Z"
              fill="url(#paint4_linear_2103_3095)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_2103_3095"
                x1="8.29855"
                y1="7.80884"
                x2="8.29855"
                y2="18.978"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#00A1FC" stopOpacity="0" />
                <stop offset="0.0001" stopColor="#00C9FF" />
                <stop offset="0.348321" stopColor="#00F0FF" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_2103_3095"
                x1="4.89117"
                y1="18.1484"
                x2="4.89117"
                y2="22.5002"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#00A1FC" stopOpacity="0" />
                <stop offset="0.0001" stopColor="#00C9FF" />
                <stop offset="0.348321" stopColor="#00F0FF" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_2103_3095"
                x1="4.35538"
                y1="8.90674"
                x2="4.35538"
                y2="15.054"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#00A1FC" stopOpacity="0" />
                <stop offset="0.0001" stopColor="#00C9FF" />
                <stop offset="0.348321" stopColor="#00F0FF" />
              </linearGradient>
              <linearGradient
                id="paint3_linear_2103_3095"
                x1="15.5"
                y1="1.49976"
                x2="15.5"
                y2="22.4999"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#00A1FC" stopOpacity="0" />
                <stop offset="0.0001" stopColor="#00C9FF" />
                <stop offset="0.348321" stopColor="#00F0FF" />
              </linearGradient>
              <linearGradient
                id="paint4_linear_2103_3095"
                x1="14"
                y1="3.75"
                x2="14"
                y2="22.5003"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#00A1FC" stopOpacity="0" />
                <stop offset="0.0001" stopColor="#00C9FF" />
                <stop offset="0.348321" stopColor="#00F0FF" />
              </linearGradient>
            </defs>
          </svg>
          <Text
            sx={{
              fontFamily: 'Anek Bangla',
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: '600',
            }}
          >
            Note:
          </Text>
        </Flex>
        <Flex gap="10px">
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="20" viewBox="0 0 18 15" fill="none">
            <path
              d="M12.5141 15C11.8192 15 11.162 14.8685 10.5516 14.6056C9.9507 14.3427 9.41549 13.9859 8.96479 13.5446C8.51408 13.0939 8.15728 12.5587 7.89437 11.9577C7.63146 11.3474 7.5 10.6901 7.5 9.99531C7.5 9.30047 7.63146 8.66197 7.89437 8.06103C8.14789 7.46009 8.5047 6.92488 8.9554 6.47418C9.4061 6.01408 9.94131 5.64789 10.5423 5.39437C11.1526 5.13146 11.8099 5 12.5047 5C13.1995 5 13.838 5.13146 14.439 5.39437C15.0399 5.65728 15.5751 6.01408 16.0352 6.46479C16.4953 6.92488 16.8521 7.46009 17.115 8.06103C17.3779 8.67136 17.5094 9.31925 17.5094 9.99531C17.5094 10.6714 17.3779 11.3474 17.115 11.9577C16.8521 12.5587 16.4953 13.0939 16.0352 13.5446C15.5845 13.9953 15.0493 14.3427 14.4484 14.6056C13.8474 14.8685 13.1901 15 12.5141 15Z"
              fill="#0B1B2B"
            />
            <path
              d="M8.43945 9.99604C8.43945 9.44204 8.54274 8.92561 8.7587 8.42796C8.97467 7.93031 9.25636 7.49838 9.62255 7.13219C9.98875 6.7566 10.4207 6.46552 10.9089 6.25895C11.4066 6.04298 11.9324 5.9397 12.4958 5.9397C13.0592 5.9397 13.5662 6.04298 14.0639 6.25895C14.5615 6.47491 14.9934 6.76599 15.3596 7.13219C15.7352 7.50777 16.0263 7.9397 16.2329 8.42796C16.4488 8.92561 16.5521 9.44204 16.5521 9.99604C16.5521 10.55 16.4488 11.0946 16.2329 11.5829C16.0169 12.0805 15.7258 12.5031 15.3596 12.8693C14.9841 13.2355 14.5521 13.5266 14.0639 13.7331C13.5662 13.9491 13.0498 14.0524 12.4958 14.0524C11.9418 14.0524 11.3972 13.9491 10.9089 13.7331C10.4113 13.5172 9.98875 13.2355 9.62255 12.8693C9.25636 12.5031 8.96528 12.0712 8.7587 11.5829C8.55213 11.0946 8.43945 10.5594 8.43945 9.99604Z"
              fill="url(#paint0_linear_2103_3134)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_2103_3134"
                x1="12.4958"
                y1="5.9397"
                x2="12.4958"
                y2="14.0524"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#00A1FC" stopOpacity="0" />
                <stop offset="0.0001" stopColor="#00C9FF" />
                <stop offset="0.348321" stopColor="#00F0FF" />
              </linearGradient>
            </defs>
          </svg>
          <Text
            sx={{
              fontFamily: 'Anek Bangla',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '400',
              color: COLOR.white,
            }}
          >
            The special offer when opening a box also includes the chance to receive bonus gold at the following rates:
            <span
              style={{
                fontWeight: 600,
              }}
            >
              100 Gold (84%), 1.000 Gold (10%), 10.000 Gold (5%), 100.000 Gold (0.9%), 1.000.000 Gold (0.1%).
            </span>{' '}
          </Text>
        </Flex>
        <Flex gap="10px">
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="20" viewBox="0 0 18 15" fill="none">
            <path
              d="M12.5141 15C11.8192 15 11.162 14.8685 10.5516 14.6056C9.9507 14.3427 9.41549 13.9859 8.96479 13.5446C8.51408 13.0939 8.15728 12.5587 7.89437 11.9577C7.63146 11.3474 7.5 10.6901 7.5 9.99531C7.5 9.30047 7.63146 8.66197 7.89437 8.06103C8.14789 7.46009 8.5047 6.92488 8.9554 6.47418C9.4061 6.01408 9.94131 5.64789 10.5423 5.39437C11.1526 5.13146 11.8099 5 12.5047 5C13.1995 5 13.838 5.13146 14.439 5.39437C15.0399 5.65728 15.5751 6.01408 16.0352 6.46479C16.4953 6.92488 16.8521 7.46009 17.115 8.06103C17.3779 8.67136 17.5094 9.31925 17.5094 9.99531C17.5094 10.6714 17.3779 11.3474 17.115 11.9577C16.8521 12.5587 16.4953 13.0939 16.0352 13.5446C15.5845 13.9953 15.0493 14.3427 14.4484 14.6056C13.8474 14.8685 13.1901 15 12.5141 15Z"
              fill="#0B1B2B"
            />
            <path
              d="M8.43945 9.99604C8.43945 9.44204 8.54274 8.92561 8.7587 8.42796C8.97467 7.93031 9.25636 7.49838 9.62255 7.13219C9.98875 6.7566 10.4207 6.46552 10.9089 6.25895C11.4066 6.04298 11.9324 5.9397 12.4958 5.9397C13.0592 5.9397 13.5662 6.04298 14.0639 6.25895C14.5615 6.47491 14.9934 6.76599 15.3596 7.13219C15.7352 7.50777 16.0263 7.9397 16.2329 8.42796C16.4488 8.92561 16.5521 9.44204 16.5521 9.99604C16.5521 10.55 16.4488 11.0946 16.2329 11.5829C16.0169 12.0805 15.7258 12.5031 15.3596 12.8693C14.9841 13.2355 14.5521 13.5266 14.0639 13.7331C13.5662 13.9491 13.0498 14.0524 12.4958 14.0524C11.9418 14.0524 11.3972 13.9491 10.9089 13.7331C10.4113 13.5172 9.98875 13.2355 9.62255 12.8693C9.25636 12.5031 8.96528 12.0712 8.7587 11.5829C8.55213 11.0946 8.43945 10.5594 8.43945 9.99604Z"
              fill="url(#paint0_linear_2103_3134)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_2103_3134"
                x1="12.4958"
                y1="5.9397"
                x2="12.4958"
                y2="14.0524"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#00A1FC" stopOpacity="0" />
                <stop offset="0.0001" stopColor="#00C9FF" />
                <stop offset="0.348321" stopColor="#00F0FF" />
              </linearGradient>
            </defs>
          </svg>
          <Text
            sx={{
              fontFamily: 'Anek Bangla',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '400',
              color: COLOR.white,
            }}
          >
            Each opened box is equivalent to the user having invested early in the{' '}
            <span
              style={{
                background: COLOR.bttLinear,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontWeight: 600,
              }}
            >
              $DARK
            </span>{' '}
            token at the listing price. When a user sells their box to a new participant, the new participant will
            receive this token.
          </Text>
        </Flex>
        <Flex gap="10px">
          <svg xmlns="http://www.w3.org/2000/svg" width="35" height="20" viewBox="0 0 18 15" fill="none">
            <path
              d="M12.5141 15C11.8192 15 11.162 14.8685 10.5516 14.6056C9.9507 14.3427 9.41549 13.9859 8.96479 13.5446C8.51408 13.0939 8.15728 12.5587 7.89437 11.9577C7.63146 11.3474 7.5 10.6901 7.5 9.99531C7.5 9.30047 7.63146 8.66197 7.89437 8.06103C8.14789 7.46009 8.5047 6.92488 8.9554 6.47418C9.4061 6.01408 9.94131 5.64789 10.5423 5.39437C11.1526 5.13146 11.8099 5 12.5047 5C13.1995 5 13.838 5.13146 14.439 5.39437C15.0399 5.65728 15.5751 6.01408 16.0352 6.46479C16.4953 6.92488 16.8521 7.46009 17.115 8.06103C17.3779 8.67136 17.5094 9.31925 17.5094 9.99531C17.5094 10.6714 17.3779 11.3474 17.115 11.9577C16.8521 12.5587 16.4953 13.0939 16.0352 13.5446C15.5845 13.9953 15.0493 14.3427 14.4484 14.6056C13.8474 14.8685 13.1901 15 12.5141 15Z"
              fill="#0B1B2B"
            />
            <path
              d="M8.43945 9.99604C8.43945 9.44204 8.54274 8.92561 8.7587 8.42796C8.97467 7.93031 9.25636 7.49838 9.62255 7.13219C9.98875 6.7566 10.4207 6.46552 10.9089 6.25895C11.4066 6.04298 11.9324 5.9397 12.4958 5.9397C13.0592 5.9397 13.5662 6.04298 14.0639 6.25895C14.5615 6.47491 14.9934 6.76599 15.3596 7.13219C15.7352 7.50777 16.0263 7.9397 16.2329 8.42796C16.4488 8.92561 16.5521 9.44204 16.5521 9.99604C16.5521 10.55 16.4488 11.0946 16.2329 11.5829C16.0169 12.0805 15.7258 12.5031 15.3596 12.8693C14.9841 13.2355 14.5521 13.5266 14.0639 13.7331C13.5662 13.9491 13.0498 14.0524 12.4958 14.0524C11.9418 14.0524 11.3972 13.9491 10.9089 13.7331C10.4113 13.5172 9.98875 13.2355 9.62255 12.8693C9.25636 12.5031 8.96528 12.0712 8.7587 11.5829C8.55213 11.0946 8.43945 10.5594 8.43945 9.99604Z"
              fill="url(#paint0_linear_2103_3134)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_2103_3134"
                x1="12.4958"
                y1="5.9397"
                x2="12.4958"
                y2="14.0524"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#00A1FC" stopOpacity="0" />
                <stop offset="0.0001" stopColor="#00C9FF" />
                <stop offset="0.348321" stopColor="#00F0FF" />
              </linearGradient>
            </defs>
          </svg>
          <Text
            sx={{
              fontFamily: 'Anek Bangla',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '400',
            }}
          >
            The locking period starts from the time the box is opened; if the user opens the box before listing on the
            exchange, the locking period is calculated from the listing time.
          </Text>
        </Flex>
        <Text
          sx={{
            fontFamily: 'Anek Bangla',
            fontSize: '15px',
            fontStyle: 'normal',
            fontWeight: '400',
            color: COLOR.white,
          }}
        >
          Example: When purchasing a box worth{' '}
          <span
            style={{
              background: COLOR.bttLinear,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              fontWeight: 600,
            }}
          >
            {slides[currentSlideIndex].price}
          </span>
          , with a listing price of{' '}
          <span
            style={{
              background: COLOR.bttLinear,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              fontWeight: 600,
            }}
          >
            0.001
          </span>
          . After 3 months, the user will be able to claim{' '}
          <span
            style={{
              background: COLOR.bttLinear,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              fontWeight: 600,
              marginRight: '5px',
            }}
          >
            {slides[currentSlideIndex].dark}
          </span>
          tokens (
          <span
            style={{
              background: COLOR.bttLinear,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              fontWeight: 600,
            }}
          >
            vesting 25%
          </span>
          ).
        </Text>
      </Stack>
    </Stack>
  )
}

export default CustomSlide
