import { useTranslation } from '@pancakeswap/localization'
import { ChainId } from '@pancakeswap/sdk'
import {
  Box,
  Flex,
  LogoutIcon,
  RefreshIcon,
  Text,
  useModal,
  UserMenu as UIKitUserMenu,
  UserMenuDivider,
  UserMenuItem,
  UserMenuVariant,
  useMatchBreakpoints,
  Button,
} from '@pancakeswap/uikit'
import { CloseIcon } from '../../../../packages/uikit/src/components/Svg'

import ConnectWalletButton from 'components/ConnectWalletButton'
import Trans from 'components/Trans'
import { useActiveChainId } from 'hooks/useActiveChainId'
import useAuth from 'hooks/useAuth'
import Image from 'next/image'
import NextLink from 'next/link'
import { useEffect, useState } from 'react'
// import { useProfile } from 'state/profile/hooks'
import { usePendingTransactions } from 'state/transactions/hooks'
import { useAccount } from 'wagmi'
import ProfileUserMenuItem from './ProfileUserMenuItem'
import WalletModal, { WalletView } from './WalletModal'
import WalletUserMenuItem from './WalletUserMenuItem'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import color from 'configs/color'
import APP_ROUTES from 'constant/APP_ROUTES'
import images from 'constant/PICTURE/images'

const NavMobile = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
`
const TextLink = styled(Text)`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: ${color.titleWhite};
`
const UserMenuNewDrider = styled.hr`
  border-color: rgba(255, 255, 255, 0.06);
  border-style: solid;
  border-width: 1px 0 0;
  margin: 4px 0;
`

const UserMenuV2 = ({ data }) => {
  const { t } = useTranslation()
  const { address: account } = useAccount()
  const { chainId, isWrongNetwork } = useActiveChainId()
  const { logout } = useAuth()
  const { hasPendingTransactions, pendingNumber } = usePendingTransactions()
  // const { isInitialized, isLoading, profile } = useProfile()
  const [onPresentWalletModal] = useModal(<WalletModal initialView={WalletView.WALLET_INFO} />)
  const [onPresentTransactionModal] = useModal(<WalletModal initialView={WalletView.TRANSACTIONS} />)
  const [onPresentWrongNetworkModal] = useModal(<WalletModal initialView={WalletView.WRONG_NETWORK} />)
  // const hasProfile = isInitialized && !!profile
  // const avatarSrc = profile?.nft?.image?.thumbnail
  const [userMenuText, setUserMenuText] = useState<string>('')
  const [userMenuVariable, setUserMenuVariable] = useState<UserMenuVariant>('default')

  useEffect(() => {
    if (hasPendingTransactions) {
      setUserMenuText(t('%num% Pending', { num: pendingNumber }))
      setUserMenuVariable('pending')
    } else {
      setUserMenuText('')
      setUserMenuVariable('default')
    }
  }, [hasPendingTransactions, pendingNumber, t])

  const onClickWalletMenu = (): void => {
    if (isWrongNetwork) {
      onPresentWrongNetworkModal()
    } else {
      onPresentWalletModal()
    }
  }
  const { isMobile, isTablet } = useMatchBreakpoints()

  const UserMenuItems = () => {
    return (
      <div style={{ padding: isMobile ? '22px 24px' : '16px', width: '375px' }}>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <CloseIcon cursor="pointer" width="24px" color="white" />
        </div>
        {isMobile || isTablet ? (
          <>
            <NavMobile>
              {data.map((items, index) => (
                <>
                  <UserMenuItem key={index}>
                    <Link to={items.link}>
                      <TextLink>{items.label}</TextLink>
                    </Link>
                    {/* <NextLink href={items.link} passHref>
                    </NextLink> */}
                  </UserMenuItem>
                </>
              ))}
            </NavMobile>
            <UserMenuNewDrider />
          </>
        ) : null}
        {/* <WalletUserMenuItem isWrongNetwork={isWrongNetwork} onPresentWalletModal={onClickWalletMenu} /> */}
        <UserMenuItem as="button" disabled={isWrongNetwork} onClick={onPresentTransactionModal}>
          <Flex width="200px" alignItems="center" justifyContent="center" style={{ gap: '8px' }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
              <g clipPath="url(#clip0_1319_1865)">
                <path
                  d="M20.7692 18.2691C20.0851 18.2691 19.4452 17.9325 19.0577 17.3688L18.0414 15.8905C16.8442 18.0371 14.5682 19.423 12 19.423C8.41633 19.423 5.45984 16.6857 5.11128 13.1922H5.76926C6.02632 13.1922 6.26229 13.0498 6.38196 12.8222C6.50163 12.5946 6.48537 12.3195 6.33973 12.1077L5.33051 10.6397L3.80126 8.41533C3.67217 8.2275 3.4588 8.11523 3.2308 8.11523C3.2023 8.11523 3.17403 8.11697 3.14609 8.12039C3.11816 8.12381 3.09064 8.12897 3.06359 8.13567C2.996 8.1525 2.9315 8.17936 2.8723 8.21522C2.78942 8.26537 2.71677 8.33311 2.66028 8.41528L0.121817 12.1076C-0.023823 12.3194 -0.0400886 12.5946 0.079583 12.8221C0.199255 13.0496 0.435223 13.1922 0.692332 13.1922H1.40671C1.57142 15.7671 2.65297 18.1652 4.49384 20.0061C6.49882 22.0111 9.16455 23.1153 12 23.1153C14.8355 23.1153 17.5012 22.0111 19.5062 20.0061C20.0392 19.4731 20.5112 18.8884 20.9164 18.2634C20.8676 18.2668 20.8185 18.2691 20.7692 18.2691Z"
                  fill="url(#paint0_linear_1319_1865)"
                />
                <path
                  d="M12.5276 11.8075H11.4727C11.2727 11.8075 11.1101 11.6448 11.1101 11.4449V10.9174C11.1101 10.7174 11.2727 10.5548 11.4727 10.5548H12.5276C12.7276 10.5548 12.8902 10.7174 12.8902 10.9174C12.8902 11.2997 13.2002 11.6097 13.5825 11.6097C13.9648 11.6097 14.2748 11.2997 14.2748 10.9174C14.2748 10.0096 13.5788 9.26151 12.6924 9.17817V8.80753C12.6924 8.42517 12.3824 8.11523 12.0001 8.11523C11.6177 8.11523 11.3078 8.42522 11.3078 8.80753V9.17817C10.4214 9.26151 9.72534 10.0096 9.72534 10.9174V11.4449C9.72534 12.4083 10.5091 13.1921 11.4726 13.1921H12.5275C12.7275 13.1921 12.8901 13.3548 12.8901 13.5547V14.0822C12.8901 14.2821 12.7274 14.4448 12.5275 14.4448H11.4726C11.2726 14.4448 11.11 14.2822 11.11 14.0823C11.11 13.6999 10.8 13.39 10.4177 13.39C10.0354 13.39 9.72539 13.7 9.72539 14.0823C9.72539 14.99 10.4214 15.7381 11.3078 15.8215V16.1921C11.3078 16.5744 11.6178 16.8844 12.0001 16.8844C12.3825 16.8844 12.6924 16.5744 12.6924 16.1921V15.8214C13.5788 15.7381 14.2749 14.99 14.2749 14.0822V13.5547C14.2748 12.5913 13.4911 11.8075 12.5276 11.8075Z"
                  fill="url(#paint1_linear_1319_1865)"
                />
                <path
                  d="M23.9205 12.1778C23.8008 11.9503 23.5649 11.8078 23.3078 11.8078H22.5934C22.4287 9.23292 21.3471 6.8348 19.5063 4.99388C17.5013 2.98895 14.8356 1.88477 12.0001 1.88477C9.16473 1.88477 6.49891 2.98895 4.49397 4.99393C3.96096 5.52695 3.48897 6.11166 3.08374 6.73665C3.13254 6.73318 3.18157 6.73093 3.23093 6.73093C3.91507 6.73093 4.55486 7.06749 4.94238 7.63121L5.95872 9.10955C7.15595 6.96291 9.43187 5.5771 12.0001 5.5771C15.5838 5.5771 18.5403 8.31441 18.8888 11.8079H18.2309C17.9738 11.8079 17.7378 11.9503 17.6181 12.1779C17.4985 12.4054 17.5147 12.6806 17.6604 12.8924L18.6696 14.3603L20.1988 16.5847C20.2311 16.6317 20.2687 16.6739 20.3104 16.7109C20.4356 16.8217 20.5984 16.8848 20.7694 16.8848C20.9973 16.8848 21.2107 16.7726 21.3399 16.5847L22.2565 15.2515L23.8783 12.8924C24.024 12.6805 24.0402 12.4053 23.9205 12.1778Z"
                  fill="url(#paint2_linear_1319_1865)"
                />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear_1319_1865"
                  x1="10.4582"
                  y1="8.11523"
                  x2="10.4582"
                  y2="23.1153"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#00A1FC" stopOpacity="0" />
                  <stop offset="0.0001" stopColor="#00C9FF" />
                  <stop offset="0.348321" stopColor="#00F0FF" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_1319_1865"
                  x1="12.0001"
                  y1="8.11523"
                  x2="12.0001"
                  y2="16.8844"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#00A1FC" stopOpacity="0" />
                  <stop offset="0.0001" stopColor="#00C9FF" />
                  <stop offset="0.348321" stopColor="#00F0FF" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear_1319_1865"
                  x1="13.5419"
                  y1="1.88477"
                  x2="13.5419"
                  y2="16.8848"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#00A1FC" stopOpacity="0" />
                  <stop offset="0.0001" stopColor="#00C9FF" />
                  <stop offset="0.348321" stopColor="#00F0FF" />
                </linearGradient>
                <clipPath id="clip0_1319_1865">
                  <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
                </clipPath>
              </defs>
            </svg>
            <Text textAlign="start" width="200px" style={{ color: color.titleWhite, fontWeight: 600 }}>
              {' '}
              {t('Recent Transactions')}
            </Text>
          </Flex>
          {hasPendingTransactions && <RefreshIcon spin />}
        </UserMenuItem>
        <Link to={APP_ROUTES.profile}>
          <UserMenuItem as="button">
            <Flex alignItems="center" style={{ gap: '8px' }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                <path
                  d="M12 2.5C10.0222 2.5 8.08879 3.08649 6.4443 4.1853C4.79981 5.28412 3.51809 6.8459 2.76121 8.67317C2.00433 10.5004 1.8063 12.5111 2.19215 14.4509C2.578 16.3907 3.53041 18.1725 4.92894 19.5711C6.32746 20.9696 8.10929 21.922 10.0491 22.3079C11.9889 22.6937 13.9996 22.4957 15.8268 21.7388C17.6541 20.9819 19.2159 19.7002 20.3147 18.0557C21.4135 16.4112 22 14.4778 22 12.5C22 11.1868 21.7413 9.88642 21.2388 8.67317C20.7363 7.45991 19.9997 6.35752 19.0711 5.42893C18.1425 4.50035 17.0401 3.76375 15.8268 3.2612C14.6136 2.75866 13.3132 2.5 12 2.5ZM12 7.5C12.5933 7.5 13.1734 7.67595 13.6667 8.00559C14.1601 8.33524 14.5446 8.80377 14.7716 9.35195C14.9987 9.90013 15.0581 10.5033 14.9424 11.0853C14.8266 11.6672 14.5409 12.2018 14.1213 12.6213C13.7018 13.0409 13.1672 13.3266 12.5853 13.4424C12.0033 13.5581 11.4001 13.4987 10.852 13.2716C10.3038 13.0446 9.83524 12.6601 9.5056 12.1667C9.17595 11.6734 9 11.0933 9 10.5C9 9.70435 9.31607 8.94129 9.87868 8.37868C10.4413 7.81607 11.2044 7.5 12 7.5ZM12 20.5C10.0555 20.5001 8.17813 19.7887 6.722 18.5C8.17904 17.2131 10.056 16.5029 12 16.5029C13.944 16.5029 15.821 17.2131 17.278 18.5C15.8219 19.7887 13.9445 20.5001 12 20.5Z"
                  fill="url(#paint0_linear_1312_1733)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1312_1733"
                    x1="12"
                    y1="2.5"
                    x2="12"
                    y2="22.5"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#00A1FC" stopOpacity="0" />
                    <stop offset="0.0001" stopColor="#00C9FF" />
                    <stop offset="0.348321" stopColor="#00F0FF" />
                  </linearGradient>
                </defs>
              </svg>
              <Text style={{ color: color.titleWhite, fontWeight: 600 }}> {t('Profile')}</Text>
            </Flex>
          </UserMenuItem>
        </Link>
        <Link to={APP_ROUTES.userBox}>
          <Flex style={{ gap: '8px', margin: '10px 0' }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
              <g clipPath="url(#clip0_2103_1489)">
                <path
                  d="M19.6901 5.10938C19.4497 4.13114 18.7318 3.36727 17.77 3.06704C16.8035 2.76479 15.782 2.98529 15.0291 3.65302L13.8318 4.71127L19.5696 6.7996C19.7721 6.26757 19.8313 5.68421 19.6901 5.10938Z"
                  fill="url(#paint0_linear_2103_1489)"
                />
                <path
                  d="M13.0794 2.94339C12.9297 1.94729 12.2853 1.11151 11.3559 0.723342C10.4264 0.334514 9.38565 0.458826 8.57092 1.05376C8.09354 1.40289 7.76425 1.89719 7.57764 2.43494L13.3162 4.52364L13.0794 2.94339Z"
                  fill="url(#paint1_linear_2103_1489)"
                />
                <path
                  d="M10.0974 12.3345V24.5001H11.5043V12.8465L10.0974 12.3345Z"
                  fill="url(#paint2_linear_2103_1489)"
                />
                <path
                  d="M2.35946 11.8848C1.97063 11.8848 1.65601 12.1994 1.65601 12.5882V22.3896C1.65601 23.5533 2.60265 24.5 3.76637 24.5H8.69049V11.8848H2.35946Z"
                  fill="url(#paint3_linear_2103_1489)"
                />
                <path
                  d="M12.9111 13.3584V24.4999H17.8353C18.9989 24.4999 19.9456 23.5532 19.9456 22.3895V15.9187L12.9111 13.3584Z"
                  fill="url(#paint4_linear_2103_1489)"
                />
                <path
                  d="M12.4984 5.72246L10.574 11.0103L11.8965 11.4916L13.821 6.20378L12.4984 5.72246Z"
                  fill="url(#paint5_linear_2103_1489)"
                />
                <path
                  d="M21.092 8.85075L15.1429 6.68555L13.2183 11.9734L20.4896 14.6199C20.5686 14.6487 20.6503 14.6625 20.73 14.6625C21.0171 14.6625 21.2871 14.4852 21.3908 14.1994L22.3532 11.5553C22.7535 10.457 22.1858 9.24938 21.092 8.85075Z"
                  fill="url(#paint6_linear_2103_1489)"
                />
                <path
                  d="M5.22815 3.07648C4.13658 2.67725 2.92415 3.2368 2.52286 4.33775L1.56112 6.98187C1.42786 7.34666 1.61611 7.75062 1.98154 7.88319L9.25261 10.5296L11.1774 5.24178L5.22815 3.07648Z"
                  fill="url(#paint7_linear_2103_1489)"
                />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear_2103_1489"
                  x1="16.7996"
                  y1="2.93408"
                  x2="16.7996"
                  y2="6.7996"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#00A1FC" stopOpacity="0" />
                  <stop offset="0.0001" stopColor="#00C9FF" />
                  <stop offset="0.348321" stopColor="#00F0FF" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_2103_1489"
                  x1="10.4469"
                  y1="0.5"
                  x2="10.4469"
                  y2="4.52364"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#00A1FC" stopOpacity="0" />
                  <stop offset="0.0001" stopColor="#00C9FF" />
                  <stop offset="0.348321" stopColor="#00F0FF" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear_2103_1489"
                  x1="10.8009"
                  y1="12.3345"
                  x2="10.8009"
                  y2="24.5001"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#00A1FC" stopOpacity="0" />
                  <stop offset="0.0001" stopColor="#00C9FF" />
                  <stop offset="0.348321" stopColor="#00F0FF" />
                </linearGradient>
                <linearGradient
                  id="paint3_linear_2103_1489"
                  x1="5.17325"
                  y1="11.8848"
                  x2="5.17325"
                  y2="24.5"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#00A1FC" stopOpacity="0" />
                  <stop offset="0.0001" stopColor="#00C9FF" />
                  <stop offset="0.348321" stopColor="#00F0FF" />
                </linearGradient>
                <linearGradient
                  id="paint4_linear_2103_1489"
                  x1="16.4284"
                  y1="13.3584"
                  x2="16.4284"
                  y2="24.4999"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#00A1FC" stopOpacity="0" />
                  <stop offset="0.0001" stopColor="#00C9FF" />
                  <stop offset="0.348321" stopColor="#00F0FF" />
                </linearGradient>
                <linearGradient
                  id="paint5_linear_2103_1489"
                  x1="11.5362"
                  y1="8.36636"
                  x2="12.8587"
                  y2="8.84768"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#00A1FC" stopOpacity="0" />
                  <stop offset="0.0001" stopColor="#00C9FF" />
                  <stop offset="0.348321" stopColor="#00F0FF" />
                </linearGradient>
                <linearGradient
                  id="paint6_linear_2103_1489"
                  x1="17.8499"
                  y1="6.68555"
                  x2="17.8499"
                  y2="14.6625"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#00A1FC" stopOpacity="0" />
                  <stop offset="0.0001" stopColor="#00C9FF" />
                  <stop offset="0.348321" stopColor="#00F0FF" />
                </linearGradient>
                <linearGradient
                  id="paint7_linear_2103_1489"
                  x1="6.34785"
                  y1="2.94775"
                  x2="6.34785"
                  y2="10.5296"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#00A1FC" stopOpacity="0" />
                  <stop offset="0.0001" stopColor="#00C9FF" />
                  <stop offset="0.348321" stopColor="#00F0FF" />
                </linearGradient>
                <clipPath id="clip0_2103_1489">
                  <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
                </clipPath>
              </defs>
            </svg>
            <Text style={{ color: color.titleWhite, fontWeight: 600 }}>My Boxes</Text>
          </Flex>
        </Link>

        {/* <UserMenuItem>
          <NextLink href="/referral" passHref>
            <Flex alignItems="center" style={{ gap: '8px' }}>
              <Image src="/images/referral.png" width={15} height={15} alt="" />
              <Text style={{color: color.titleWhite}}> {t('Referral')}</Text>
            </Flex>
          </NextLink>
        </UserMenuItem> */}
        <UserMenuNewDrider />
        {/* <NextLink href={`/profile/${account?.toLowerCase()}`} passHref> */}
        {/*   <UserMenuItem as="a" disabled={isWrongNetwork || chainId !== ChainId.BSC}> */}
        {/*     {t('Your NFTs')} */}
        {/*   </UserMenuItem> */}
        {/* </NextLink> */}
        {/* <ProfileUserMenuItem */}
        {/*   isLoading={isLoading} */}
        {/*   hasProfile={hasProfile} */}
        {/*   disabled={isWrongNetwork || chainId !== ChainId.BSC} */}
        {/* /> */}
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <UserMenuItem
            as="button"
            onClick={logout}
            style={{
              backgroundImage: `url(${images.button})`,
              backgroundPosition: 'center',
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '30px 200px',
            }}
          >
            <Flex justifyContent="center" alignItems="center" style={{ gap: '8px' }}>
              <Text style={{ color: '#00F0FF', fontWeight: 700, fontSize: '20px' }}> {t('Disconnect')}</Text>
            </Flex>
          </UserMenuItem>
        </div>
      </div>
    )
  }

  if (account) {
    return (
      <UIKitUserMenu account={account} text={userMenuText} variant={userMenuVariable}>
        {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)}
      </UIKitUserMenu>
    )
  }

  if (isWrongNetwork) {
    return (
      <UIKitUserMenu text={t('Network')} variant="danger">
        {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)}
      </UIKitUserMenu>
    )
  }

  return (
    <>
      {/* <ButtonBuy>
      <Text className='text'>Buy now</Text>
      </ButtonBuy> */}
      <ConnectWalletButton scale="sm">
        <Box
          style={{
            fontFamily: 'Anek Bangla',
            fontWeight: 600,
            padding: '5px 10px',
          }}
          width="180px"
          display={['none', , , 'block']}
        >
          <Trans>Connect Wallet</Trans>
        </Box>
        <Box display={['block', , , 'none']}>
          <Trans>Connect</Trans>
        </Box>
      </ConnectWalletButton>
    </>
  )
}

export default UserMenuV2
