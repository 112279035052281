import images from 'constant/PICTURE/images'

export const DataCard = [
  {
    name: 'Sakura sword',
    attack: 30,
    accuracy: 60,
    attributes: 90,
    price: 12.63,
    bid: 0.33,
    img: `${images.sakura}`,
  },

  {
    name: 'Rebirth Sword',
    attack: 40,
    accuracy: 70,
    attributes: 100,
    price: 15.63,
    bid: 1.33,
    img: `${images.rebirth}`,
  },

  {
    name: 'Purgatory Sword',
    attack: 50,
    accuracy: 70,
    attributes: 100,
    price: 20.63,
    bid: 2.33,
    img: `${images.purgatory}`,
  },
]
