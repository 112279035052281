import { Flex, Stack, Text } from '@chakra-ui/react'
import 'aos/dist/aos.css'
import COLOR from 'constant/COLOR'
import useResponsive from 'hooks/useResponsive'
import React from 'react'

import { MysteryCard } from '../MysteryCard'

const Section1: React.FC<React.PropsWithChildren> = () => {
  const { isMobile, isMobileOrTablet, isTabletOrLaptop, isBigScreen } = useResponsive()

  return (
    <Flex flexWrap="wrap" direction={isMobile ? 'column' : 'row'} gap="30px" justifyContent="center">
      {MysteryCard.map((item, index) => (
        <Stack
          key={index}
          w="300px"
          borderRadius="20px"
          border={`1px solid ${COLOR.bttGetBox}`}
          bg={`${COLOR.bgBoxAirdrop}`}
          backdropFilter="blur(5px)"
          p="20px"
        >
          <img src={item.img} alt="" />
          <Text color={COLOR.white} fontSize="16px" fontFamily="Anek Bangla" fontWeight={400} textAlign="center">
            {item.desc}
          </Text>
        </Stack>
      ))}
    </Flex>
  )
}

export default Section1
