/* eslint-disable no-return-assign */
import { Button, Flex, Grid, Stack, Text } from '@chakra-ui/react'
import { LinkExternal } from '@pancakeswap/uikit'
import 'aos/dist/aos.css'
import COLOR from 'constant/COLOR'
import images from 'constant/PICTURE/images'
import useResponsive from 'hooks/useResponsive'
import React, { useEffect, useRef, useState } from 'react'
import { listAirdrop } from 'service/UserService'
import styled from 'styled-components'
import { getBlockExploreLink } from 'utils'
import useActiveWeb3React from 'hooks/useActiveWeb3React'

const ButtonClaim = styled.a`
  cursor: pointer;
  display: flex;
  margin-top: 10px;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  background-image: url(${images.button});
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-width: 250px;
  color: ${COLOR.button};
  text-shadow: 0px 2px 0px ${COLOR.bttTextShadow};
  font-family: Anek Bangla;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
  width: fit-content;
  white-space: nowrap;
  touch-action: manipulation;
  transition: all 235ms ease-in-out;
  &:hover {
    text-decoration: none;
    color: ${COLOR.hoverBtt};
    transform: translate3d(0, 2px, 0);
    text-shadow: 0px 4px 4px ${COLOR.hoverYellow};
  }
  @media (max-width: 900px) {
    padding: 15px;
    font-size: 14px;
    min-width: 135px;
  }
`
const Whitelist: React.FC<React.PropsWithChildren> = () => {
  const shortenURL = (s: string, max: number) => {
    return s.length > max ? s.substring(0, max / 2 - 1) + '...' + s.substring(s.length - max / 2 + 2, s.length) : s
  }

  const { isMobile, isMobileOrTablet, isTabletOrLaptop, isBigScreen } = useResponsive()
  const [user, setUser] = useState([])
  const [totalUser, setTotalUser] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const pageLimit = 14
  const { account } = useActiveWeb3React()

  const CHAIN_ID = Number(process.env.NEXT_PUBLIC_DEFAULT_CHAIN)

  const getUser = async (page) => {
    try {
      const data = await listAirdrop(pageLimit, page)
      setUser(data.data || [])
      setTotalUser(data.totalRecord)
    } catch (error) {
      console.error('Error fetching user data:', error)
    }
  }
  useEffect(() => {
    getUser(pageNumber)
  }, [pageNumber])

  const handleBack = () => {
    if (pageNumber > 1) {
      setPageNumber((prev) => prev - 1)
    }
  }

  const handleNext = () => {
    if (user.length === pageLimit) {
      setPageNumber((prev) => prev + 1)
    }
  }
  return (
    <Stack justifyContent="center" alignItems="center">
      <Text
        color={COLOR.white}
        fontSize={isMobile ? '25px' : isTabletOrLaptop ? '45px' : '64px'}
        fontFamily="Anek Bangla"
        fontWeight={600}
        textAlign="center"
        mt="50px"
      >
        Whitelist{' '}
        <span
          style={{
            fontSize: '20px',
            fontWeight: '400',
          }}
        >
          ( {totalUser}/1000 )
        </span>
      </Text>

      <Grid
        gridTemplateColumns={isMobile ? '1fr ' : '1fr 1fr'}
        gridColumnGap="50px"
        borderRadius="20px"
        border={`1px solid ${COLOR.bttGetBox}`}
        bg={`${COLOR.bgBoxAirdrop}`}
        backdropFilter="blur(5px)"
        p="20px"
        columnGap={isMobile ? '5px' : '50px'}
      >
        {user.map((item, index) => (
          <Text
            fontSize="15px"
            fontFamily="Anek Bangla"
            fontWeight={600}
            key={item}
            color="gray"
            _hover={{
              cursor: 'pointer',
            }}
          >
            <LinkExternal
              fontSize={['14px', '16px', '18px', '18px', '18px']}
              href={getBlockExploreLink(item, 'address', CHAIN_ID)}
              ellipsis={true}
              style={{ color: item === account ? COLOR.lime : COLOR.white }}
              color={COLOR.menu}
            >
              {shortenURL(`${item}`, 20)}
            </LinkExternal>
          </Text>
        ))}
      </Grid>

      <Flex mt="20px">
        {pageNumber > 1 && <ButtonClaim onClick={handleBack}>Back</ButtonClaim>}
        {user.length === pageLimit && user.length < totalUser && <ButtonClaim onClick={handleNext}>Next</ButtonClaim>}
      </Flex>
    </Stack>
  )
}

export default Whitelist
