import images from 'constant/PICTURE/images'
import { BoxAllType, BoxType } from '../models/BoxType'
import COLOR from 'constant/COLOR'

export const DefaultBox: BoxType[] = [
  {
    name: 'hero box',
    type: 'Rarity',
    img: `${images.heroBox}`,
    all: BoxAllType.hero,
    border: `${COLOR.borderHero}`,
    shadow: `${COLOR.shadowHero}`,
    desc: 'This is a basic Mystery Box,containing your lucky items to start your adventure',
  },
  {
    name: 'warrior box',
    type: 'Rarity',
    img: `${images.championBox}`,
    all: BoxAllType.champion,
    border: `${COLOR.borderChampion}`,
    shadow: `${COLOR.shadowChampion}`,
    desc: 'Upgraded Mystery Box, containing items to support you in your mysterious journey.',
  },
  {
    name: 'champion box',
    type: 'Rarity',
    img: `${images.warriorBox}`,
    all: BoxAllType.warrior,
    border: `${COLOR.borderWarrior}`,
    shadow: `${COLOR.shadowWarrior}`,
    desc: 'Premium version of the Mystery Box, freely experience the difficult journey ahead.',
  },
]
