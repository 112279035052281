import { Stack, Text, Flex, Button } from '@chakra-ui/react'
import 'aos/dist/aos.css'
import COLOR from 'constant/COLOR'
import images from 'constant/PICTURE/images'
import useResponsive from 'hooks/useResponsive'
import React, { useEffect, useState } from 'react'
import { BoxData } from '../BoxData'
const data = [
  {
    text: '0xcac2...658e',
  },
  {
    text: '0xcbn6...a49a',
  },
  {
    text: '0xcac2...658e',
  },
  {
    text: '0xcba4...atg8',
  },
  {
    text: '0xcac2...658e',
  },
]
const Section2: React.FC<React.PropsWithChildren> = () => {
  const { isMobile, isMobileOrTablet, isTabletOrLaptop, isBigScreen } = useResponsive()
  const [days, setDays] = useState(0)
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)
  const timeStamp = Math.floor(Date.now() / 1000)
  const [expanded, setExpanded] = useState(0)
  const [show, setShow] = useState(false)
  const expandWidth = (index) => {
    setExpanded(index)
    setShow(true)
  }
  const decreaseWidth = (index) => {
    setExpanded(index)
    setShow(false)
  }
  useEffect(() => {
    let countdown = 1707523200 - timeStamp
    const updateCountdown = () => {
      setDays(Math.floor(countdown / 86400))
      setHours(Math.floor((countdown % 86400) / 3600))
      setMinutes(Math.floor((countdown % 3600) / 60))
      setSeconds(countdown % 60)
    }
    const timer = setInterval(() => {
      if (countdown > 0) {
        updateCountdown()
        countdown--
      } else {
        clearInterval(timer)
      }
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [timeStamp])
  return (
    <Stack>
      <Text
        color={COLOR.white}
        fontSize={isMobile ? '25px' : isTabletOrLaptop ? '45px' : '64px'}
        fontFamily="Anek Bangla"
        fontWeight={600}
        textAlign="center"
        mt="100px"
      >
        Special rewards - Mystery Boxes
      </Text>
      <Flex
        flexWrap="wrap"
        direction={isMobile ? 'column' : 'row'}
        gap="30px"
        justifyContent="center"
        alignItems="center"
        w="100%"
      >
        {BoxData.map((item, index) => (
          <Flex
            key={index}
            direction={isMobile ? 'column' : 'row'}
            w={
              show === true && index === expanded && !isMobile
                ? '60%'
                : isMobile
                ? '100%'
                : show === true && index === expanded && isMobile
                ? '100%'
                : '30%'
            }
            justifyContent="center"
            alignItems="center"
            borderRadius="20px"
            gap="50px"
            border={`5px solid ${item.border}`}
            boxShadow={`0px 0px 10px 0px${item.shadow}`}
            bg={COLOR.BoxAirdrop}
            p="20px"
            display={index === expanded + 1 && show === true ? 'none' : 'flex'}
          >
            <Stack>
              <img style={{ width: '200px' }} src={item.img} alt="" />
              <Text color={COLOR.white} fontSize="22px" fontFamily="Anek Bangla" fontWeight={600} textAlign="center">
                {item.name}
              </Text>

              <Text color={COLOR.white} fontSize="15px" fontFamily="Anek Bangla" fontWeight={400} textAlign="center">
                On Process
              </Text>
            </Stack>

            {index === expanded && show === true && (
              <Stack>
                <Text
                  color={COLOR.white}
                  fontSize="18px"
                  fontFamily="Anek Bangla"
                  fontWeight={600}
                  textAlign="center"
                  borderRadius="8px"
                  bg={COLOR.bgButton}
                  border={`1px solid ${COLOR.bttGetBox}`}
                  p="10px 20px"
                >
                  USERS WINNER
                </Text>
                {data.map((item, index) => (
                  <Flex key={index}>
                    <Text
                      color={COLOR.white}
                      fontSize="15px"
                      fontFamily="Anek Bangla"
                      fontWeight={600}
                      textAlign="center"
                      borderRadius="8px"
                      p="10px 20px"
                      bg={COLOR.bgButton}
                    >
                      {item.text}
                    </Text>{' '}
                  </Flex>
                ))}
              </Stack>
            )}
          </Flex>
        ))}
      </Flex>
    </Stack>
  )
}

export default Section2
