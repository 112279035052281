/* eslint-disable consistent-return */
/* eslint-disable no-useless-catch */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Stack, Text, Flex, Button } from '@chakra-ui/react'
import 'aos/dist/aos.css'
import COLOR from 'constant/COLOR'
import images from 'constant/PICTURE/images'
import useResponsive from 'hooks/useResponsive'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import useActiveWeb3React from 'hooks/useActiveWeb3React'
import axios from 'axios'
import {
  AuthTwitter,
  ConnectTwitter,
  checkAirdrop,
  checkClaimAirdrop,
  getInfoTwitter,
  logoutTwitter,
} from 'service/UserService'
import DarkPageLoader from 'components/Loader/DarkKnightLoader'
import { useToast } from '@pancakeswap/uikit'
import useAuth from 'hooks/useAuth'

const BoxContain = styled.div`
  border-radius: 20px;
  border: 1px solid ${COLOR.bttGetBox};
  background: ${COLOR.bgButton};
  display: flex;
  padding: 20px;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`
const ButtonClaim = styled.a`
  display: flex;
  margin-top: 10px;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  background-image: url(${images.button});
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-width: 250px;
  color: ${COLOR.button};
  text-shadow: 0px 2px 0px ${COLOR.bttTextShadow};
  font-family: Anek Bangla;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
  width: fit-content;
  white-space: nowrap;
  touch-action: manipulation;
  transition: all 235ms ease-in-out;
  &:hover {
    text-decoration: none;
    color: ${COLOR.hoverBtt};
    transform: translate3d(0, 2px, 0);
    text-shadow: 0px 4px 4px ${COLOR.hoverYellow};
  }
  @media (max-width: 900px) {
    padding: 15px;
    font-size: 14px;
    min-width: 135px;
  }
`
const Wrapper = styled.div`
  width: 100vw;
  height: 150vh;
  background: ${COLOR.bgAirdrop};
  background-image: url(${images.bigAirdrop});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 900px) {
    height: 100vh;
  }
  @media (max-width: 575px) {
    height: 100vh;
  }
`

const Task: React.FC<React.PropsWithChildren> = () => {
  const { connetSocial } = useAuth()

  const { isMobile, isMobileOrTablet, isTabletOrLaptop, isBigScreen } = useResponsive()
  const { toastSuccess, toastError } = useToast()
  const [user, setUser] = useState('')
  // const [info, setInfo] = useState('')
  const [follow, setFollow] = useState(false)
  const [share, setShare] = useState(false)
  const [loading, setLoading] = useState(false)
  const { account } = useActiveWeb3React()
  const [isClaim, setIsClaim] = useState(false)
  const [isTwitterUsed, setIsTwitterUsed] = useState(false)
  useEffect(() => {
    const twitterName = localStorage.getItem('twitter')
    if (twitterName) {
      setUser(twitterName)
    }
    checkLoading(twitterName)
  }, [account])
  // useEffect(() => {
  //   if (user) {
  //     checkLoading(user)
  //   }
  // }, [user])
  useEffect(() => {
    authTwitter()
  }, [account])
  const checkLoading = async (user) => {
    if (!account) return
    setLoading(true)
    const twitterUser = localStorage.getItem('twitter')
    const token = localStorage.getItem('TokenGame')
    const twitterId = localStorage.getItem('twitterId') || ''
    const [follow, share] = await Promise.all([CheckFollow(user), CheckShare(user)])
    if (twitterUser) {
      const usedTwitter = await checkClaimAirdrop({ twitterName: twitterUser, twitterId })
      setIsTwitterUsed(account && account !== usedTwitter.data.walletAddress && usedTwitter.data.isClaimed)
      try {
        if (
          twitterUser &&
          follow &&
          share &&
          account &&
          localStorage.getItem('joinChannel') &&
          localStorage.getItem('joinGroup') &&
          !usedTwitter.data.isClaimed
        ) {
          await checkAirdrop(
            {
              twitterName: twitterUser,
              twitterId,
            },
            token,
          )
        }
      } catch (error) {
        const errorResponse = (error as any).response
        if (errorResponse.status === 401 && account) {
          connetSocial()
        }
      }
    }

    setLoading(false)
  }
  const CheckShare = async (user) => {
    const options = {
      method: 'GET',
      url: 'https://twitter-api45.p.rapidapi.com/checkretweet.php',
      params: {
        screenname: user,
        tweet_id: process.env.NEXT_PUBLIC_TWEET_ID,
      },

      headers: {
        'X-RapidAPI-Key': '37fd0a7fc9mshe7ee0744ab70df6p1c37c4jsn7ffdf9430594',
        'X-RapidAPI-Host': 'twitter-api45.p.rapidapi.com',
      },
    }
    try {
      const response = await axios.request(options)
      if (response.data.is_retweeted) {
        setShare(true)
        return response.data.is_retweeted
      }
    } catch (error) {
      console.error(error)
    }
  }
  const CheckFollow = async (user) => {
    const options = {
      method: 'GET',
      url: 'https://twitter-api45.p.rapidapi.com/checkfollow.php?follows=dark_knightgame&user=' + user,

      headers: {
        'X-RapidAPI-Key': '37fd0a7fc9mshe7ee0744ab70df6p1c37c4jsn7ffdf9430594',
        'X-RapidAPI-Host': 'twitter-api45.p.rapidapi.com',
      },
    }

    try {
      const response = await axios.request(options)
      if (response.data.is_follow) {
        setFollow(true)
        return response.data.is_follow
      }
    } catch (error) {
      console.error(error)
    }
  }

  // const handleLogout = async () => {
  //   try {
  //     const data = await logoutTwitter()
  //     localStorage.removeItem('twitter')
  //     localStorage.removeItem('joinChannel')
  //     localStorage.removeItem('joinGroup')
  //     setUser('')
  //     setFollow(false)
  //     setShare(false)
  //     toastSuccess('Success', 'Log Out Success')
  //   } catch (err) {
  //     toastError('Failed', 'Log Out Failed')
  //   }
  // }
  const handleConnect = async () => {
    try {
      const data = await ConnectTwitter({ callback: window.location.origin + '/Airdrop' })
      if (data.oauth_token) {
        window.location.href = `https://api.twitter.com/oauth/authenticate?oauth_token=${data.oauth_token}`
      }
      setLoading(true)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }
  const authTwitter = async () => {
    const query = new URLSearchParams(window.location.search)
    const oauthToken = query.get('oauth_token')
    const oauthVerifier = query.get('oauth_verifier')

    if (oauthToken && oauthVerifier) {
      const data = await AuthTwitter({ oauth_token: oauthToken, oauth_verifier: oauthVerifier })

      if (data.data.userName) {
        window.history.replaceState(data.data.userName, null, window.location.origin + window.location.pathname)
        // window.location.replace(window.location.origin + window.location.pathname)
        setUser(data.data.userName)
        localStorage.setItem('twitter', data.data.userName)
        localStorage.setItem('twitterId', data.data.userId)

        toastSuccess('Success', 'Sign in success')
      }
    }
  }
  const joinChannel = () => {
    localStorage.setItem('joinChannel', 'true')
  }
  const joinGroup = () => {
    localStorage.setItem('joinGroup', 'true')
  }

  // const infoTwitter = async () => {
  //   try {
  //     const data = await getInfoTwitter()
  //     console.log(data, data.data.username)

  //     setInfo(data.data.username)
  //   } catch (err) {
  //     console.log(err)

  //     const errorResponse = (err as any).response
  //     toastError('Failed', errorResponse.data.message)
  //   }
  // }
  // useEffect(() => {
  //   infoTwitter()
  // }, [])
  return (
    <Wrapper
      style={{
        display: 'flex',
        justifyContent: 'center',
        gap: '50px',
        flexDirection: isMobileOrTablet ? 'column' : 'row',
      }}
    >
      {loading && (
        <Stack
          zIndex={1}
          position="absolute"
          top={isMobile ? '10%' : isMobileOrTablet ? '15%' : isTabletOrLaptop ? '10%' : 0}
          left={isMobile ? '30%' : isMobileOrTablet ? '40%' : isTabletOrLaptop ? '77%' : '67%'}
        >
          <DarkPageLoader />
        </Stack>
      )}
      <Stack mt={isMobileOrTablet ? 400 : 150} alignItems="center">
        <img style={{ width: '700px' }} src={images.bannerAirdrop} />
        <Text
          color={COLOR.white}
          fontFamily="Anek Bangla"
          fontWeight={400}
          fontSize="22px"
          textAlign="center"
          w={isMobile ? '100%' : '600px'}
        >
          Join in Dark Knight Airdrop event for a chance to receive a Mystery Box
        </Text>
      </Stack>

      <Stack mt={100} p="10px">
        <Text
          color={COLOR.white}
          fontSize="25px"
          fontFamily="Anek Bangla"
          fontWeight={800}
          textTransform="uppercase"
          mb="20px"
          textAlign="center"
        >
          Tasks to get FREE Airdrop
        </Text>
        {user ? (
          <BoxContain style={{ justifyContent: 'center' }}>
            <Text
              color={COLOR.white}
              fontSize="16px"
              fontFamily="Anek Bangla"
              fontWeight={800}
              textTransform="uppercase"
              textAlign="center"
            >
              Welcome :{' '}
              <span
                style={{
                  color: COLOR.bttGetBox,
                }}
              >
                {user}
              </span>
              {isTwitterUsed === true && (
                <Text fontSize="10px" fontFamily="Anek Bangla" color={COLOR.red} mt="10px">
                  This twitter account has claimed by another wallet
                </Text>
              )}
            </Text>
          </BoxContain>
        ) : (
          <></>
        )}
        <BoxContain>
          <Text color={COLOR.white} fontSize="16px" fontFamily="Anek Bangla" fontWeight={800} textTransform="uppercase">
            Connect Wallet
          </Text>
          {account ? <img src={images.checkAirdropIcon} /> : <img src={images.unCheckAirdropIcon} />}
        </BoxContain>
        <BoxContain>
          <Flex justifyContent="space-between" width="100%" alignItems="center">
            <Text
              color={COLOR.white}
              fontSize="16px"
              fontFamily="Anek Bangla"
              fontWeight={800}
              textTransform="uppercase"
            >
              Follow Twitter
            </Text>
            {follow ? (
              <></>
            ) : (
              <Stack
                transition="all 235ms ease-in-out"
                _hover={{
                  transform: ' translate3d(0, 2px, 0)',
                }}
              >
                <a href="https://twitter.com/dark_knightgame">
                  <Button
                    p="0 20px"
                    h="40px"
                    borderRadius="20px"
                    border={`1px solid ${COLOR.bttGetBox}`}
                    bg={COLOR.bgButton}
                    color={COLOR.bttGetBox}
                    _hover={{ bg: 'none' }}
                  >
                    Follow Us
                  </Button>
                </a>
              </Stack>
            )}
            {follow ? (
              <img src={images.checkAirdropIcon} />
            ) : (
              <img
                // style={{
                //   cursor: 'pointer',
                // }}
                // onClick={() => CheckFollow(user)}
                src={images.unCheckAirdropIcon}
              />
            )}
          </Flex>
        </BoxContain>{' '}
        <BoxContain>
          <Flex justifyContent="space-between" width="100%" alignItems="center">
            <Stack>
              <Text
                color={COLOR.white}
                fontSize="16px"
                fontFamily="Anek Bangla"
                fontWeight={800}
                textTransform="uppercase"
              >
                Share Event
              </Text>
              <Text
                color={COLOR.white}
                fontSize="10px"
                fontFamily="Anek Bangla"
                fontWeight={400}
                textTransform="uppercase"
              >
                With tag 3 friends + tag:
              </Text>
              <Text
                color={COLOR.white}
                fontSize="10px"
                fontFamily="Anek Bangla"
                fontWeight={400}
                // textTransform="uppercase"
              >
                @dark_knightgame
              </Text>
            </Stack>
            {share ? (
              <></>
            ) : (
              <Stack
                transition="all 235ms ease-in-out"
                _hover={{
                  transform: ' translate3d(0, 2px, 0)',
                }}
              >
                <a
                  style={{ color: 'red' }}
                  className="twitter-share-button"
                  href={`https://twitter.com/intent/retweet?tweet_id=${process.env.NEXT_PUBLIC_TWEET_ID}&text=%20%23dark_knightgame`}
                >
                  <Button
                    p="0 20px"
                    h="40px"
                    borderRadius="20px"
                    border={`1px solid ${COLOR.bttGetBox}`}
                    bg={COLOR.bgButton}
                    color={COLOR.bttGetBox}
                    _hover={{ bg: 'none' }}
                  >
                    Share
                  </Button>
                </a>
              </Stack>
            )}
            {share ? (
              <img src={images.checkAirdropIcon} />
            ) : (
              <img
                // style={{
                //   cursor: 'pointer',
                // }}
                // onClick={() => CheckShare(user)}
                src={images.unCheckAirdropIcon}
              />
            )}
          </Flex>
        </BoxContain>{' '}
        <BoxContain>
          <Flex justifyContent="space-between" width="100%" alignItems="center">
            <Text
              color={COLOR.white}
              fontSize="16px"
              fontFamily="Anek Bangla"
              fontWeight={800}
              textTransform="uppercase"
            >
              JOIN CHANNEL
            </Text>
            {localStorage.getItem('joinChannel') ? (
              <></>
            ) : (
              <Stack
                transition="all 235ms ease-in-out"
                _hover={{
                  transform: ' translate3d(0, 2px, 0)',
                }}
              >
                <a href="https://t.me/darkknight_games">
                  <Button
                    p="0 20px"
                    h="40px"
                    onClick={joinChannel}
                    borderRadius="20px"
                    border={`1px solid ${COLOR.bttGetBox}`}
                    bg={COLOR.bgButton}
                    color={COLOR.bttGetBox}
                    _hover={{ bg: 'none' }}
                  >
                    Join Channel
                  </Button>
                </a>
              </Stack>
            )}
            {localStorage.getItem('joinChannel') ? (
              <img src={images.checkAirdropIcon} />
            ) : (
              <img
                style={{
                  cursor: 'pointer',
                }}
                src={images.unCheckAirdropIcon}
              />
            )}
          </Flex>
        </BoxContain>{' '}
        <BoxContain>
          <Flex justifyContent="space-between" width="100%" alignItems="center">
            <Text
              color={COLOR.white}
              fontSize="16px"
              fontFamily="Anek Bangla"
              fontWeight={800}
              textTransform="uppercase"
            >
              JOIN GROUP
            </Text>
            {localStorage.getItem('joinGroup') ? (
              <></>
            ) : (
              <Stack
                transition="all 235ms ease-in-out"
                _hover={{
                  transform: ' translate3d(0, 2px, 0)',
                }}
              >
                <a href="https://t.me/darkknight_group">
                  <Button
                    p="0 20px"
                    h="40px"
                    onClick={joinGroup}
                    borderRadius="20px"
                    border={`1px solid ${COLOR.bttGetBox}`}
                    bg={COLOR.bgButton}
                    color={COLOR.bttGetBox}
                    _hover={{ bg: 'none' }}
                  >
                    Join Group
                  </Button>
                </a>
              </Stack>
            )}
            {localStorage.getItem('joinGroup') ? (
              <img src={images.checkAirdropIcon} />
            ) : (
              <img
                style={{
                  cursor: 'pointer',
                }}
                src={images.unCheckAirdropIcon}
              />
            )}
          </Flex>
        </BoxContain>{' '}
        <Stack justifyContent="center" alignItems="center">
          {user ? (
            localStorage.getItem('joinChannel') && localStorage.getItem('joinGroup') && share && account && follow ? (
              <>
                {isClaim ? (
                  <Stack
                    w="200px"
                    transition="all 235ms ease-in-out"
                    filter="grayscale(100%)"
                    _hover={{
                      transform: ' translate3d(0, 2px, 0)',
                    }}
                  >
                    <ButtonClaim style={{ minWidth: '190px', cursor: 'not-allowed' }}>CLAIM</ButtonClaim>
                  </Stack>
                ) : (
                  <Stack
                    w="200px"
                    cursor="not-allowed"
                    transition="all 235ms ease-in-out"
                    filter="grayscale(100%)"
                    _hover={{
                      transform: ' translate3d(0, 2px, 0)',
                    }}
                  >
                    <ButtonClaim style={{ minWidth: '190px', cursor: 'not-allowed' }}>CLAIM</ButtonClaim>
                  </Stack>
                )}
              </>
            ) : (
              <Stack
                w="200px"
                transition="all 235ms ease-in-out"
                cursor="not-allowed"
                filter="grayscale(100%)"
                _hover={{
                  transform: ' translate3d(0, 2px, 0)',
                }}
                // onClick={claimAirdrop}
              >
                <ButtonClaim style={{ minWidth: '190px', cursor: 'not-allowed' }}>CLAIM</ButtonClaim>
              </Stack>
            )
          ) : (
            <Stack
              cursor="pointer"
              transition="all 235ms ease-in-out"
              _hover={{
                transform: ' translate3d(0, 2px, 0)',
              }}
              onClick={handleConnect}
            >
              <ButtonClaim style={{ gap: '10px' }}>
                Connect
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-twitter-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z" />
                </svg>
              </ButtonClaim>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Wrapper>
  )
}

export default Task
