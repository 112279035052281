import { FooterLinkType } from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'

export const footerLinks: (t: ContextApi['t']) => FooterLinkType[] = (t) => [
  {
    label: t('Home'),
    items: [],
  },
  {
    label: t('Support'),
    items: [
      {
        label: t('Gitbook'),
        href: 'https://google.com',
      },
      {
        label: t('Documentation'),
        href: 'https://google.com',
      },
      {
        label: t('Help Center'),
        // href: 'https://docs.pancakeswap.finance/contact-us/customer-support',
      },
      {
        label: t('FAQ'),
        // href: 'https://docs.pancakeswap.finance/help/troubleshooting',
      },
      {
        label: t('Chat Support'),
        // href: 'https://docs.pancakeswap.finance/get-started',
      },
    ],
  },
  {
    label: t('Social JOIN NOW'),
    items: [
      // {
      //   label: 'Support on Polygon',
      //   href: 'https://polygon.technology',
      //   image: '/images/IconPoolsV2.svg',
      // },
      {
        label: 'Audit by Certik',
        href: 'https://google.com',
        image: '/images/chains/certik-logo.png',
        border: true,
      },
      // {
      //   label: 'Twitter',
      //   href: 'https://google.com',
      //   image: '/images/V3/Logotwitter.svg',
      // },
      // {
      //   label: t('Facebook'),
      //   href: 'https://google.com',
      //   image: '/images/pools/facebook.svg',
      // },
      // {
      //   label: t('Telegram'),
      //   href: 'https://google.com',
      //   image: '/images/V3/Telegramicon.svg',
      // },
      // {
      //   label: t('Global Community'),
      //   href: 'https://google.com',
      //   image: '/images/V3/Telegramicon.svg',
      // },
      // {
      //   label: 'Github',
      //   href: 'https://google.com',
      //   image: '/images/pools/Github.png',
      //   // border: true,
      // },
      // {
      //   label: t('Medium'),
      //   href: 'https://google.com',
      //   isHighlighted: true,
      //   image: '/images/pools/medium.svg',
      // },
      // {
      //   label: t('Youtube'),
      //   href: 'https://google.com',
      //   image: '/images/pools/youtube.svg',
      // },
    ],
  },
]
