import React, { useEffect, useState } from 'react'

import { ProductType } from 'components/Data/models/ProductType'
import { DefaultProduct } from 'components/Data/ProductDefault'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import 'antd-message-react/dist/index.css'
import { Flex, Text, Stack } from '@chakra-ui/react'
import useResponsive from 'hooks/useResponsive'
import CustomImage from 'components/CustomSkeleton/CustomImage'
import CustomText from 'components/CustomSkeleton/CustomText'
import COLOR from 'constant/COLOR'
import images from 'constant/PICTURE/images'

const Container = styled.div`
  display: flex;
  background-image: url(${images.bgHome});
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  flex-direction: column;
  align-items: center;
  padding: 45px;
  overflow: hidden;
`
const Wrapper = styled.div`
  background-image: url(${images.bgDetail});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  @media (max-width: 900px) {
    background-size: cover;
  }
  @media (max-width: 575px) {
    background-image: url(${images.bgDetail2});
    background-size: contain;
  }
`
const WrapperInfo = styled.div`
  display: flex;
  flex-direction: row;
  background-image: url(${images.bgInfo});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 6px 20px;
  width: 300px;
  gap: 10px;
  @media (max-width: 575px) {
    width: 100%;
  }
`
const WrapperInfo2 = styled.div`
  display: flex;
  flex-direction: row;
  background-image: url(${images.bgInfo2});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 4px 10px;
  gap: 10px;
`

const ButtonDetail = styled.div`
  display: flex;
  width: 200px;
  padding: 10px 25px;
  justify-content: space-between;
  align-items: center;
  color: ${COLOR.black};
  font-family: Anek Bangla;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: none;
  text-transform: uppercase;
  background-image: url(${images.bttDetail});
  background-size: contain;
  background-repeat: no-repeat;
`
const ButtonBuy = styled.button`
  display: flex;
  padding: 5px 20px;
  justify-content: center;
  align-items: center;
  color: ${COLOR.black};
  font-family: Anek Bangla;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: none;
  text-transform: uppercase;
  cursor: pointer;
  background-image: url(${images.bttBuy});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  touch-action: manipulation;
  transition: all 235ms ease-in-out;
  &:hover {
    text-decoration: none;
    transform: translate3d(0, 2px, 0);
  }
`
const ButtonOffer = styled.button`
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  color: ${COLOR.black};
  font-family: Anek Bangla;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: none;
  text-transform: uppercase;
  cursor: pointer;
  background-image: url(${images.bttOffer});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  touch-action: manipulation;
  transition: all 235ms ease-in-out;
  &:hover {
    text-decoration: none;
    transform: translate3d(0, 2px, 0);
  }
`

const Title = styled.text`
  padding: 6px 50px;
  background-image: url(${images.bgTitle});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  @media (max-width: 575px) {
    font-size: 25px;
  }
`
const Header = styled.text`
  border-bottom: 1px solid ${COLOR.white};
  font-family: Russo One;
  font-size: 65px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 50px;
  color: ${COLOR.white};
  @media (max-width: 900px) {
    font-size: 30px;
    line-height: normal;
  }
  @media (max-width: 575px) {
    font-size: 25px;
  }
`
function NFTDetail() {
  const [product, setProduct] = useState<ProductType>()
  const { isMobile, isMobileOrTablet } = useResponsive()
  type Card = { id?: string }
  const NFTitem = useParams()
  const Item: Card = NFTitem
  const ProductItem = Item.id

  useEffect(() => {
    if (ProductItem) {
      setProduct(DefaultProduct.find((item) => item.id === ProductItem))
    }
  }, [ProductItem])
  return (
    product && (
      <Container>
        <Header>THE NFTs CARD</Header>

        <Wrapper>
          <Stack
            direction={isMobileOrTablet ? 'column' : 'row'}
            justifyContent="center"
            p={isMobileOrTablet ? '0' : '90px 130px'}
            alignItems="center"
            gap={isMobileOrTablet ? '0' : '50px'}
            marginTop={isMobileOrTablet ? '120px' : '0'}
          >
            <Stack justifyContent="center" alignItems="center" gap="30px">
              <CustomImage
                style={{
                  width: '200px',
                  height: '300px',
                  boxShadow: ` 0px 10px 20px 0px ${COLOR.shadowCard}}`,
                  background: COLOR.white,
                  borderRadius: '12px',
                }}
                src={product.img}
                alt=""
              />

              <ButtonDetail>
                <Text
                  fontFamily="Anek Bangla"
                  fontSize="18px"
                  fontWeight={500}
                  lineHeight="normal"
                  color={COLOR.detailId}
                >
                  NFT ID
                </Text>
                <Text
                  fontFamily="Anek Bangla"
                  fontSize="18px"
                  fontWeight={500}
                  lineHeight="normal"
                  color={COLOR.detailId}
                >
                  {product.id}
                </Text>
              </ButtonDetail>
            </Stack>
            <Stack
              backgroundImage={isMobile ? images.bgCard2 : images.bgCard}
              backgroundPosition="center"
              backgroundRepeat="no-repeat"
              backgroundSize={isMobile ? '50% 100%' : 'contain'}
              p={isMobile ? '155px 165px' : '150px 170px'}
            >
              <Title>
                <CustomText
                  fontFamily="Anek Bangla"
                  fontSize="32px"
                  fontWeight={500}
                  lineHeight="normal"
                  color={COLOR.titleDetail}
                >
                  {product.all} Card
                </CustomText>
              </Title>
              <Stack
                display="flex"
                p="10px"
                borderRadius="12px"
                border={`1px solid ${COLOR.borderDetail}`}
                w={isMobile ? '300px' : 'full'}
              >
                <Text color={COLOR.titleDetail} fontFamily="Anek Bangla" fontWeight={500} fontSize="20px">
                  Attributes:
                </Text>
                <Stack>
                  <WrapperInfo>
                    <img src={images.attackIcon} alt="" />
                    <CustomText style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                      <Text color={COLOR.black} fontFamily="Anek Bangla" fontWeight={500} fontSize="20px">
                        Attack :
                      </Text>
                      <Text color={COLOR.black} fontFamily="Anek Bangla" fontWeight={500} fontSize="20px">
                        {product.attack}
                      </Text>
                    </CustomText>
                  </WrapperInfo>
                  <WrapperInfo>
                    <img src={images.criticalIcon} alt="" />
                    <CustomText style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                      <Text color={COLOR.black} fontFamily="Anek Bangla" fontWeight={500} fontSize="20px">
                        Critical :
                      </Text>
                      <Text color={COLOR.black} fontFamily="Anek Bangla" fontWeight={500} fontSize="20px">
                        {product.critical}
                      </Text>
                    </CustomText>
                  </WrapperInfo>
                  <WrapperInfo>
                    <img src={images.accuracyIcon} alt="" />
                    <CustomText style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                      <Text color={COLOR.black} fontFamily="Anek Bangla" fontWeight={500} fontSize="20px">
                        Accuracy :
                      </Text>
                      <Text color={COLOR.black} fontFamily="Anek Bangla" fontWeight={500} fontSize="20px">
                        {product.critical}
                      </Text>
                    </CustomText>
                  </WrapperInfo>
                </Stack>
              </Stack>
              <Stack p="20px 10px" borderRadius="12px" border={`1px solid ${COLOR.borderDetail}`}>
                <Text color={COLOR.titleDetail} fontFamily="Anek Bangla" fontWeight={500} fontSize="20px">
                  Current price
                </Text>
                <WrapperInfo2>
                  <img src={images.tokenDetailIcon} />
                  <CustomText style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                    <Text color={COLOR.titleDetail} fontFamily="Anek Bangla" fontWeight={500} fontSize="20px">
                      {product.description}{' '}
                      <span
                        style={{
                          color: COLOR.titleDetail,
                          fontFamily: 'Anek Bangla',
                          fontWeight: '600',
                          fontSize: '20px',
                        }}
                      >
                        Dark
                      </span>
                    </Text>
                    <Text color={COLOR.bgTitleMarket} fontFamily="Anek Bangla" fontWeight={500} fontSize="20px">
                      (${product.price})
                    </Text>
                  </CustomText>
                </WrapperInfo2>
              </Stack>
              <Flex sx={{ gap: '5px', spacing: '0' }} justifyContent="center">
                <ButtonBuy>buy now</ButtonBuy>
                <ButtonOffer>make offer</ButtonOffer>
              </Flex>
            </Stack>
          </Stack>
        </Wrapper>
      </Container>
    )
  )
}

export default NFTDetail
