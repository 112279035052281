import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import 'aos/dist/aos.css'
import AOS from 'aos'
import { NFTCard } from 'components/NFTCard'
import images from 'constant/PICTURE/images'
import COLOR from 'constant/COLOR'
const Wrapper = styled.div`
  width: 100%;
  font-style: normal;
  overflow: hidden;
  background: ${COLOR.bg};
  background-image: url(${images.bgHome});
  background-position: center;
  background-repeat: repeat;
  background-size: contain;
`
const Container = styled.div`
  padding: 0 70px;
  @media (max-width: 575px) {
    padding: 60px 5px;
  }
`

const MarketPlace: React.FC<React.PropsWithChildren> = () => {
  useEffect(() => {
    AOS.init({
      disable: function () {
        var maxWidth = 1000
        return window.innerWidth < maxWidth
      },
    })
  })

  return (
    <>
      <Wrapper>
        <Container>
          <NFTCard />
        </Container>
      </Wrapper>
    </>
  )
}

export default MarketPlace
