const color = {
    bgBalck: '#0A0A0B',
    bgGray: '#221E1E',
    title: '#F2ECDB',
    titleWhite: '#FAFAF9',
    label: '#F5F4F4',
    brown: '#B7A7A7',
    textFooter: '#E4E0E0',
    yellow: '#FCD535'
}
export default color