export const dataTeams = [
  {
    name: 'William',
    job: 'CEO & Co-founder',
    description:
      'Experienced entrepreneur and business strategy consultant. Over 28 years of experience in sales & marketing. Founder of specialist online marketing consultancy. Specific experience working with Internet startups developing systems and processes for planned growth.',
    images: '/images/DarkKnight/william.svg',
  },

  {
    name: 'Andrew Faridani',
    job: 'CMO & Co-founder',
    description:
      'Founder of Canada’s largest local digital agency, with 18 years experience, specializing in online advertising, branding, and social media. Vast experience in staff management, business processes, systems, scaling and development.',
    images: '/images/DarkKnight/andrew.svg',
  },

  {
    name: 'Calvin Adamus',
    job: 'CPO & Co-founder',
    description:
      'Gaimin creator with product-specific knowledge. Strategic advisor, community building and referral program specialist. Extensive experience developing concepts through to reality. Multi-lingual and world travelled.',
    images: '/images/DarkKnight/calvin.svg',
  },
]
