import React, { useState, useEffect } from 'react'
import { Box, BoxProps, Text, TextProps, SkeletonText } from '@chakra-ui/react'
import useResponsive from 'hooks/useResponsive'

type CustomTextProps = {
  containerProps?: BoxProps
} & TextProps

const CustomText = ({ containerProps, ...props }: CustomTextProps) => {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false)
    }, 1000) // Adjust the time as needed

    return () => clearTimeout(timer)
  }, [])

  return (
    <Box w="100%" h="100%" {...containerProps}>
      <SkeletonText isLoaded={!isLoading} startColor="gray.400" endColor="gray.300" w="100%" noOfLines={1}>
        <Text {...props} />
      </SkeletonText>
    </Box>
  )
}

export default CustomText
