import { Stack } from '@chakra-ui/react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { Access } from 'components/Access'
import { Crafting } from 'components/Crafting'
import { DarkToken } from 'components/DarkToken'
import { Hero } from 'components/Hero'
import { JoinCommunity } from 'components/JoinCommunity'
import { Preseason } from 'components/Preseason'
import { Roadmap } from 'components/Roadmap'
import COLOR from 'constant/COLOR'
import images from 'constant/PICTURE/images'
import useAuth from 'hooks/useAuth'
import { useWallet } from 'hooks/useWallet'
import React, { useEffect } from 'react'
import { GetMe } from 'service/UserService'
import styled from 'styled-components'
import { useWeb3React } from '../../../packages/wagmi/src/useWeb3React'
import BoxStore from 'components/BoxStore'
const Wrapper = styled.div`
  width: 100%;
  font-style: normal;
  overflow: hidden;
  background: ${COLOR.bg};
  background-image: url(${images.bgHome});
  background-position: center;
  background-repeat: repeat;
  background-size: contain;
`
const Container = styled.div`
  padding: 0 50px;
  @media (max-width: 575px) {
    padding: 0 5px;
  }
`

const HomePage: React.FC<React.PropsWithChildren> = () => {
  // const { connetSocial } = useAuth()
  // const { account } = useWeb3React()

  // const checkOpenGame = async () => {
  //   try {
  //     const token = localStorage.getItem('TokenGame')
  //     await GetMe(token)
  //   } catch (error) {
  //     const errorResponse = (error as any).response
  //     if (errorResponse.status === 401 && account) {
  //       connetSocial()
  //     }
  //   }
  // }
  // useEffect(() => {
  //   checkOpenGame()
  // }, [account])
  useEffect(() => {
    AOS.init({
      disable: function () {
        var maxWidth = 1000
        return window.innerWidth < maxWidth
      },
    })
  })
  const link = window.location.href

  const review = () => {
    if (link.indexOf('roadmap') !== -1) {
      window.location.href = '#roadmap'
      window.history.replaceState(null, null, window.location.origin)
    }
  }

  useEffect(() => {
    review()
  }, [link])
  return (
    <>
      <Wrapper>
        <Hero />
        <Container>
          <Preseason />
          <Crafting />
          <DarkToken />
          <Access />
          <Stack id="roadmap">
            <Roadmap />
          </Stack>

          <JoinCommunity />
        </Container>
      </Wrapper>
    </>
  )
}

export default HomePage
