/* eslint-disable no-return-assign */
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import AOS from 'aos'
import 'aos/dist/aos.css'

import { Flex, Stack } from '@chakra-ui/react'
import { UserMenuProps } from '@pancakeswap/uikit/src/widgets/Menu/components/UserMenu/types'
import CustomImage from 'components/CustomSkeleton/CustomImage'
import CustomText from 'components/CustomSkeleton/CustomText'
import { DefaultProduct } from 'components/Data/ProductDefault'
import ProfileCard from 'components/ProfileCard'
import images from 'constant/PICTURE/images'
import useResponsive from 'hooks/useResponsive'
import { useWeb3React } from '../../../packages/wagmi/src/useWeb3React'
import COLOR from 'constant/COLOR'

const Wrapper = styled.div`
  font-style: normal;
  overflow: hidden;
  background: ${COLOR.bg};
  background-image: url(${images.bgHome});
  background-position: center;
  background-repeat: repeat;
  background-size: contain;
`
const Container = styled.div`
  padding: 0 10px 100px 450px;
  @media (max-width: 1024px) {
    padding: 0 10px 100px 420px;
  }
  @media (max-width: 900px) {
    padding: 0 10px 100px 20px;
  }
  @media (max-width: 575px) {
    padding: 60px 5px;
  }
`

const ButtonLoad = styled.button`
  display: flex;
  width: 223px;
  padding: 15px;
  justify-content: center;
  align-items: center;
  color: ${COLOR.white};
  font-family: Anek Bangla;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: none;
  text-transform: uppercase;
  cursor: pointer;
  background-image: url(${images.load});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  touch-action: manipulation;
  transition: all 235ms ease-in-out;
  &:hover {
    text-decoration: none;
    transform: translate3d(0, 2px, 0);
  }
`
export const LabelText = styled.div`
  color: ${COLOR.profileText};
  text-shadow: 0px 4px 5px ${COLOR.profileShadow};
  font-family: Anek Bangla;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media (max-width: 900px) {
    font-size: 25px;
  }
  @media (max-width: 575px) {
    font-size: 20px;
  }
`
export const ProfileText = styled.text`
  color: ${COLOR.profileText};
  text-shadow: 0px 4px 5px ${COLOR.profileShadow};
  font-family: Anek Bangla;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media (max-width: 900px) {
    font-size: 25px;
  }
  @media (max-width: 575px) {
    font-size: 20px;
  }
`
const Profile: React.FC<UserMenuProps> = ({ text }) => {
  useEffect(() => {
    AOS.init({
      disable: function () {
        var maxWidth = 1000
        return window.innerWidth < maxWidth
      },
    })
  })
  const { account, chainId } = useWeb3React()
  const accountEllipsis = account ? `${account.substring(0, 2)}...${account.substring(account.length - 4)}` : null

  const { isMobile, isMobileOrTablet, isTabletOrLaptop, isBigScreen } = useResponsive()
  const [products, setProduct] = useState(DefaultProduct)
  const [visible, setVisible] = useState(4)

  const loadMore = () => {
    setVisible((prevVisible) => prevVisible + 4)
    setHasClickedLoadMore(true)
  }
  const showLess = () => {
    setVisible((prevVisible) => Math.max(prevVisible - 4, 0))
  }
  const [hasClickedLoadMore, setHasClickedLoadMore] = useState(false)
  const itemRefs = useRef([])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [hasClickedLoadMore])
  useEffect(() => {
    if (hasClickedLoadMore && itemRefs.current[visible - 4]) {
      itemRefs.current[visible - 4].scrollIntoView({ behavior: 'smooth' })
    }
  }, [visible, hasClickedLoadMore])
  return (
    <>
      <Wrapper>
        <Stack>
          <img src={images.profileCover} alt="" />
          <Stack position="relative">
            <Stack
              position="absolute"
              top="50%"
              left={isMobileOrTablet ? '50%' : isTabletOrLaptop ? '20%' : isBigScreen ? '15%' : '13%'}
              transform="translate(-50%, -50%)"
            >
              <CustomImage
                style={{
                  position: 'absolute',
                  top: '98%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
                src={images.paperProfile}
              />
              <img style={{ zIndex: 1, width: isMobileOrTablet ? '250px' : '100%' }} src={images.avtFrame} />
              <CustomImage
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: isMobile ? '125px' : isMobileOrTablet ? '150px' : '235px',
                }}
                src="/images/DarkKnight/william.svg"
                alt=""
              />
              <Stack
                style={{
                  position: 'absolute',
                  top: '100%',
                  left: isMobile ? '73%' : '55%',
                  transform: 'translate(-50%, -50%)',
                  width: '230px',
                }}
              >
                <Flex justifyContent="flex-start" alignItems="center" gap="10px" mt="50px">
                  <img style={{ width: '30px' }} src={images.walletIcon} />
                  <CustomText>
                    <LabelText title={typeof text === 'string' ? text || account : account}>
                      {text || accountEllipsis}
                    </LabelText>
                  </CustomText>
                </Flex>
                <Flex justifyContent="flex-start" alignItems="center" gap="10px">
                  <img style={{ width: '30px' }} src={images.darkIcon} />
                  <CustomText>
                    <ProfileText>100 $DARK</ProfileText>
                  </CustomText>
                </Flex>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Container>
          <Stack
            justifyContent="center"
            mt={isMobile ? '170px' : isMobileOrTablet ? '250px' : '50px'}
            color="black"
            alignItems="center"
          >
            <Stack>
              <img src={images.myCollection} alt="" />
            </Stack>
          </Stack>

          <Flex
            sx={{
              margin: 'auto',
              flexWrap: 'wrap',
              marginTop: 8,
              gap: '50px',
              justifyContent: isMobileOrTablet ? 'center' : 'flex-start',
            }}
          >
            {products.slice(0, visible).map((item, index) => (
              <div ref={(ref) => (itemRefs.current[index] = ref)} key={item.id}>
                <ProfileCard product={item} />
              </div>
            ))}
          </Flex>
          <Stack direction={isMobileOrTablet ? 'column' : 'row'} justifyContent="center" p="75px" alignItems="center">
            {visible < products.length && <ButtonLoad onClick={loadMore}>Load More</ButtonLoad>}
            {hasClickedLoadMore && visible > 5 && <ButtonLoad onClick={showLess}>Show Less</ButtonLoad>}
          </Stack>
        </Container>
      </Wrapper>
    </>
  )
}

export default Profile
