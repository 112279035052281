import React from 'react'
import styled from 'styled-components'
import 'aos/dist/aos.css'
import { Flex, Stack } from '@chakra-ui/react'
import CustomImage from 'components/CustomSkeleton/CustomImage'
import CustomText from 'components/CustomSkeleton/CustomText'
import useResponsive from 'hooks/useResponsive'
import { dataTeams } from './Data'
import COLOR from 'constant/COLOR'
import images from 'constant/PICTURE/images'
const Wrapper = styled.div`
  font-style: normal;
  overflow: hidden;
  background: ${COLOR.bg};
  background-image: url(${images.bgHome});
  background-position: center;
  background-repeat: repeat;
  background-size: contain;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px;
  @media (max-width: 575px) {
    font-size: 25px;
    padding: 0;
  }
`
const Header = styled.text`
  border-bottom: 1px solid ${COLOR.white};
  font-family: Russo One;
  font-size: 65px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 50px;
  color: ${COLOR.white};
  @media (max-width: 900px) {
    font-size: 60px;
    line-height: normal;
  }
  @media (max-width: 575px) {
    font-size: 25px;
  }
`
const TextName = styled.text`
  color: ${COLOR.white};
  text-align: center;
  font-family: Anek Bangla;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`
const TextJob = styled.text`
  color: ${COLOR.white};
  font-family: Anek Bangla;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`
const TextDescription = styled.text`
  color: ${COLOR.white};
  text-align: center;
  font-family: Anek Bangla;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media (max-width: 1440px) {
    font-size: 12px;
  }
  @media (max-width: 575px) {
    font-size: 10px;
  }
`
const Content = styled.text`
  color: ${COLOR.white};
  text-align: center;
  font-family: Anek Bangla;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 50px; /* 138.889% */
  @media (max-width: 575px) {
    font-size: 15px;
    line-height: 20px; /* 138.889% */
  }
`
const ButtonJoin = styled.text`
  color: #33a1c6;
  text-align: center;
  text-shadow: 0px 4px 4px ${COLOR.teamShadow};
  font-family: Anek Bangla;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 50px; /* 138.889% */
`

const WrapperInfo = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  background-image: url(${images.bttTeam});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 44px;
  width: 350px;
  gap: 10px;
  @media (max-width: 1440px) {
    gap: 0;
  }

  @media (max-width: 575px) {
    width: 100%;
  }
  transition: all 235ms ease-in-out;
  &:hover {
    text-decoration: none;
    transform: translate3d(0, 2px, 0);
  }
`

const Team: React.FC<React.PropsWithChildren> = () => {
  const { isMobile, isMobileOrTablet, isTabletOrLaptop, isBigScreen } = useResponsive()

  return (
    <>
      <Wrapper>
        <Container>
          <Stack
            justifyContent="center"
            alignItems="center"
            marginBottom="100px"
            gap="20px"
            mt={isMobileOrTablet ? '100px' : 0}
          >
            <Header>TEAM</Header>
            <Content>
              Our team has a wealth of experience in multiple industries ranging from digital marketing, sales,
              blockchain, and machine learning.
            </Content>
            <WrapperInfo>
              {' '}
              <ButtonJoin>Join the team</ButtonJoin>
            </WrapperInfo>
          </Stack>
          <Stack justifyContent="center" alignItems="center" gap="20px">
            <Header>OUR TEAM</Header>
            <Content>
              The Founders have all known each other for in excess of 12+ years. Having worked together in several
              countries across different continents, the team have extensive experience of solving problems and creating
              solutions together.
            </Content>
            <Stack direction={isTabletOrLaptop ? 'column' : 'row'} mt="70px">
              {dataTeams.map((item, index) => (
                <Stack position="relative" key={index}>
                  <Stack position="absolute" top="10%" left="50%" transform="translate(-50%, -50%)" zIndex={1}>
                    <img src="/images/DarkKnight/avtFrame.svg" />
                    <CustomImage
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '150px',
                      }}
                      src={item.images}
                    />
                  </Stack>
                  <Stack position="relative" textAlign="center">
                    <img src="/images/DarkKnight/profileTeam.png" />
                    <Stack
                      position="absolute"
                      top="50%"
                      left="50%"
                      transform="translate(-50%, -50%)"
                      gap={isBigScreen ? '0' : '10px'}
                    >
                      <CustomText>
                        <TextName>{item.name}</TextName>
                      </CustomText>
                      <CustomText>
                        <TextJob>{item.job}</TextJob>
                      </CustomText>
                      <CustomText style={{ lineHeight: isMobile ? 0 : 'normal' }}>
                        <TextDescription>{item.description}</TextDescription>
                      </CustomText>

                      <Flex justifyContent="center">
                        <Stack
                          cursor="pointer"
                          transition="all 235ms ease-in-out"
                          _hover={{
                            transform: ' translate3d(0, 2px, 0)',
                          }}
                        >
                          <img style={{ width: '80px' }} src="/images/DarkKnight/twitter.png" alt="" />
                        </Stack>
                        <Stack
                          cursor="pointer"
                          transition="all 235ms ease-in-out"
                          _hover={{
                            transform: ' translate3d(0, 2px, 0)',
                          }}
                        >
                          <img style={{ width: '80px' }} src="/images/DarkKnight/telegram.png" alt="" />
                        </Stack>
                      </Flex>
                    </Stack>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Container>
      </Wrapper>
    </>
  )
}

export default Team
