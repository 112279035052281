const ArrowB = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_1752_5088)">
        <path
          d="M20 10.0112L10 20.0112L9.99878 20.0102L0 10.0112L2.74719 7.26404L9.99878 14.5156L10 14.5167L17.2528 7.26404L20 10.0112Z"
          fill="#F7A92F"
        />
        <path
          d="M20 2.78625L10 12.7863L9.99878 12.7852L0 2.78625L2.74719 0.0390625L10 7.29187L17.2528 0.0390625L20 2.78625Z"
          fill="#E2CE92"
        />
        <path
          d="M17.2521 7.26416L19.9993 10.0114L9.99927 20.0114L9.99805 20.0103V14.5157L9.99927 14.5168L17.2521 7.26416Z"
          fill="#F7A92F"
        />
        <path
          d="M9.99927 12.7863L9.99805 12.7852V7.29065L9.99927 7.29187L17.2521 0.0390625L19.9993 2.78625L9.99927 12.7863Z"
          fill="#E2CE92"
        />
      </g>
      <defs>
        <clipPath id="clip0_1752_5088">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ArrowB
