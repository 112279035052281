import images from 'constant/PICTURE/images'

export const DataMystery = [
  {
    desc: 'Each Mystery Box contains a range of NFTs Card Dark Knight. Each type of box has its minimum and maximum content value which is randomly selected when purchasing.',
    img: `${images.m1}`,
  },
  {
    desc: 'When purchasing a Mystery Box you won’t know its content until the Mystery Box Unlock Event. You agree to take a chance to win a prize far above the amount you paid, or less.',
    img: `${images.m2}`,
  },

  {
    desc: 'Please note that the total maximum value of each Mystery Box is subject to change due to fluctuations in $DARK token price and future pricing of Consumables.',
    img: `${images.m3}`,
  },
]
