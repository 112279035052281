import { Button, Flex, Stack, Text } from '@chakra-ui/react'
import 'aos/dist/aos.css'
import CustomImage from 'components/CustomSkeleton/CustomImage'
import CustomText from 'components/CustomSkeleton/CustomText'
import COLOR from 'constant/COLOR'
import images from 'constant/PICTURE/images'
import useResponsive from 'hooks/useResponsive'
import React from 'react'
import styled from 'styled-components'
import { DataCard } from '../DataCard'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
  padding: 150px 250px;
  @media (max-width: 1024px) {
    margin-top: 50px;
    padding: 50px;
  }
  @media (max-width: 900px) {
    padding: 50px;
  }
  @media (max-width: 575px) {
    margin-top: 50px;
    padding: 50px;
  }
`
const Card = styled.div`
  display: flex;
  flex-direction: row;
  gap: 50px;
  justify-content: flex-start;

  flex-wrap: wrap;
  position: relative;
  @media (max-width: 1024px) {
    gap: 37px;
  }
  @media (max-width: 900px) {
    margin-top: 20px;

    gap: 87px;
  }
  @media (max-width: 575px) {
    margin-top: 20px;
    justify-content: center;
  }
`

const data = [
  {
    number: 240,
    title: 'Total Sale',
  },
  {
    number: 100,
    title: 'Purchase',
  },
  {
    number: 100,
    title: 'Card',
  },
]
const ANIMATION_TIME = 500

const Section1: React.FC<React.PropsWithChildren> = () => {
  const { isMobile, isMobileOrTablet, isTabletOrLaptop, isBigScreen } = useResponsive()

  return (
    <>
      <Container>
        <Flex direction={isMobileOrTablet ? 'column' : 'row'} justifyContent="space-between">
          <Stack w={isMobileOrTablet ? '100%' : '50%'} gap="30px">
            <Text color={COLOR.white} fontFamily="Anek Bangla" fontSize={isMobile ? '40px' : '67px'} fontWeight={600}>
              Discover & Collect NFTs
            </Text>
            <Text color={COLOR.white} fontFamily="Anek Bangla" fontSize={isMobile ? '18px' : '25px'} fontWeight={400}>
              The collection includes 100+ algorithmically - generated “Dark Knight” NFTs Card, some are rarer than
              others, with a few that are legendary.
            </Text>
            <Button
              w={isTabletOrLaptop ? '100%' : '30%'}
              h="50px"
              borderRadius="20px"
              border={`1px solid ${COLOR.bttGetBox}`}
              bg={COLOR.bgButton}
              color={COLOR.bttGetBox}
              _hover={{ bg: 'none' }}
            >
              Get Started
            </Button>
            <Flex gap="30px" justifyContent="space-between">
              {data.map((item, index) => (
                <Stack gap="10px" key={index}>
                  <Text color={COLOR.white} fontFamily="Anek Bangla" fontSize="28px" fontWeight={700}>
                    {item.number}k +
                  </Text>
                  <Text color={COLOR.white} fontFamily="Anek Bangla" fontSize="24px" fontWeight={400}>
                    {item.title}
                  </Text>
                </Stack>
              ))}
            </Flex>
          </Stack>
          <Card>
            <Stack
              w="100%"
              h="100%"
              bg={COLOR.bgButton}
              sx={{
                borderRadius: '20px',
              }}
            >
              <CustomImage
                sx={{
                  transition: `all ${ANIMATION_TIME}ms ease-in-out`,
                }}
                width="100%"
                height="100%"
                src={images.fairy}
                alt=""
                transition="all 235ms ease-in-out"
                _hover={{
                  transform: ' translate3d(0, 2px, 0)',
                }}
              />
              <Text
                color={COLOR.white}
                fontFamily="Anek Bangla"
                fontSize="38px"
                fontWeight={600}
                textAlign="center"
                p="20px"
              >
                Fairy Sword
              </Text>
            </Stack>
          </Card>
        </Flex>
        <Stack w="100%" gap="50px">
          <Flex direction={isMobile ? 'column' : 'row'} justifyContent="space-between">
            <Stack>
              <Text color={COLOR.white} fontFamily="Anek Bangla" fontSize="38px" fontWeight={600}>
                The NFTs Card
              </Text>
              <Text color={COLOR.white} fontFamily="Anek Bangla" fontSize="22px" fontWeight={400}>
                Explore new trending NFTs
              </Text>
            </Stack>
            <Button
              p="0 20px"
              h="60px"
              borderRadius="20px"
              border={`1px solid ${COLOR.bttGetBox}`}
              bg={COLOR.bgButton}
              color={COLOR.bttGetBox}
              mt="20px"
              _hover={{ bg: 'none' }}
            >
              <Flex gap="10px">
                <img src={images.eyeIcon} alt="" />
                <Text color={COLOR.bttGetBox} fontFamily="Anek Bangla" fontSize="18px" fontWeight={600}>
                  See All
                </Text>
              </Flex>
            </Button>
          </Flex>
          <Flex gap="30px" justifyContent="space-between" direction={isMobile ? 'column' : 'row'}>
            {DataCard.map((i, index) => (
              <Card key={index}>
                <Stack
                  w="100%"
                  h="100%"
                  bg={COLOR.bgButton}
                  sx={{
                    borderRadius: '20px',
                  }}
                >
                  <CustomImage
                    sx={{
                      transition: `all ${ANIMATION_TIME}ms ease-in-out`,
                    }}
                    width="100%"
                    height="100%"
                    src={i.img}
                    alt=""
                    transition="all 235ms ease-in-out"
                    _hover={{
                      transform: ' translate3d(0, 2px, 0)',
                    }}
                  />

                  <Stack p="10px 0 20px 0" gap="20px" justifyContent="center" alignItems="center">
                    <Stack textOverflow="ellipsis" h="32px">
                      <CustomText
                        fontFamily="Anek Bangla"
                        fontWeight={600}
                        fontSize="22px"
                        width="100%"
                        textAlign="center"
                        color={COLOR.white}
                      >
                        {i.name}
                      </CustomText>
                    </Stack>

                    <Flex justifyContent="space-between" gap={isMobileOrTablet ? '10px' : '30px'}>
                      <CustomText
                        display="flex"
                        flexDirection="row"
                        fontWeight={600}
                        fontSize="1rem"
                        width="100%"
                        textAlign="center"
                        color={COLOR.white}
                        gap="10px"
                      >
                        <img src="/images/DarkKnight/1.svg" alt="" />
                        <Text>{i.attack}</Text>
                      </CustomText>
                      <CustomText
                        display="flex"
                        flexDirection="row"
                        fontWeight={600}
                        fontSize="1rem"
                        width="100%"
                        textAlign="center"
                        color={COLOR.white}
                        gap="10px"
                      >
                        <img src="/images/DarkKnight/2.svg" alt="" />
                        <Text>{i.accuracy}</Text>
                      </CustomText>
                      <CustomText
                        display="flex"
                        flexDirection="row"
                        fontWeight={600}
                        fontSize="1rem"
                        width="100%"
                        textAlign="center"
                        color={COLOR.white}
                        gap="10px"
                      >
                        <img src="/images/DarkKnight/3.svg" alt="" />
                        <Text>{i.attributes}</Text>
                      </CustomText>
                    </Flex>
                    <Flex justifyContent="space-between" w="100%" p=" 0 30px">
                      <Stack>
                        <Text fontFamily="Anek Bangla" fontWeight={600} fontSize="12px" width="100%" color={COLOR.gray}>
                          Price
                        </Text>
                        <CustomText
                          fontFamily="Anek Bangla"
                          fontWeight={600}
                          fontSize="16px"
                          width="100%"
                          textAlign="center"
                          color={COLOR.white}
                        >
                          {i.price} $DARK
                        </CustomText>
                      </Stack>
                      <Stack>
                        <Text
                          fontFamily="Anek Bangla"
                          fontWeight={600}
                          fontSize="12px"
                          width="100%"
                          textAlign="center"
                          color={COLOR.gray}
                        >
                          Highest Bid
                        </Text>
                        <CustomText
                          fontFamily="Anek Bangla"
                          fontWeight={500}
                          fontSize="16px"
                          width="100%"
                          textAlign="center"
                          color={COLOR.white}
                        >
                          {i.bid} wETH
                        </CustomText>
                      </Stack>
                    </Flex>
                  </Stack>
                </Stack>
              </Card>
            ))}
          </Flex>
        </Stack>
      </Container>
    </>
  )
}

export default Section1
