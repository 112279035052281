import styled from 'styled-components'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'
import images from 'constant/PICTURE/images'

import CustomImage from 'components/CustomSkeleton/CustomImage'
import COLOR from 'constant/COLOR'
import { Link } from 'react-router-dom'
import APP_ROUTES from 'constant/APP_ROUTES'

const ButtonLarge = styled.button`
  background: none;
  border: none;
  display: flex;
  padding: 60px 75px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  background-image: url(${images.buttonLarge});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  min-width: 150px;
  color: ${COLOR.button};
  text-shadow: 0px 2px 0px ${COLOR.bttTextShadow};
  font-family: Anek Bangla;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
  width: fit-content;
  white-space: nowrap;
  touch-action: manipulation;
  transition: all 235ms ease-in-out;
  &:hover {
    text-decoration: none;
    color: ${COLOR.hoverBtt};
    transform: translate3d(0, 2px, 0);
    text-shadow: 0px 4px 4px ${COLOR.hoverYellow};
  }

  @media (max-width: 575px) {
    font-size: 14px;
    padding: 15px 45px;
  }
`
const Button = styled.a`
  display: flex;
  padding: 15px 180px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  background-image: url(${images.button});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  min-width: 250px;
  color: ${COLOR.button};
  text-shadow: 0px 2px 0px ${COLOR.bttTextShadow};
  font-family: Anek Bangla;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
  width: fit-content;
  white-space: nowrap;
  touch-action: manipulation;
  transition: all 235ms ease-in-out;
  &:hover {
    text-decoration: none;
    color: ${COLOR.hoverBtt};
    transform: translate3d(0, 2px, 0);
    text-shadow: 0px 4px 4px ${COLOR.hoverYellow};
  }

  @media (max-width: 575px) {
    font-size: 14px;
    padding: 15px 100px;
  }
`

const WhoAccess = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 45px;
  overflow: hidden;
  @media (max-width: 575px) {
    padding: 25px 0;
    gap: 20px;
  }
`
const WrapImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: transform 330ms ease-in-out;

  &:hover {
    transform: translateY(-25px);
  }
  @media (max-width: 900px) {
    &:hover {
      transform: none;
    }
  }
`
const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 45px;
  color:${COLOR.white};
  text-align: center;

  .heading {
    font-family: Anek Bangla;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
  @media (max-width: 900px) {
    gap: 20px;
    .heading {
      font-size: 25px;
    }
  }
  @media (max-width: 575px) {
    .heading {
      font-size: 20px;
    }
`
const Desc = styled.text`
  font-family: Anek Bangla;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 50px;
  text-align: center;

  @media (max-width: 900px) {
    font-size: 20px;
    line-height: normal;
  }
  @media (max-width: 575px) {
    font-size: 18px;
  }
`
const Title = styled.text`
  // border-bottom: 1px solid ${COLOR.white};
  color: #030205;

  font-family: Russo One;
  font-size: 65px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  @media (max-width: 900px) {
    font-size: 30px;
    line-height: normal;
  }
  @media (max-width: 575px) {
    font-size: 30px;
  }
`
const CardList = styled.div`
  display: flex;
  padding: 20px 0px;
  align-items: center;
  gap: 28px;
  @media (max-width: 900px) {
    justify-content: center;
  }
  @media (max-width: 575px) {
    flex-direction: column;
    padding: 20px;
  }
`
const Card = styled.div`
  width: calc((100% - 28px) / 3);
  border: none;
  background-color: transparent;
  overflow: inherit;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 900px) {
    width: 100%;
  }
`
const Text = styled.text`
  color: ${COLOR.white};
  text-align: center;
  font-family: DM Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  text-transform: uppercase;
  height: 60px;
  text-overflow: ellipsis;
`
const HowToAccess = styled.div`
  display: flex;
  gap: 45px;
  flex-direction: row;
  justify-content: center;
  @media (width <=820px) {
    gap: 22px;
    flex-wrap: wrap;
  }
  @media (max-width: 575px) {
    flex-direction: column;
    gap: 12px;
  }
`
const Banner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: transform 330ms ease-in-out;
  &:hover {
    transform: translateY(-25px);
  }
  @media (max-width: 900px) {
    &:hover {
      transform: none;
    }
  }
`

export const Access = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 })
  }, [])

  return (
    <>
      <section className="whoaccess">
        <WhoAccess>
          <TextBox className="textbox">
            <Title
              className="title"
              style={{
                WebkitTextStroke: `3px ${COLOR.white}`,
                WebkitTextStrokeWidth: '2px',
                textShadow: `0px 5px 5px ${COLOR.bgTitleMarket}`,
              }}
            >
              WHO HAS ACCESS
            </Title>
          </TextBox>
          <CardList className="cardlist">
            <Card className="card" data-aos="fade-up">
              <WrapImage className="wrapimage">
                <CustomImage loading="lazy" src={images.access1} />
              </WrapImage>
              <Text className="text">GOLD/SILVER/JADE DARK REDEEMERS PRIOR TO PRESEASON LAUNCH</Text>
            </Card>
            <Card className="card" data-aos="fade-up">
              <WrapImage className="wrapimage">
                <CustomImage loading="lazy" src={images.access2} />
              </WrapImage>
              <Text className="text">SPACE HOLDERS BEFORE 10/11/2023</Text>
            </Card>
            <Card className="card" data-aos="fade-up">
              <WrapImage className="wrapimage">
                <CustomImage loading="lazy" src={images.access3} />
              </WrapImage>
              <Text className="text">ACHIEVE RANK 20 IN ANY PATCH</Text>
            </Card>
          </CardList>
        </WhoAccess>
      </section>
      <section className="howtoaccess">
        <WhoAccess className="container" data-aos="fade-up">
          <TextBox className="textbox">
            <Title
              className="title"
              style={{
                WebkitTextStroke: `3px ${COLOR.white}`,
                WebkitTextStrokeWidth: '2px',
                textShadow: `0px 5px 5px ${COLOR.bgTitleMarket}`,
              }}
            >
              HOW TO GET ACCESS
            </Title>
            <Desc className="desc">
              Watch Dark Knight on Twitch for a chance to gain an access key to the Preseason!
            </Desc>
          </TextBox>
          <Banner className="howtoaccess__image wrapimage">
            <CustomImage loading="lazy" src={images.howAccess} />
          </Banner>
          <HowToAccess className="howtoaccess__cta">
            <Button rel="noreferrer noopener" target="_blank" href="https://twitter.com/dark_knightgame">
              START NOW
            </Button>
            <Link to={APP_ROUTES.leaderBoard}>
              <ButtonLarge>CHECK OUT THE LEADERBOARD</ButtonLarge>
            </Link>
          </HowToAccess>
        </WhoAccess>
      </section>
    </>
  )
}
