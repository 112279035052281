const APP_ROUTES = {
  marketPlace: '/Marketplace',
  marketplaceDetail: `/Marketplace/detail`,
  home: '/',
  profile: '/Profile',
  team: '/Team',
  getBox: '/GetBox',
  airdrop: '/Airdrop',
  roadmap: '/roadmap',
  userBox: '/Box',
  boxStore: '/BoxStore',
  leaderBoard: '/LeaderBoard',
}

export default APP_ROUTES
