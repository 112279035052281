/* eslint-disable no-return-assign */
import {
  ButtonGroup,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import CustomImage from 'components/CustomSkeleton/CustomImage'
import CustomText from 'components/CustomSkeleton/CustomText'
import { ProductType } from 'components/Data/models/ProductType'
import images from 'constant/PICTURE/images'
import APP_ROUTES from 'constant/APP_ROUTES'
import COLOR from 'constant/COLOR'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const ButtonDetail = styled.button`
  display: flex;
  margin-top: 10px;
  padding: 35px 45px 45px 45px;
  color: ${COLOR.black};
  font-family: Anek Bangla;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: none;
  text-transform: capitalize;
  cursor: pointer;
  background-image: url(${images.bttPD});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  touch-action: manipulation;
  transition: all 235ms ease-in-out;
  &:hover {
    text-decoration: none;
    transform: translate3d(0, 2px, 0);
  }
`
const ButtonBuy = styled.button`
  margin-left: 10px;

  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  color: ${COLOR.white};
  font-family: Anek Bangla;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: none;
  text-transform: capitalize;
  cursor: pointer;
  background-image: url(${images.bttSale});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  touch-action: manipulation;
  transition: all 235ms ease-in-out;
  &:hover {
    text-decoration: none;
    transform: translate3d(0, 2px, 0);
  }
`

const Card = styled.div`
  display: flex;
  flex-direction: row;
  gap: 50px;
  justify-content: flex-start;

  flex-wrap: wrap;
  position: relative;
  @media (max-width: 1024px) {
    gap: 37px;
  }
  @media (max-width: 900px) {
    gap: 87px;
  }
  @media (max-width: 575px) {
    justify-content: center;
  }
`
const WrapperContent = styled.div`
  color: ${COLOR.wProduct};
  width: 270px;
  height: 400px;
  box-shadow: 0 1px 5px ${COLOR.sProduct};
  transition-duration: 300ms;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-out;
  transform: rotate3d(0);
  &:hover {
    transition-duration: 150ms;
    box-shadow: 0 5px 20px 5px ${COLOR.hProduct};
  }
`
const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`
const ANIMATION_TIME = 500

const ProfileCard = ({ product }: { product: ProductType }) => {
  let bounds: DOMRect | null
  const inputRef = useRef<HTMLDivElement | null>(null)
  const glowRef = useRef<HTMLDivElement | null>(null)

  const rotateToMouse = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (inputRef.current) {
      bounds = inputRef.current.getBoundingClientRect()
      const mouseX = e.clientX
      const mouseY = e.clientY
      const leftX = mouseX - bounds.x
      const topY = mouseY - bounds.y
      const center = {
        x: leftX - bounds.width / 2,
        y: topY - bounds.height / 2,
      }
      const distance = Math.sqrt(center.x ** 2 + center.y ** 2)

      inputRef.current.style.transform = `
      scale3d(1.07, 1.07, 1.07)
      rotate3d(
        ${center.y / 100},
        ${-center.x / 100},
        0,
        ${Math.log(distance) * 2}deg
      )
    `
      if (glowRef.current) {
        glowRef.current.style.backgroundImage = `
        radial-gradient(
          circle at
          ${center.x * 2 + bounds.width / 2}px
          ${center.y * 2 + bounds.height / 2}px,
          #ffffff55,
          #0000000f
        )
      `
      }
    }
  }

  const removeListener = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (inputRef.current) {
      inputRef.current.style.transform = ''
      inputRef.current.style.background = ''
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(() => {}, [])

  const [dataFilter, setDataFilter] = useState<ProductType>()

  useEffect(() => {
    setDataFilter(product)
  }, [])
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure()

  return (
    <Card>
      <Stack
        style={{
          perspective: '1500px',
        }}
        // w="full"
        // h="full"
        position="relative"
        spacing={0}
        _hover={{
          '.hover_item': {
            opacity: 1,
          },
          '.hover_item .button_group': {
            transform: 'translateX(0)',
          },
          '.hover_item .icon': {
            transform: 'translateY(0)',
            opacity: 1,
          },
        }}
      >
        <Stack
          w="full"
          h="full"
          bg={COLOR.white}
          boxShadow={`0px 10px 20px 0px ${COLOR.shadowCard}`}
          sx={{
            borderRadius: '20px',
          }}
        >
          <div
            style={{
              width: '100%',
              height: '100%',
              overflow: 'hidden',
              borderRadius: '1rem',
              perspective: '1500px',
            }}
          >
            <WrapperContent ref={inputRef} onMouseLeave={removeListener} onMouseMove={rotateToMouse}>
              <Wrapper ref={glowRef}>
                <CustomImage
                  style={{
                    transition: `all ${ANIMATION_TIME}ms ease-in-out`,
                  }}
                  width="270px"
                  height="400px"
                  src={product.img}
                  alt=""
                />
              </Wrapper>
            </WrapperContent>
          </div>
          <Stack p="10px 0 20px 0" gap="10px" justifyContent="center" alignItems="center">
            <Stack textOverflow="ellipsis" h="32px">
              <CustomText
                fontFamily="Anek Bangla"
                fontWeight={500}
                fontSize="18px"
                width="100%"
                textAlign="center"
                color={COLOR.idCard}
              >
                ID:{product.id}
              </CustomText>
            </Stack>

            <Flex justifyContent="space-between" gap="30px">
              <CustomText
                display="flex"
                flexDirection="row"
                fontWeight={600}
                fontSize="1rem"
                width="100%"
                textAlign="center"
                color={COLOR.black}
                gap="10px"
              >
                <img src={images.attackIcon} alt="" />
                <Text>{product.attack}</Text>
              </CustomText>
              <CustomText
                display="flex"
                flexDirection="row"
                fontWeight={600}
                fontSize="1rem"
                width="100%"
                textAlign="center"
                color={COLOR.black}
                gap="10px"
              >
                <img src={images.criticalIcon} alt="" />
                <Text>{product.critical}</Text>
              </CustomText>
              <CustomText
                display="flex"
                flexDirection="row"
                fontWeight={600}
                fontSize="1rem"
                width="100%"
                textAlign="center"
                color={COLOR.black}
                gap="10px"
              >
                <img src={images.accuracyIcon} alt="" />
                <Text>{product.accuracy}</Text>
              </CustomText>
            </Flex>
          </Stack>
        </Stack>
        <Stack
          position="absolute"
          left="5%"
          top="80%"
          w="255px"
          h="90px"
          className="hover_item"
          opacity={0}
          transition={`all ${ANIMATION_TIME}ms ease-in-out`}
          bg={COLOR.white}
          overflow="hidden"
          justifyContent="center"
          borderRadius="20px"
        >
          <ButtonGroup
            display="flex"
            flexDirection="row"
            transform="translateX(40px)"
            transition={`all ${ANIMATION_TIME}ms ease-in-out`}
            className="button_group"
            w="100%"
            alignItems="center"
            justifyContent="center"
            spacing={0}
            gap="10px"
          >
            <>
              {' '}
              <ButtonBuy onClick={onOpen}>Sale now</ButtonBuy>
              <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent bg={COLOR.white} borderRadius="20px" boxShadow={`0px 4px 20px 0px ${COLOR.boxShadow}`}>
                  <ModalHeader color={COLOR.black}>Choose Marketplace</ModalHeader>
                  <ModalCloseButton color={COLOR.black} />
                  <ModalBody>
                    <Flex p="20px 10px" justifyContent="center" gap="40px">
                      <Stack
                        justifyContent="center"
                        alignItems="center"
                        gap="30px"
                        cursor="pointer"
                        transition="all 235ms ease-in-out"
                        _hover={{
                          transform: ' translate3d(0, 2px, 0)',
                        }}
                      >
                        <img style={{ width: '50px' }} src={images.openSeaIcon} />
                        <Text fontFamily="Anek Bangla" fontWeight={500} fontSize="20px" color={COLOR.black}>
                          OpenSea
                        </Text>
                      </Stack>
                      <Stack
                        justifyContent="center"
                        alignItems="center"
                        gap="30px"
                        transition="all 235ms ease-in-out"
                        cursor="pointer"
                        _hover={{
                          transform: ' translate3d(0, 2px, 0)',
                        }}
                      >
                        <img style={{ width: '50px' }} src={images.dotIcon} />
                        <Text fontFamily="Anek Bangla" fontWeight={500} fontSize="20px" color={COLOR.black}>
                          More
                        </Text>
                      </Stack>
                    </Flex>
                  </ModalBody>
                </ModalContent>
              </Modal>
            </>
            <Link to={`${APP_ROUTES.marketplaceDetail}/${product.id}`}>
              <ButtonDetail>Details</ButtonDetail>
            </Link>
          </ButtonGroup>
        </Stack>
      </Stack>
    </Card>
  )
}

export default ProfileCard
