/* eslint-disable consistent-return */
import API_ROUTES from '../../constant/API_ROUTES'

import axiosClient from '../../interceptor'
import QueryString from 'qs'

const subscribe = async (body) => {
  const res = await axiosClient.post(API_ROUTES.user.subscribes, body)
  return res.data || undefined
}
const ConnectTwitter = async (body) => {
  const res = await axiosClient.post(`${API_ROUTES.twitter.connect}?${QueryString.stringify(body)}`)
  return res.data || undefined
}

const AuthTwitter = async (body) => {
  const res = await axiosClient.post(`${API_ROUTES.twitter.authConnect}?${QueryString.stringify(body)}`)
  return res.data || undefined
}
const GetMe = async (token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  }
  const res = await axiosClient.get(`${API_ROUTES.user.me}?`, { headers })
  return res.data || undefined
}
const logoutTwitter = async () => {
  const res = await axiosClient.post(API_ROUTES.twitter.logout)
  return res.data
}
const checkAirdrop = async (body, token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  }
  const res = await axiosClient.get(`${API_ROUTES.user.airdrop}?${QueryString.stringify(body)}`, { headers })
  return res.data || undefined
}
const listAirdrop = async (offset?: number, limit?: number) => {
  const list = await axiosClient.get(API_ROUTES.user.listAirdrop, {
    params: {
      pageSize: offset || 0,
      pageIndex: limit || 0,
    },
  })
  return list.data || undefined
}
const listLeaderBoard = async (limit?: number, offset?: number, type?: string) => {
  const list = await axiosClient.get(API_ROUTES.leaderBoard.board, {
    params: {
      pageIndex: limit || 0,
      pageSize: offset || 0,
      type: type,
    },
  })
  return list.data || undefined
}
const checkClaimAirdrop = async (body) => {
  const res = await axiosClient.get(`${API_ROUTES.twitter.airdrop}?${QueryString.stringify(body)}`)
  return res.data || undefined
}
const getInfoTwitter = async () => {
  const res = await axiosClient.get(API_ROUTES.twitter.info)
  return res.data || undefined
}
export {
  subscribe,
  ConnectTwitter,
  AuthTwitter,
  GetMe,
  logoutTwitter,
  checkAirdrop,
  getInfoTwitter,
  listAirdrop,
  checkClaimAirdrop,
  listLeaderBoard,
}
