const Arrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
      <path
        d="M22.1667 12.3649C22.1673 12.5392 22.1288 12.7114 22.0541 12.8689C21.9794 13.0263 21.8704 13.1651 21.735 13.2749L14.735 18.9099C14.5263 19.0815 14.2644 19.1753 13.9942 19.1753C13.724 19.1753 13.4621 19.0815 13.2534 18.9099L6.25337 13.0766C6.01512 12.8785 5.86529 12.594 5.83685 12.2855C5.8084 11.977 5.90368 11.6698 6.1017 11.4316C6.29973 11.1933 6.5843 11.0435 6.89279 11.015C7.20129 10.9866 7.50845 11.0819 7.7467 11.2799L14 16.4949L20.2534 11.4549C20.4246 11.3122 20.6332 11.2216 20.8543 11.1937C21.0754 11.1659 21.2999 11.202 21.5012 11.2977C21.7025 11.3934 21.8721 11.5448 21.9901 11.7339C22.108 11.9231 22.1693 12.142 22.1667 12.3649Z"
        fill="white"
      />
    </svg>
  )
}

export default Arrow
