import React, { useState } from 'react'
import { Flex, FlexProps, Image, ImageProps, Skeleton } from '@chakra-ui/react'

type CustomImageProps = {
  containerProps?: FlexProps
} & ImageProps

const CustomImage = ({ containerProps, ...props }: CustomImageProps) => {
  const [isLoading, setIsLoading] = useState(true)

  const handleLoad = () => {
    setIsLoading(false)
  }

  return (
    <Flex w="100%" h="100%" {...containerProps}>
      <Skeleton isLoaded={!isLoading} startColor="gray.400" endColor="gray.300" w="100%" h="100%">
        <Image onLoad={handleLoad} {...props} />
      </Skeleton>
    </Flex>
  )
}

export default CustomImage
