import { Stack } from '@chakra-ui/react'
import 'aos/dist/aos.css'
import COLOR from 'constant/COLOR'
import useResponsive from 'hooks/useResponsive'
import React, { useEffect } from 'react'

import useAuth from 'hooks/useAuth'
import { useWallet } from 'hooks/useWallet'
import { GetMe } from 'service/UserService'
import { useNetwork } from 'wagmi'
import { useWeb3React } from '../../../packages/wagmi/src/useWeb3React'
import Section1 from './Section1'
import Section2 from './Section2'
import SocialTweet from './SocialTweet'
import Task from './Task'
import Whitelist from './Whitelist'

const Airdrop: React.FC<React.PropsWithChildren> = () => {
  const { isMobile, isMobileOrTablet, isTabletOrLaptop, isBigScreen } = useResponsive()
  const { connetSocial } = useAuth()
  const { account } = useWeb3React()
  const { onPresentConnectModal } = useWallet()
  const { chain } = useNetwork()

  const checkOpenGame = async () => {
    const token = localStorage.getItem('TokenGame')

    if (!account) {
      onPresentConnectModal()
      return
    }
    try {
      const data = await GetMe(token)
    } catch (error) {
      connetSocial()
    }
  }
  useEffect(() => {
    if (chain?.id && account) checkOpenGame()
  }, [account, chain])
  return (
    <Stack bg={COLOR.bgAirdrop}>
      <Task />
      <Stack
        mt={isMobileOrTablet ? '400px' : isTabletOrLaptop ? '-100px' : isBigScreen ? '-200px' : '-350px'}
        mb="100px"
        justifyContent="center"
        alignItems="center"
      >
        <Section1 />
        <SocialTweet />
        <Whitelist />
        <Section2 />
      </Stack>
    </Stack>
  )
}

export default Airdrop
