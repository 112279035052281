/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Button, Flex, Stack, Text } from '@chakra-ui/react'
import 'aos/dist/aos.css'
import { DefaultBox } from 'components/Data/BoxDefault'
import boxAbi from 'config/abi/box.json'
import mintBoxAbi from 'config/abi/mintBox.json'
import contracts from 'config/constants/contracts'
import COLOR from 'constant/COLOR'
import images from 'constant/PICTURE/images'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useResponsive from 'hooks/useResponsive'
import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { getContract } from 'utils/contractHelpers'
import { useSigner } from 'wagmi'
import { ChainId } from '../../../packages/swap-sdk/src/constants'

const Wrapper = styled.div`
  width: 100%;
  font-style: normal;
  overflow: hidden;
  background: ${COLOR.bg};
  background-image: url(${images.bgHome});
  background-position: center;
  background-repeat: repeat;
  background-size: contain;
`
const Container = styled.div`
  padding: 100px;

  @media (max-width: 575px) {
    padding: 90px 5px;
  }
`
const mover = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
`

const AnimatedImage = styled.img`
  cursor: pointer;
  // transition: transform 0.5s;

  &:hover {
    animation: ${mover} 1s infinite alternate;
    transform: translateY(-20px);
  }
`
const GlowingStack = styled(Stack)`
  border: 5px solid ${(props) => props.theme.colors.bronze};
  border-radius: 20px;
  box-shadow: 0px 0px 10px 0px ${COLOR.shadowHero};
  padding: 30px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    border-color: ${COLOR.bttGetBox};
    box-shadow: 0px 0px 20px 0px ${COLOR.bttGetBox};
  }
`

const UserBox: React.FC<React.PropsWithChildren> = () => {
  const { account, chainId } = useActiveWeb3React()

  const CHAIN_ID = chainId === undefined ? ChainId.BSC_TESTNET : chainId
  const { data: signer } = useSigner()
  const [box, setBox] = useState(DefaultBox)
  const { isMobile, isMobileOrTablet, isTabletOrLaptop, isBigScreen } = useResponsive()
  const [selectedItem, setSelectedItem] = useState(null)
  const [boxBought, setBoxBought] = useState([])
  const totalBoxes = 1000
  const [boxRemain, setBoxRemain] = useState([])

  const getBox = getContract({
    address: contracts.box[CHAIN_ID],
    abi: boxAbi,
    chainId: CHAIN_ID,
    signer,
  })
  const mintBox = getContract({
    address: contracts.mintBox[CHAIN_ID],
    abi: mintBoxAbi,
    chainId: CHAIN_ID,
    signer,
  })
  const getRemain = async () => {
    const arr = [1, 2, 3]
    const data = arr.map(async (i) => {
      const remain = await mintBox.remain(i)
      const totalBox = await getBox.balanceOf(account, i)

      return { remain, totalBox }
    })
    const DataBox = await Promise.all(data)
    setBoxRemain(DataBox)
  }

  const getUserBox = async () => {
    const arr = [1, 2, 3]
    const data = arr.map(async (i) => {
      const totalBox = await getBox.balanceOf(account, i)
      return { totalBox }
    })
    const newData = await Promise.all(data)
    setBoxBought(newData)
  }
  // console.log(boxBought)

  const handleCardClick = (item, index) => {
    item.id = index
    setSelectedItem(item)
  }
  useEffect(() => {
    getUserBox()

    getRemain()
  }, [account])

  return (
    <>
      <Wrapper>
        <Container>
          <Text color={COLOR.white} fontSize="38px" fontWeight={400} fontFamily="Russo One" textAlign="center">
            MY BOXES
          </Text>
          <Flex flexWrap="wrap" justifyContent="center" gap="50px" mt="10px">
            {box.map((item, index) => (
              <GlowingStack
                key={index}
                bg={COLOR.bgButton}
                border={`5px solid ${item.border}`}
                borderRadius="20px"
                boxShadow={`0px 0px 10px 0px ${COLOR.shadowHero}`}
                p="30px"
                justifyContent="center"
                alignItems="center"
                gap="20px"
              >
                <AnimatedImage onClick={() => handleCardClick(item, index)} src={item.img} alt="" />
                <Flex justifyContent="space-between" w="full">
                  <Text
                    color={COLOR.white}
                    fontFamily="Anek Bangla"
                    fontSize="20px"
                    fontWeight={400}
                    textAlign="center"
                  >
                    Owned
                  </Text>
                  <Text
                    textTransform="uppercase"
                    color={COLOR.white}
                    fontFamily="Anek Bangla"
                    fontSize="22px"
                    fontWeight={600}
                    textAlign="center"
                  >
                    {boxBought.length > 0 ? Number(boxBought[index].totalBox.toString()) : 0}
                  </Text>
                </Flex>
                <Button
                  p="0 50px"
                  w="full"
                  h="60px"
                  borderRadius="20px"
                  border={`1px solid ${COLOR.bttGetBox}`}
                  bg={COLOR.bgButton}
                  color={COLOR.bttGetBox}
                  filter="grayscale(100%)"
                  _hover={{ bg: '#778899' }}
                  cursor="not-allowed"
                >
                  <Text color={COLOR.bttGetBox} fontFamily="Anek Bangla" fontSize="18px" fontWeight={600}>
                    Open Box
                  </Text>
                </Button>
              </GlowingStack>
            ))}
          </Flex>

          <Stack justifyContent="center" alignItems="center" w="full">
            {selectedItem && (
              <>
                <Text
                  color={COLOR.white}
                  fontSize="38px"
                  fontWeight={400}
                  fontFamily="Russo One"
                  textAlign="center"
                  mt="20px"
                >
                  DETAIL BOXES
                </Text>
                <Stack
                  bg={COLOR.bgButton}
                  border={`5px solid ${selectedItem.border}`}
                  borderRadius="20px"
                  boxShadow={`0px 0px 10px 0px ${selectedItem.shadow}`}
                  padding="30px"
                  justifyContent="space-between"
                  gap="20px"
                  w={isTabletOrLaptop ? '100%' : '40%'}
                  mt="10px"
                >
                  <Text
                    textTransform="uppercase"
                    color={COLOR.white}
                    fontSize="38px"
                    fontWeight={600}
                    fontFamily="Anek Bangla"
                  >
                    {selectedItem.name}
                  </Text>
                  <Flex justifyContent="space-between" alignItems="center" gap="50px" alignSelf="stretch">
                    <Stack>
                      <Text fontSize="20px" fontWeight={400} fontFamily="Anek Bangla">
                        Max Supply
                      </Text>
                      <Text color={COLOR.white} fontSize="22px" fontWeight={600} fontFamily="Anek Bangla">
                        {totalBoxes}
                      </Text>
                    </Stack>
                    <Stack>
                      <Text fontSize="20px" fontWeight={400} fontFamily="Anek Bangla">
                        Total Sold
                      </Text>
                      <Text fontSize="22px" fontWeight={600} fontFamily="Anek Bangla">
                        {boxRemain.length > 0 && totalBoxes - Number(boxRemain[selectedItem.id]?.remain.toString())}
                      </Text>
                    </Stack>
                    <Stack>
                      <Text fontSize="20px" fontWeight={400} fontFamily="Anek Bangla">
                        Type
                      </Text>
                      <Text fontSize="22px" fontWeight={600} fontFamily="Anek Bangla">
                        {selectedItem.type}
                      </Text>
                    </Stack>
                  </Flex>
                  <Stack>
                    <Text fontSize="20px" fontWeight={400} fontFamily="Anek Bangla">
                      Describe
                    </Text>
                    <Text fontSize="22px" fontWeight={600} fontFamily="Anek Bangla">
                      {selectedItem.desc}
                    </Text>
                  </Stack>
                </Stack>
              </>
            )}
          </Stack>
        </Container>
      </Wrapper>
    </>
  )
}

export default UserBox
