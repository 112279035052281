/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-return-assign */
import 'antd-message-react/dist/index.css'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import {
  Button,
  Divider,
  Flex,
  Input,
  Menu,
  MenuButton,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@pancakeswap/uikit/src/components/Svg'
import { DefaultProduct } from 'components/Data/ProductDefault'
import { AllType, ProductType } from 'components/Data/models/ProductType'
import ProductCard from 'components/ProductCard'
import COLOR from 'constant/COLOR'
import useResponsive from 'hooks/useResponsive'
import images from 'constant/PICTURE/images'
import useCards from 'hooks/useCards'
const Container = styled.div`
  display: flex;
  padding: 100px 0px;
  flex-direction: column;
  align-items: center;
  gap: 45px;
  overflow: hidden;
  @media (max-width: 575px) {
    padding: 0;
    gap: 20px;
  }
`
const ButtonLoad = styled.button`
  display: flex;
  width: 223px;
  padding: 15px;
  justify-content: center;
  align-items: center;
  color: ${COLOR.white};
  font-family: Anek Bangla;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: none;
  text-transform: uppercase;
  cursor: pointer;
  background-image: url(${images.load});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  touch-action: manipulation;
  transition: all 235ms ease-in-out;
  &:hover {
    text-decoration: none;
    transform: translate3d(0, 2px, 0);
  }
`
const ButtonFi = styled.button`
  background: none;
  border: none;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-image: url(${images.cardButton});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  color: ${COLOR.button};
  font-family: Anek Bangla;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
  width: fit-content;
  white-space: nowrap;
  touch-action: manipulation;
  transition: all 235ms ease-in-out;
  &:hover {
    text-decoration: none;
    color: ${COLOR.hoverBtt};
    transform: translate3d(0, 2px, 0);
    text-shadow: 0px 4px 4px ${COLOR.hoverYellow};
  }
  @media (max-width: 575px) {
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    font-size: 14px;
  }
`

const ButtonBuy = styled.button`
  display: flex;
  width: 100px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  color: ${COLOR.white};
  font-family: Anek Bangla;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: none;
  text-transform: uppercase;
  cursor: pointer;
  background-image: url(${images.bttBuy});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  touch-action: manipulation;
  transition: all 235ms ease-in-out;
  &:hover {
    text-decoration: none;
    transform: translate3d(0, 2px, 0);
  }
`
const ButtonOffer = styled.button`
  display: flex;
  width: 100px;
  padding: 1px;
  justify-content: center;
  align-items: center;
  color: ${COLOR.white};
  font-family: Anek Bangla;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: none;
  text-transform: uppercase;
  cursor: pointer;
  background-image: url(${images.bttOffer});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  touch-action: manipulation;
  transition: all 235ms ease-in-out;
  &:hover {
    text-decoration: none;
    transform: translate3d(0, 2px, 0);
  }
`

const Title = styled.text`
  color: ${COLOR.white};
  font-family: Anek Bangla;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px; /* 138.889% */
  padding: 6px 10px;
  @media (max-width: 575px) {
    font-size: 25px;
  }
`
const Header = styled.text`
  border-bottom: 1px solid ${COLOR.white};
  font-family: Russo One;
  font-size: 65px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 50px;
  color: ${COLOR.white};
  @media (max-width: 900px) {
    font-size: 30px;
    line-height: normal;
  }
  @media (max-width: 575px) {
    font-size: 25px;
  }
`

export const NFTCard = () => {
  const items = [
    {
      key: 'price',
      order: 1,
      id: 1,
      tab: 'Price low to high',
    },
    {
      key: 'price',
      order: -1,
      id: 2,
      tab: 'Price high to low',
    },
    { key: '', order: 1, id: 3, tab: 'Recently listed' },
    { key: '', order: 1, id: 4, tab: 'Highest last sale' },
    { key: '', order: 1, id: 5, tab: 'Recent sold' },
    { key: '', order: 1, id: 6, tab: 'Recently created' },
  ]
  const [filter, setFilter] = useState(1)
  const [title, setTitle] = useState('Filter')
  const { isTabletOrLaptop, isMobileOrTablet } = useResponsive()
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure()
  const [openId, setOpenId] = useState(false)
  const [openClassify, setOpenClassify] = useState(false)
  const [products, setProduct] = useState(DefaultProduct)
  // const { isLoading, cards } = useCards()
  // const [currentCard, setCurrentCard] = useState(cards)

  // const filterAllCards = (allCard: AllType) => {
  //   const filterCards = cards?.filter((item, index) => {
  //     return item.all === allCard
  //   })
  //   setCurrentCard(filterCards)
  // }
  const filterAll = (all: AllType) => {
    const filterProducts = DefaultProduct.filter((item) => {
      return item.all === all
    })
    setTypeFilter(all)
    setProduct(filterProducts)
  }
  const sortProductsByPrice = (products: ProductType[], index) => {
    const sortPrice = [...products].sort((a, b) => (a[items[index].key] - b[items[index].key]) * items[index].order)
    setProduct(sortPrice)
  }
  const filterId = (name: string) => {
    if (!!name.trim()) {
      const filterProducts = DefaultProduct?.filter((item) => {
        return item.id.toUpperCase().indexOf(name.toUpperCase()) > -1
      })

      setProduct(filterProducts)
    } else {
      setProduct(DefaultProduct)
    }
  }
  const [typeFilter, setTypeFilter] = useState(null)

  const [visible, setVisible] = useState(5)

  const loadMore = () => {
    setVisible((prevVisible) => prevVisible + 5)
    setHasClickedLoadMore(true)
  }
  const showLess = () => {
    setVisible((prevVisible) => Math.max(prevVisible - 5, 0))
  }
  const [hasClickedLoadMore, setHasClickedLoadMore] = useState(false)
  const itemRefs = useRef([])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [hasClickedLoadMore])
  useEffect(() => {
    if (hasClickedLoadMore && itemRefs.current[visible - 5]) {
      itemRefs.current[visible - 5].scrollIntoView({ behavior: 'smooth' })
    }
  }, [visible, hasClickedLoadMore])
  return (
    <>
      <Container>
        <Header>THE NFTs CARD</Header>
        {isTabletOrLaptop ? (
          <>
            <Stack
              direction="row"
              background={COLOR.bgMarket}
              boxShadow={`0px 4px 20px 0px ${COLOR.boxShadow}`}
              alignItems="center"
              p={isMobileOrTablet ? '20px 5px' : '20px 40px'}
              borderRadius="12px"
              justifyContent="space-between"
              w="100%"
            >
              <>
                <Button onClick={onOpen} bg="none" colorScheme="none" _hover={{ background: 'none' }}>
                  <img src={images.moreIcon} alt="" />
                </Button>

                <Modal isOpen={isOpen} onClose={onClose} isCentered>
                  <ModalOverlay />
                  <ModalContent
                    bg={COLOR.bgMarket}
                    borderRadius="20px"
                    boxShadow={`0px 4px 20px 0px ${COLOR.boxShadow}`}
                  >
                    <ModalHeader color={COLOR.white}>Filter</ModalHeader>
                    <ModalCloseButton />
                    <Divider color={COLOR.divider} />
                    <ModalBody>
                      <Stack p="20px 10px">
                        <Flex justifyContent="space-between">
                          <Text fontFamily="Anek Bangla" fontWeight={500} fontSize="18px" color={COLOR.white}>
                            ID
                          </Text>
                          <Stack
                            onClick={() => setOpenId(!openId)}
                            w="25px"
                            h="25px"
                            borderRadius="50%"
                            border={`1px solid ${COLOR.white}`}
                            justifyContent="center"
                            cursor="pointer"
                          >
                            {openId ? <ChevronUpIcon /> : <ChevronDownIcon />}
                          </Stack>
                        </Flex>
                        {openId && (
                          <Input
                            _focusVisible={{ boxShadow: COLOR.white, border: `1px solid ${COLOR.white}` }}
                            placeholder="Search..."
                            onChange={(event) => filterId(event.target.value)}
                          />
                        )}
                      </Stack>
                    </ModalBody>
                    <Divider color={COLOR.divider} />

                    <ModalBody p="20px 33px">
                      <Flex justifyContent="space-between">
                        <Text fontFamily="Anek Bangla" fontWeight={500} fontSize="18px" color={COLOR.white}>
                          Classify
                        </Text>
                        <Stack
                          onClick={() => setOpenClassify(!openClassify)}
                          w="25px"
                          h="25px"
                          borderRadius="50%"
                          border={`1px solid ${COLOR.white}`}
                          justifyContent="center"
                          cursor="pointer"
                        >
                          {openClassify ? <ChevronUpIcon /> : <ChevronDownIcon />}
                        </Stack>
                      </Flex>

                      {openClassify && (
                        <Flex gap="10px" mt="20px">
                          <Stack h="35px" direction="row" cursor="pointer">
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{
                                textTransform: 'capitalize',
                                flexWrap: 'wrap',
                              }}
                            >
                              <Title
                                onClick={() => {
                                  setProduct(DefaultProduct)
                                  setTypeFilter(null)
                                }}
                                style={{
                                  height: '35px',
                                  alignItems: 'center',
                                  display: 'flex',
                                  color: !typeFilter ? COLOR.white : COLOR.titleMarket,
                                  background: !typeFilter ? COLOR.bgTitleMarket : COLOR.white,
                                  boxShadow: !typeFilter ? `0px 4px 11px 0px ${COLOR.shadowTitleMarket} inset` : 'none',
                                  borderRadius: '47px',
                                  padding: '0 10px',
                                  fontSize: '13px',
                                }}
                              >
                                All
                              </Title>
                              <Title
                                onClick={() => filterAll(AllType.normal)}
                                style={{
                                  height: '35px',
                                  alignItems: 'center',
                                  display: 'flex',
                                  borderRadius: '47px',
                                  padding: '0 10px',
                                  color: typeFilter === AllType.normal ? COLOR.white : COLOR.titleMarket,
                                  background: typeFilter === AllType.normal ? COLOR.bgTitleMarket : COLOR.white,
                                  boxShadow:
                                    typeFilter === AllType.normal
                                      ? `0px 4px 11px 0px ${COLOR.shadowTitleMarket} inset`
                                      : 'none',
                                  fontSize: '13px',
                                }}
                              >
                                Normal
                              </Title>
                              <Title
                                onClick={() => filterAll(AllType.rare)}
                                style={{
                                  height: '35px',
                                  alignItems: 'center',
                                  display: 'flex',
                                  borderRadius: '47px',
                                  color: typeFilter === AllType.rare ? COLOR.white : COLOR.titleMarket,
                                  background: typeFilter === AllType.rare ? COLOR.bgTitleMarket : COLOR.white,
                                  boxShadow:
                                    typeFilter === AllType.rare
                                      ? `0px 4px 11px 0px ${COLOR.shadowTitleMarket} inset`
                                      : 'none',
                                  fontSize: '13px',
                                  padding: '0 10px',
                                }}
                              >
                                Rare
                              </Title>{' '}
                              <Title
                                onClick={() => filterAll(AllType.epic)}
                                style={{
                                  height: '35px',
                                  alignItems: 'center',
                                  display: 'flex',
                                  borderRadius: '47px',
                                  color: typeFilter === AllType.epic ? COLOR.white : COLOR.titleMarket,
                                  background: typeFilter === AllType.epic ? COLOR.bgTitleMarket : COLOR.white,
                                  boxShadow:
                                    typeFilter === AllType.epic
                                      ? `0px 4px 11px 0px ${COLOR.shadowTitleMarket} inset`
                                      : 'none',
                                  fontSize: '13px',
                                  padding: '0 10px',
                                }}
                              >
                                Epic
                              </Title>{' '}
                              <Title
                                onClick={() => filterAll(AllType.legendary)}
                                style={{
                                  color: typeFilter === AllType.legendary ? COLOR.white : COLOR.titleMarket,
                                  height: '35px',
                                  alignItems: 'center',
                                  display: 'flex',
                                  background: typeFilter === AllType.legendary ? COLOR.bgTitleMarket : COLOR.white,
                                  borderRadius: '47px',
                                  boxShadow:
                                    typeFilter === AllType.legendary
                                      ? `0px 4px 11px 0px ${COLOR.shadowTitleMarket} inset`
                                      : 'none',
                                  fontSize: '13px',
                                  padding: '0 10px',
                                }}
                              >
                                Legendary
                              </Title>{' '}
                              <Title
                                onClick={() => filterAll(AllType.mythical)}
                                style={{
                                  height: '35px',
                                  alignItems: 'center',
                                  display: 'flex',
                                  borderRadius: '47px',
                                  color: typeFilter === AllType.mythical ? COLOR.white : COLOR.titleMarket,
                                  background: typeFilter === AllType.mythical ? COLOR.bgTitleMarket : COLOR.white,
                                  boxShadow:
                                    typeFilter === AllType.mythical
                                      ? `0px 4px 11px 0px ${COLOR.shadowTitleMarket} inset`
                                      : 'none',
                                  fontSize: '13px',
                                  padding: '0 10px',
                                }}
                              >
                                Mythical
                              </Title>{' '}
                            </Stack>
                          </Stack>
                        </Flex>
                      )}
                    </ModalBody>
                    <Divider mt="30px" color={COLOR.divider} />

                    <ModalFooter justifyContent="center" gap="10px">
                      <ButtonOffer style={{ padding: '5px' }} onClick={() => setProduct(DefaultProduct)}>
                        Clear All
                      </ButtonOffer>
                      <ButtonBuy style={{ padding: '5px' }} onClick={onClose}>
                        Done
                      </ButtonBuy>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
              </>
              <Menu>
                <ButtonFi>
                  <MenuButton
                    _hover={{ bg: 'none' }}
                    as={Button}
                    bg="none"
                    border="none"
                    borderRadius="12px"
                    p="22px 10px"
                    rightIcon={<ChevronDownIcon />}
                    h="4px"
                    w="250px"
                    cursor="pointer"
                    colorScheme="none"
                  >
                    <Title>{title}</Title>
                  </MenuButton>
                </ButtonFi>
                <MenuList bg={COLOR.white} borderRadius="12px" p="30px" w="320px" zIndex={1}>
                  <Stack>
                    {items.map((item, index) => (
                      <Stack key={index}>
                        <Stack
                          onClick={() => {
                            sortProductsByPrice(DefaultProduct, index)
                            setFilter(item.id)
                            setTitle(item.tab)
                          }}
                          cursor="pointer"
                        >
                          <p
                            style={{
                              background: filter === item.id ? COLOR.id : 'none',
                              padding: '0 20px',
                              borderRadius: '12px',
                            }}
                          >
                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                              <Title style={{ color: COLOR.black }}>{item.tab} </Title>
                              {filter === item.id && <img style={{ width: 20 }} src={images.checkIcon} alt="" />}
                            </Stack>
                          </p>
                        </Stack>
                      </Stack>
                    ))}
                  </Stack>
                </MenuList>
              </Menu>
            </Stack>
          </>
        ) : (
          <>
            <Stack
              direction="row"
              background={COLOR.bgMarket}
              boxShadow={`0px 4px 20px 0px ${COLOR.boxShadow}`}
              alignItems="center"
              p="20px 40px"
              borderRadius="12px"
              justifyContent="space-between"
              w="100%"
              gap="20px"
            >
              <Menu>
                <Input
                  w="150px"
                  _focusVisible={{ boxShadow: COLOR.white, border: `1px solid ${COLOR.white}` }}
                  placeholder="Search ID..."
                  onChange={(event) => filterId(event.target.value)}
                />
              </Menu>
              <Stack
                justifyContent="space-between"
                direction="row"
                gap="30px"
                alignItems="center"
                bg={COLOR.menu}
                p="6px 20px"
                borderRadius="47px"
                w="100%"
                h="60px"
                cursor="pointer"
              >
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                  sx={{
                    textTransform: 'capitalize',
                  }}
                >
                  <Title
                    onClick={() => {
                      setProduct(DefaultProduct)
                      setTypeFilter(null)
                    }}
                    style={{
                      padding: '0 20px',
                      borderRadius: '47px',
                      color: !typeFilter ? COLOR.titleMarket : COLOR.white,
                      background: !typeFilter ? COLOR.white : 'none',
                      boxShadow: !typeFilter ? `0px 4px 11px 0px ${COLOR.shadowTitleMarket} inset` : 'none',
                    }}
                  >
                    All
                  </Title>
                  <Title
                    onClick={() => filterAll(AllType.normal)}
                    style={{
                      padding: '0 20px',
                      borderRadius: '47px',
                      color: typeFilter === AllType.normal ? COLOR.titleMarket : COLOR.white,
                      background: typeFilter === AllType.normal ? COLOR.white : 'none',
                      boxShadow:
                        typeFilter === AllType.normal ? `0px 4px 11px 0px ${COLOR.shadowTitleMarket} inset` : 'none',
                    }}
                  >
                    Normal
                  </Title>
                  <Title
                    onClick={() => filterAll(AllType.rare)}
                    style={{
                      padding: '0 20px',
                      borderRadius: '47px',
                      color: typeFilter === AllType.rare ? COLOR.titleMarket : COLOR.white,
                      boxShadow:
                        typeFilter === AllType.rare ? `0px 4px 11px 0px ${COLOR.shadowTitleMarket} inset` : 'none',
                      background: typeFilter === AllType.rare ? COLOR.white : 'none',
                    }}
                  >
                    Rare
                  </Title>{' '}
                  <Title
                    onClick={() => filterAll(AllType.epic)}
                    style={{
                      padding: '0 20px',
                      borderRadius: '47px',
                      color: typeFilter === AllType.epic ? COLOR.titleMarket : COLOR.white,
                      boxShadow:
                        typeFilter === AllType.epic ? `0px 4px 11px 0px ${COLOR.shadowTitleMarket} inset` : 'none',
                      background: typeFilter === AllType.epic ? COLOR.white : 'none',
                    }}
                  >
                    Epic
                  </Title>{' '}
                  <Title
                    onClick={() => filterAll(AllType.legendary)}
                    style={{
                      padding: '0 20px',
                      borderRadius: '47px',
                      color: typeFilter === AllType.legendary ? COLOR.titleMarket : COLOR.white,
                      boxShadow:
                        typeFilter === AllType.legendary ? `0px 4px 11px 0px ${COLOR.shadowTitleMarket} inset` : 'none',
                      background: typeFilter === AllType.legendary ? COLOR.white : 'none',
                    }}
                  >
                    Legendary
                  </Title>{' '}
                  <Title
                    onClick={() => filterAll(AllType.mythical)}
                    style={{
                      padding: '0 20px',
                      borderRadius: '47px',
                      color: typeFilter === AllType.mythical ? COLOR.titleMarket : COLOR.white,
                      boxShadow:
                        typeFilter === AllType.mythical ? `0px 4px 11px 0px ${COLOR.shadowTitleMarket} inset` : 'none',
                      background: typeFilter === AllType.mythical ? COLOR.white : 'none',
                    }}
                  >
                    Mythical
                  </Title>{' '}
                </Flex>
              </Stack>

              <Menu>
                <ButtonFi>
                  <MenuButton
                    _hover={{ bg: 'none' }}
                    as={Button}
                    bg="none"
                    border="none"
                    borderRadius="12px"
                    p="22px 10px"
                    rightIcon={<ChevronDownIcon />}
                    h="4px"
                    w="250px"
                    cursor="pointer"
                    colorScheme="none"
                  >
                    <Title>{title}</Title>
                  </MenuButton>
                </ButtonFi>
                <MenuList bg={COLOR.white} borderRadius="12px" p="30px" w="320px" zIndex={1}>
                  <Stack>
                    {items.map((item, index) => (
                      <Stack key={index}>
                        <Stack
                          onClick={() => {
                            sortProductsByPrice(DefaultProduct, index)
                            setFilter(item.id)
                            setTitle(item.tab)
                          }}
                          cursor="pointer"
                        >
                          <p
                            style={{
                              background: filter === item.id ? COLOR.id : 'none',
                              padding: '0 20px',
                              borderRadius: '12px',
                            }}
                          >
                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                              <Title style={{ color: COLOR.black }}>{item.tab} </Title>
                              {filter === item.id && <img style={{ width: 20 }} src={images.checkIcon} alt="" />}
                            </Stack>
                          </p>
                        </Stack>
                      </Stack>
                    ))}
                  </Stack>
                </MenuList>
              </Menu>
            </Stack>
          </>
        )}

        <Flex
          sx={{
            margin: 'auto',
            flexWrap: 'wrap',
            marginTop: 8,
            gap: isTabletOrLaptop ? '35px' : '50px',
            justifyContent: isMobileOrTablet ? 'center' : 'flex-start',
          }}
        >
          {products.slice(0, visible).map((item, index) => (
            <div ref={(ref) => (itemRefs.current[index] = ref)} key={item.id}>
              <ProductCard product={item} />
            </div>
          ))}
        </Flex>
        <Stack direction={isMobileOrTablet ? 'column' : 'row'} justifyContent="center">
          {visible < products.length && <ButtonLoad onClick={loadMore}>Load More</ButtonLoad>}
          {hasClickedLoadMore && visible > 5 && <ButtonLoad onClick={showLess}>Show Less</ButtonLoad>}
        </Stack>
      </Container>
    </>
  )
}
