import Aos from 'aos'
import 'aos/dist/aos.css'
import COLOR from 'constant/COLOR'
import { useEffect } from 'react'
import styled from 'styled-components'

const Container = styled.div`
   display: flex;
    padding: 50px 0px;
    flex-direction: column;
    align-items: center;
    gap: 45px;
    overflow: hidden;
  }
`

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 45px;
  color: ${COLOR.white};
  text-align: center;

  .heading {
    font-family: Anek Bangla;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
  @media (max-width: 900px) {
    gap: 20px;
    .heading {
      font-size: 25px;
    }
  }
  @media (max-width: 575px) {
    .heading {
      font-size: 20px;
    }
`
const Desc = styled.text`
  font-family: Anek Bangla;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 50px;
  text-align: center;

  @media (max-width: 900px) {
    font-size: 20px;
    line-height: normal;
  }
  @media (max-width: 575px) {
    font-size: 16px;
  }
`
const Title = styled.text`
  // border-bottom: 1px solid ${COLOR.white};
  color: #030205;

  // text-stroke-width: 2;d
  // text-stroke-color: #fff;
  font-family: 'Russo One';
  font-size: 65px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  @media (max-width: 900px) {
    font-size: 30px;
    line-height: normal;
  }
  @media (max-width: 575px) {
    font-size: 25px;
  }
`
const CardList = styled.div`
  display: flex;
  padding: 20px 0px;
  align-items: center;
  gap: 28px;
  @media (max-width: 900px) {
    justify-content: center;
  }
  @media (max-width: 575px) {
    padding: 20px 20px;
    flex-direction: column;
  }
`
const Card = styled.div`
  width: calc((100% - 28px) / 3);
  border: none;
  background-color: transparent;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 900px) {
    width: 100%;
  }
`
const ThumbnailEye = styled.div`
  background-image: url('/images/DarkKnight/EYEDCover.jpg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`
const ThumbnailOak = styled.div`
  background-image: url('/images/DarkKnight/OAKCover.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`
const ThumbnailStone = styled.div`
  background-image: url('/images/DarkKnight/STONECover.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`

export const Preseason = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 })
  }, [])

  return (
    <section className="preseason">
      <Container className="container">
        <TextBox className="textbox">
          <Title
            className="title"
            style={{
              WebkitTextStroke: `3px ${COLOR.white}`,
              WebkitTextStrokeWidth: '2px',
              textShadow: `0px 5px 5px ${COLOR.bgTitleMarket}`,
            }}
          >
            THE BOSS HAVE ARRIVED
          </Title>
          <Desc className="desc" style={{ textAlign: 'center' }}>
            Destroy the monsters, craft and find collectibles, open Mystery Boxes, use new abilities, and much more to
            face powerful bosses!
          </Desc>
        </TextBox>
        <CardList className="cardlist">
          <Card className="card">
            <ThumbnailEye>
              <video width="100%" autoPlay loop muted playsInline>
                <source src="/images/DarkKnight/1.mp4" type="video/mp4"></source>
              </video>
            </ThumbnailEye>
          </Card>
          <Card className="card">
            <ThumbnailStone>
              <video width="100%" autoPlay loop muted playsInline>
                <source src="/images/DarkKnight/3.mp4" type="video/mp4"></source>
              </video>
            </ThumbnailStone>
          </Card>
          <Card className="card">
            <ThumbnailOak>
              <video width="100%" autoPlay loop muted playsInline>
                <source src="/images/DarkKnight/2.mp4" type="video/mp4"></source>
              </video>
            </ThumbnailOak>
          </Card>
        </CardList>
      </Container>
    </section>
  )
}
