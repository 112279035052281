import styled from 'styled-components'
import { subscribe } from '../../service/UserService'
import { Col } from 'antd'
import * as Yup from 'yup'
import { Form, Formik, Field } from 'formik'
import 'antd-message-react/dist/index.css'
import { Socials } from './Socials'
import COLOR from 'constant/COLOR'
import images from 'constant/PICTURE/images'
import { useToast } from '@pancakeswap/uikit'
const Button = styled.button`
  background: none;
  border: none;
  display: flex;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  background-image: url(${images.bgButton});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  min-width: 250px;
  color: ${COLOR.button};
  text-shadow: 0px 2px 0px ${COLOR.bttTextShadow};
  font-family: Anek Bangla;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
  width: fit-content;
  white-space: nowrap;
  touch-action: manipulation;
  transition: all 235ms ease-in-out;
  &:hover {
    text-decoration: none;
    color: ${COLOR.hoverBtt};
    transform: translate3d(0, 2px, 0);
    text-shadow: 0px 4px 4px ${COLOR.hoverYellow};
  }
  @media (max-width: 575px) {
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    font-size: 14px;
  }
`
const Wrapper = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  padding: 50px;
  max-height: 400px;
`
const Container = styled.div`
  display: flex;
  padding: 50px 0px;
  flex-direction: column;
  align-items: center;
  gap: 45px;
  overflow: hidden;
  @media (max-width: 900px) {
    gap: 20px;
  }
  @media (max-width: 575px) {
    padding: 0;
    gap: 20px;
  }
`
const Title = styled.text`
  color: ${COLOR.white};
  font-family: Russo One;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px; /* 138.889% */
  @media (max-width: 575px) {
    font-size: 25px;
  }
`
const FormInput = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  @media (max-width: 900px) {
    gap: 10px;
    flex-direction: column;
  }
  @media (max-width: 575px) {
    align-items: flex-start;
    gap: 20px;

    flex-direction: column;
  }
`
const Input = styled.input`
  display: flex;
  padding: 10px 48px;
  max-width: 383px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  outline: none;
  color: ${COLOR.white};
  font-family: Anek Bangla;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px; /* 208.333% */
  height: 100%;

  background-image: url(${images.bgInput});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;

  @media (max-width: 575px) {
    font-size: 15px;
  }
`
export const JoinCommunity = () => {
  const { toastSuccess, toastError } = useToast()

  const RegisterSchema = Yup.object().shape({
    email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
  })
  const handleSubscribe = async (values, { resetForm }) => {
    try {
      const { email } = values
      const data = await subscribe({ email })
      if (data.status === true) {
        toastSuccess('Success', ' Please check your email')
      }
      resetForm()
    } catch (err) {
      toastError('Error', 'Email is required')
    }
  }

  return (
    <Wrapper className="newsletter-wrapper">
      <Container className="container">
        <Title
          className="title"
          style={{
            WebkitTextStroke: `1px ${COLOR.bgTitleMarket}`,
            WebkitTextStrokeWidth: '1px',
            color: COLOR.white,
          }}
        >
          JOIN THE COMMUNITY
        </Title>
        <Formik
          onSubmit={handleSubscribe}
          initialValues={{
            email: '',
          }}
          validationSchema={RegisterSchema}
        >
          {({ handleChange, handleSubmit, errors, touched, values }) => (
            <Form onSubmit={handleSubmit}>
              <FormInput className="form">
                <Col span={12} style={{ height: '100%' }}>
                  <Field name="email">
                    {({ field }) => (
                      <Input
                        {...field}
                        placeholder="Email"
                        className="input"
                        name="email"
                        value={values.email}
                        onChange={handleChange('email')}
                        autoComplete="off"
                        style={{ textDecoration: 'none' }}
                      />
                    )}
                  </Field>
                  <div
                    style={{
                      height: '0px',
                    }}
                  >
                    {errors.email && touched.email && (
                      <div
                        style={{
                          color: COLOR.red,
                          fontSize: '12px',
                          marginLeft: '20px',
                          width: '100%',
                        }}
                      >
                        {errors.email}
                      </div>
                    )}
                  </div>
                </Col>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Button type="submit">SUBSCRIBE</Button>
                </div>
              </FormInput>
            </Form>
          )}
        </Formik>

        <Socials />
      </Container>
    </Wrapper>
  )
}
