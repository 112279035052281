import COLOR from 'constant/COLOR'
import images from 'constant/PICTURE/images'
import styled from 'styled-components'

const LogoFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 775px;
  max-height: 230px;
`
const Container = styled.div`
  display: flex;
  padding: 50px 0px;
  flex-direction: column;
  align-items: center;
  gap: 45px;
  overflow: hidden;
  @media (max-width: 425px) {
    padding: 0;
    gap: 20px;
  }
`
const FooterWrapper = styled.div`
  background-image: linear-gradient(rgba(0, 26, 47, 0.4), rgba(0, 26, 47, 0.4)), url(${images.bgFooter});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-left: 15px;
  padding-right: 15px;
`

const Bottom = styled.div`
  display: flex;
  padding: 11px 7px;
  align-items: center;
  gap: 65px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  color: ${COLOR.white};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  span {
    font-family: Anek Bangla;
  }
  .bottom__left {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .bottom__right {
    display: flex;
    align-items: center;
    gap: 10px;
    a {
      display: block;
      cursor: pointer;
      font-family: Anek Bangla;
    }
  }
  @media (max-width: 425px) {
    font-size: 14px;
    white-space: nowrap;
    gap: 10px;
  }
`
const Social = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  gap: 20px;
  .social-item {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  @media (max-width: 425px) {
    gap: 10px;
  }
`

export const NewFooter = () => {
  return (
    <>
      <footer className="primary-footer">
        <FooterWrapper className="footer-wrapper">
          <Container className="container">
            <LogoFooter>
              <img loading="lazy" width="100%" height="100%" className="light_mode" src={images.logo} />
            </LogoFooter>

            <Bottom>
              <div className="bottom__left">
                <i>
                  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={17} viewBox="0 0 17 17" fill="none">
                    <g clipPath="url(#clip0_1_244)">
                      <path
                        d="M6.964 7.3919C7.008 7.1319 7.088 6.8999 7.204 6.6959C7.32 6.4919 7.48 6.3279 7.676 6.2039C7.864 6.0879 8.1 6.0239 8.392 6.0199C8.584 6.0239 8.76 6.0559 8.912 6.1239C9.076 6.1959 9.212 6.2919 9.328 6.4119C9.444 6.5319 9.532 6.6759 9.596 6.8359C9.66 6.9959 9.696 7.1679 9.704 7.3479H11.136C11.124 6.9719 11.048 6.6279 10.916 6.3159C10.784 6.0039 10.592 5.7359 10.352 5.5079C10.112 5.2799 9.824 5.1079 9.488 4.9839C9.152 4.8599 8.78 4.7959 8.372 4.7959C7.852 4.7959 7.4 4.8839 7.016 5.0639C6.632 5.2439 6.312 5.4879 6.056 5.7999C5.8 6.1119 5.608 6.4719 5.484 6.8839C5.36 7.2959 5.3 7.7319 5.3 8.1919V8.4119C5.3 8.8759 5.364 9.3119 5.488 9.7239C5.612 10.1359 5.804 10.4959 6.06 10.8039C6.316 11.1119 6.636 11.3559 7.024 11.5359C7.412 11.7159 7.864 11.8039 8.384 11.8039C8.76 11.8039 9.112 11.7439 9.44 11.6199C9.768 11.4959 10.056 11.3279 10.304 11.1119C10.552 10.8959 10.752 10.6479 10.896 10.3599C11.04 10.0719 11.124 9.7679 11.136 9.4399H9.704C9.696 9.6079 9.656 9.7599 9.584 9.9039C9.512 10.0479 9.416 10.1679 9.296 10.2679C9.176 10.3679 9.036 10.4479 8.876 10.5039C8.724 10.5559 8.564 10.5839 8.392 10.5839C8.104 10.5759 7.868 10.5159 7.676 10.3959C7.476 10.2679 7.316 10.1039 7.2 9.8999C7.084 9.6959 7.004 9.4639 6.96 9.1999C6.916 8.9359 6.9 8.6799 6.9 8.4119V8.1919C6.9 7.9199 6.92 7.6519 6.964 7.3919Z"
                        fill={COLOR.white}
                      />
                      <path
                        d="M8.5 0.299805C4.08 0.299805 0.5 3.8798 0.5 8.2998C0.5 12.7198 4.08 16.2998 8.5 16.2998C12.92 16.2998 16.5 12.7198 16.5 8.2998C16.5 3.8798 12.92 0.299805 8.5 0.299805ZM8.5 14.6998C4.972 14.6998 2.1 11.8278 2.1 8.2998C2.1 4.7718 4.972 1.8998 8.5 1.8998C12.028 1.8998 14.9 4.7718 14.9 8.2998C14.9 11.8278 12.028 14.6998 8.5 14.6998Z"
                        fill={COLOR.white}
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_244">
                        <rect width={16} height={16} fill={COLOR.white} transform="translate(0.5 0.299805)" />
                      </clipPath>
                    </defs>
                  </svg>
                </i>
                <span>2023 Dark Knight Studios</span>
              </div>
              <div className="bottom__right">
                <a>Privacy & Policy</a>
                <span>|</span>
                <a>Terms & Conditions</a>
              </div>
            </Bottom>
            <Social>
              <a rel="noreferrer" href="https://twitter.com/dark_knightgame" target="_blank" className="social-item">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                  <path
                    d="M13.9761 11.1321L22.7186 0.969727H20.6469L13.0558 9.79356L6.99289 0.969727H0L9.16837 14.3129L0 24.9697H2.07179L10.0881 15.6515L16.491 24.9697H23.4839L13.9756 11.1321H13.9761ZM11.1385 14.4305L10.2096 13.1018L2.81829 2.52933H6.00044L11.9653 11.0617L12.8942 12.3903L20.6479 23.481H17.4657L11.1385 14.431V14.4305Z"
                    fill={COLOR.white}
                  />
                </svg>
              </a>
              <a href="https://t.me/darkknight_games" target="_blank" className="social-item" rel="noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                  <g clipPath="url(#clip0_320_1608)">
                    <path
                      d="M24.0139 2.93608C23.6278 2.58232 23.0791 2.46443 22.5817 2.62835L1.4791 9.58465C0.903991 9.77421 0.513991 10.2876 0.485538 10.8924C0.457132 11.4973 0.79721 12.045 1.35193 12.2878L6.57076 14.5712C6.59621 14.6893 8.09438 21.6378 8.14534 21.8741C8.21073 22.1774 8.36471 22.4477 8.69091 22.5308C9.02087 22.6148 9.25398 22.4345 9.50016 22.2502C9.63718 22.1476 13.373 19.3503 13.373 19.3503L17.9006 23.0552C18.1643 23.2711 18.4866 23.3837 18.8153 23.3837C18.9717 23.3837 19.1297 23.3582 19.2834 23.306C19.7599 23.1445 20.1131 22.7555 20.2283 22.2657L24.4455 4.33596C24.5653 3.82619 24.4 3.28985 24.0139 2.93608ZM9.83752 15.8796C9.83495 15.8857 9.83241 15.8926 9.83002 15.9008L8.95702 18.9509L7.98277 14.4324L14.682 10.7103L9.99821 15.6077C9.92443 15.6848 9.87015 15.7781 9.83752 15.8796ZM10.1146 20.0292L10.5111 18.6438L10.8904 17.3187L12.2493 18.4308L10.1146 20.0292ZM23.0733 4.01318L18.8562 21.943C18.8541 21.9519 18.8513 21.9641 18.8307 21.9711C18.8102 21.9781 18.8003 21.9701 18.7933 21.9643L13.839 17.9102C13.8389 17.9101 13.8388 17.91 13.8386 17.9099L11.5432 16.0315L18.9177 8.32066C19.1586 8.0688 19.1792 7.67875 18.9662 7.40289C18.7532 7.12699 18.3706 7.04819 18.0661 7.2175L7.14648 13.2845L1.91696 10.9964C1.90102 10.9894 1.89226 10.9856 1.89352 10.9586C1.89479 10.9317 1.90388 10.9287 1.92038 10.9233L23.0229 3.967C23.0332 3.96368 23.0447 3.95983 23.0617 3.9753C23.0786 3.99086 23.0758 4.00268 23.0733 4.01318Z"
                      fill={COLOR.white}
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_320_1608">
                      <rect width="24" height="24" fill={COLOR.white} transform="translate(0.483887 0.969727)" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
              <a
                href="https://www.threads.net/@dark.knight.games "
                target="_blank"
                className="social-item"
                rel="noreferrer"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="25" viewBox="0 0 23 25" fill="none">
                  <path
                    d="M11.6697 24.9697H11.6628C8.08193 24.9454 5.32915 23.7649 3.47956 21.4598C1.83308 19.4088 0.984311 16.5549 0.955811 12.9784V12.9611C0.984357 9.38374 1.83308 6.53065 3.47956 4.47968C5.32826 2.17455 8.08194 0.993914 11.6619 0.969727H11.6757C14.4207 0.987867 16.7172 1.69399 18.5019 3.06654C20.1795 4.35696 21.3602 6.1962 22.0119 8.53413L19.9722 9.10287C18.8685 5.14348 16.0751 3.11927 11.6688 3.0873C8.75956 3.10802 6.5599 4.02246 5.12951 5.8047C3.78983 7.47368 3.09753 9.88423 3.07245 12.9689C3.09837 16.0544 3.79067 18.465 5.12951 20.1331C6.55995 21.9153 8.75956 22.8296 11.6688 22.8504C14.291 22.8314 16.0266 22.2204 17.4701 20.8064C19.1174 19.1927 19.0872 17.2135 18.5599 16.0095C18.2496 15.2999 17.686 14.7087 16.9263 14.261C16.7354 15.6111 16.3057 16.707 15.642 17.5324C14.7569 18.6344 13.502 19.2369 11.9134 19.3224C10.7111 19.3871 9.55297 19.1029 8.65409 18.5212C7.59097 17.8332 6.9687 16.7804 6.90214 15.5566C6.83731 14.3665 7.30925 13.2723 8.23058 12.4753C9.11131 11.7139 10.3498 11.2671 11.8131 11.185C12.8917 11.1244 13.9003 11.172 14.833 11.3276C14.7094 10.5851 14.4588 9.99654 14.0854 9.57129C13.5719 8.98615 12.7776 8.68713 11.7258 8.6802H11.6964C10.852 8.6802 9.70508 8.9118 8.97387 9.99912L7.21676 8.81763C8.19514 7.36301 9.78462 6.56266 11.6956 6.56266H11.7388C14.9341 6.58254 16.8365 8.53671 17.0258 11.9498C17.1346 11.9957 17.241 12.0431 17.3464 12.0925C18.8374 12.7934 19.9272 13.8548 20.4994 15.1616C21.2963 16.9835 21.3697 19.9525 18.9506 22.3206C17.1027 24.1304 14.8589 24.9472 11.6757 24.9689H11.6688L11.6697 24.9697ZM12.6723 13.2783C12.4302 13.2783 12.1848 13.2851 11.9341 13.2999C10.0975 13.4036 8.95311 14.2446 9.01793 15.4425C9.08623 16.6975 10.4708 17.2808 11.801 17.2091C13.0257 17.1434 14.6195 16.6672 14.8865 13.4986C14.2106 13.3543 13.4674 13.2783 12.6722 13.2783H12.6723Z"
                    fill={COLOR.white}
                  />
                </svg>
              </a>
              <a href="https://docs.darkknight.games/" target="_blank" className="social-item" rel="noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
                  <path
                    fill={COLOR.white}
                    d="M10.802 17.77a.703.703 0 1 1-.002 1.406a.703.703 0 0 1 .002-1.406m11.024-4.347a.703.703 0 1 1 .001-1.406a.703.703 0 0 1-.001 1.406m0-2.876a2.176 2.176 0 0 0-2.174 2.174c0 .233.039.465.115.691l-7.181 3.823a2.165 2.165 0 0 0-1.784-.937c-.829 0-1.584.475-1.95 1.216l-6.451-3.402c-.682-.358-1.192-1.48-1.138-2.502c.028-.533.212-.947.493-1.107c.178-.1.392-.092.62.027l.042.023c1.71.9 7.304 3.847 7.54 3.956c.363.169.565.237 1.185-.057l11.564-6.014c.17-.064.368-.227.368-.474c0-.342-.354-.477-.355-.477c-.658-.315-1.669-.788-2.655-1.25c-2.108-.987-4.497-2.105-5.546-2.655c-.906-.474-1.635-.074-1.765.006l-.252.125C7.78 6.048 1.46 9.178 1.1 9.397C.457 9.789.058 10.57.006 11.539c-.08 1.537.703 3.14 1.824 3.727l6.822 3.518a2.175 2.175 0 0 0 2.15 1.862a2.177 2.177 0 0 0 2.173-2.14l7.514-4.073c.38.298.853.461 1.337.461A2.176 2.176 0 0 0 24 12.72a2.176 2.176 0 0 0-2.174-2.174"
                  />
                </svg>
              </a>
            </Social>
          </Container>
        </FooterWrapper>
      </footer>
    </>
  )
}
