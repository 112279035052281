import { useEffect, useState } from 'react'
import { useModal } from '@pancakeswap/uikit'
import { useNetwork } from 'wagmi'
import { WrongNetworkModal } from 'components/NetworkModal/WrongNetworkModal'
import { atom, useAtom } from 'jotai'
import { useWeb3React } from '../../../packages/wagmi/src/useWeb3React'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { GetMe } from 'service/UserService'
import useAuth from 'hooks/useAuth'

export const hideWrongNetworkModalAtom = atom(false)

export const ModalRegister = () => {
  const [walletAddress, setWalletAddress] = useState('')
  const localData = window?.localStorage?.getItem('user_social')
  const data = localData ? JSON.parse(localData) : ''
  const { chains, chain } = useNetwork()
  const { account, chainId } = useWeb3React()
  const { isWrongNetwork } = useActiveWeb3React()
  const [dismissWrongNetwork, setDismissWrongNetwork] = useAtom(hideWrongNetworkModalAtom)
  const currentChain = chains.find((c) => c.id === Number(process.env.NEXT_PUBLIC_DEFAULT_CHAIN))
  const [openModalChange, onCloseNetwork] = useModal(
    <WrongNetworkModal currentChain={currentChain} onDismiss={() => setDismissWrongNetwork(true)} />,
    false,
    false,
    'ModalWrongNetwork',
  )
  // console.log(data.userInfo.address != account)
  // console.log(data && data.userInfo.address)
  const { connetSocial } = useAuth()
  // const checkRegis = async () => {
  //   const token = localStorage.getItem('TokenGame')

  //   try {
  //     const data = await GetMe(token)
  //     setWalletAddress(data.data.walletAddress)
  //     if (data.data.walletAddress !== account) {
  //       localStorage.removeItem('TokenGame')
  //       connetSocial()
  //     }
  //   } catch (error) {
  //     const errorResponse = (error as any).response
  //     if (errorResponse?.status === 401 && account) {
  //       connetSocial()
  //     }
  //     console.error('Error during registration check:', error)
  //   }

  //   // if (account)
  //   //   if (data && data.userInfo.address !== account) {
  //   //     window?.localStorage?.setItem('user_social', '')
  //   //   }
  //   if (chainId != null && chainId === Number(process.env.NEXT_PUBLIC_DEFAULT_CHAIN)) {
  //     onCloseNetwork()
  //   } else {
  //     openModalChange()
  //   }
  // }
  const checkRegis = async () => {
    if (chainId != null && chainId === Number(process.env.NEXT_PUBLIC_DEFAULT_CHAIN)) {
      onCloseNetwork()
    } else {
      openModalChange()
    }
  }
  useEffect(() => {
    if (chain?.id && account) checkRegis()
  }, [account, chain, isWrongNetwork, localStorage])
  return <></>
}
