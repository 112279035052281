import BoxStore from 'components/BoxStore'
import NFTDetail from 'components/NFTCard/NFTDetail'
import APP_ROUTES from 'constant/APP_ROUTES'
import { Route, Switch } from 'react-router-dom'
import Airdrop from 'views/Airdrop'
import GetBox from 'views/GetBox'
import HomePage from 'views/HomePage1'
import LeaderBoard from 'views/LeaderBoard'
import MarketPlace from 'views/MarketPlace'
import NotFound from 'views/NotFound'
import Profile from 'views/Profile'
import Team from 'views/Team'
import UserBox from 'views/UserBox'

export const NewNav = () => {
  return (
    <>
      <div>
        <Switch>
          <Route exact path={APP_ROUTES.home}>
            <HomePage />
          </Route>
          <Route exact path={APP_ROUTES.marketPlace}>
            <MarketPlace />
          </Route>
          <Route exact path={APP_ROUTES.userBox}>
            <UserBox />
          </Route>
          <Route exact path={APP_ROUTES.profile}>
            <Profile />
          </Route>
          <Route exact path={APP_ROUTES.team}>
            <Team />
          </Route>
          <Route exact path={APP_ROUTES.getBox}>
            <GetBox />
          </Route>
          <Route exact path={APP_ROUTES.airdrop}>
            <Airdrop />
          </Route>
          <Route exact path={APP_ROUTES.roadmap}>
            <HomePage />
          </Route>
          <Route exact path={APP_ROUTES.boxStore}>
            <GetBox />
          </Route>
          <Route exact path={APP_ROUTES.leaderBoard}>
            <LeaderBoard />
          </Route>
          <Route exact path={`${APP_ROUTES.marketplaceDetail}/:id`}>
            <NFTDetail />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </div>
    </>
  )
}
