import styled from 'styled-components'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'
import images from 'constant/PICTURE/images'
import CustomImage from 'components/CustomSkeleton/CustomImage'
import COLOR from 'constant/COLOR'
import { Link } from 'react-router-dom'
import APP_ROUTES from 'constant/APP_ROUTES'

const ButtonLarge = styled.button`
  background: none;
  border: none;
  display: flex;
  padding: 60px 75px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  background-image: url(${images.buttonLarge});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  min-width: 150px;
  color: ${COLOR.button};
  text-shadow: 0px 2px 0px ${COLOR.bttTextShadow};
  font-family: Anek Bangla;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
  width: fit-content;
  white-space: nowrap;
  touch-action: manipulation;
  transition: all 235ms ease-in-out;
  &:hover {
    text-decoration: none;
    color: ${COLOR.hoverBtt};
    transform: translate3d(0, 2px, 0);
    text-shadow: 0px 4px 4px ${COLOR.hoverYellow};
  }

  @media (max-width: 575px) {
    font-size: 14px;
    padding: 15px 90px;
  }
`
const Button = styled.a`
  display: flex;
  padding: 15px 180px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  background-image: url(${images.button});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  min-width: 250px;
  color: ${COLOR.button};
  text-shadow: 0px 2px 0px ${COLOR.bttTextShadow};
  font-family: Anek Bangla;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
  width: fit-content;
  white-space: nowrap;
  touch-action: manipulation;
  transition: all 235ms ease-in-out;
  &:hover {
    text-decoration: none;
    color: ${COLOR.hoverBtt};
    transform: translate3d(0, 2px, 0);
    text-shadow: 0px 4px 4px ${COLOR.hoverYellow};
  }

  @media (max-width: 575px) {
    font-size: 14px;
  }
`

const WrapImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: transform 330ms ease-in-out;

  &:hover {
    transform: translateY(-25px);
  }
  @media (max-width: 900px) {
    &:hover {
      transform: none;
    }
  }
`
const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  color: ${COLOR.white};
  justify-content: center;
  gap: 20px;
  align-items: flex-start;

  @media (max-width: 900px) {
    gap: 20px;
    .heading {
      font-size: 25px;
    }
  }
  @media (max-width: 575px) {
    align-items: center;

    .heading {
      font-size: 20px;
    }
  }
`
const Heading = styled.text`
  font-family: Russo One;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;

  @media (max-width: 900px) {
    font-size: 25px;
  }
  @media (max-width: 575px) {
    font-size: 20px;
  }
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const Contain = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 100px;
  margin-top: 40px;
  padding: 30px 120px;
  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
  @media (max-width: 900px) {
    flex-direction: column;
    padding: 10px 40px;
    gap: 20px;
  }
`
const Text = styled.text`
  color: ${COLOR.white};
  font-family: Anek Bangla;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  background: ${COLOR.bgToken};
  border-radius: 25px;
  padding: 10px 20px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 15px;
  }
`
const TextContain = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  @media (max-width: 900px) {
    align-items: center;
    flex-direction: column;
  }
`
const TokenContain = styled.div`
  display: flex;
  flex-direction: column;
  background: ${COLOR.bgToken};
  border: 2px solid ${COLOR.black};
  height: 100%;
  align-items: center;
  border-radius: 20px;
  padding: 0 10px 100px 10px;
  @media (max-width: 1024px) {
    padding: 0 10px 20px 10px;
  }
`
const TokenButton = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  gap: 10px;
  text-align: center;
  margin-top: 30px;
`
const TokenText = styled.text`
  color: ${COLOR.white};
  font-family: Anek Bangla;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  @media (max-width: 900px) {
    font-size: 22px;
  }
`

const DarkContain = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
`
const Container = styled.div`
  display: flex;
  padding: 50px 0px;
  flex-direction: column;
  align-items: center;
  gap: 45px;
  overflow: hidden;
`
const Boxes = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 28px;
  flex-wrap: wrap;
`
const Box = styled.div`
  display: flex;
  flex-direction: column;
  color: ${COLOR.white};
  justify-content: center;
  gap: 20px;
  align-items: center;
  max-width: 760px;
`
const Collect = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  justify-content: space-between;
`
const ContainerCollect = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  @media (width <=1280px) {
    flex-wrap: wrap;
    gap: 0px;
    justify-content: center;
  }
  @media (max-width: 575px) {
    padding: 30px;
    align-items: center;
    justify-content: center;
  }
`
const ContainerMystery = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
  padding: 30px 90px;
  @media (width <=1280px) {
    flex-wrap: wrap;
    gap: 0px;
    justify-content: center;
  }
  @media (max-width: 575px) {
    flex-direction: column;
    padding: 20px;
  }
`
const ContentCenter = styled.text`
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  max-width: 550px;
  @media (max-width: 900px) {
    font-size: 16px;
    line-height: initial;
  }
  @media (max-width: 900px) {
    font-size: 14px;
  }
`
const Content = styled.text`
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  max-width: 450px;
  @media (max-width: 900px) {
    font-size: 16px;
    line-height: initial;
  }
  @media (max-width: 900px) {
    font-size: 14px;
  }
  @media (max-width: 575px) {
    font-size: 14px;
    text-align: center;
    width: 350px;
  }
`
const Subtitle = styled.text`
  font-family: Russo One;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
  @media (max-width: 900px) {
    font-size: 25px;
    line-height: normal;
  }
  @media (max-width: 575px) {
    font-size: 18px;
    text-align: center;
  }
`
export const DarkToken = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 })
  }, [])

  return (
    <>
      <section className="game">
        <Container className="container">
          <Boxes className="boxs">
            <Box className="box textbox" data-aos="fade-up">
              <Heading
                className="heading"
                style={{
                  WebkitTextStroke: `1px ${COLOR.bgTitleMarket}`,
                  WebkitTextStrokeWidth: '1px',
                  color: COLOR.white,
                }}
              >
                SKILLS
              </Heading>
              <WrapImage className="image wrapimage">
                <CustomImage className="image" src="/images/DarkKnight/bosshunting.png" alt="" />
              </WrapImage>
              <ContentCenter className="content">
                Lots of skills for you to combine together, use wisely to destroy the target!
              </ContentCenter>
            </Box>
            <Box className="box textbox" data-aos="fade-up">
              <Heading
                className="heading"
                style={{
                  WebkitTextStroke: `1px ${COLOR.bgTitleMarket}`,
                  WebkitTextStrokeWidth: '1px',
                  color: COLOR.white,
                }}
              >
                BOSS HUNTING
              </Heading>
              <WrapImage className="image wrapimage">
                <Image className="image" src={images.boss} alt="" />
              </WrapImage>
              <ContentCenter className="content">
                Explore special Adventure Instances, complete challenges, and defeat bosses to collect the ultimate
                rewards.
              </ContentCenter>
            </Box>
          </Boxes>
        </Container>
      </section>
      <section className="collect">
        <ContainerCollect className="container" data-aos="fade-up" style={{ padding: 0 }}>
          <div className="collect__left wrapimage">
            <CustomImage src={images.darkToken} alt="" />
          </div>
          <Collect className="collect__right">
            <TextBox>
              <Subtitle
                className="subtitle"
                style={{
                  WebkitTextStroke: `1px ${COLOR.bgTitleMarket}`,
                  WebkitTextStrokeWidth: '1px',
                  color: COLOR.white,
                }}
              >
                COLLECT $DARK{' '}
              </Subtitle>
              <Content className="content">
                The $DARK Token is the main cryptocurrency that drives Dark Knight&apos;s economy.
              </Content>
              <Button>LEARN MORE</Button>
            </TextBox>
          </Collect>
        </ContainerCollect>
      </section>
      <section
        className="collect"
        style={{
          marginTop: '30px',
        }}
      >
        <WrapImage className="collect__left wrapimage">
          <Image
            style={{
              width: '500px',
            }}
            src={images.tokenomicTitle}
            alt=""
          />
        </WrapImage>
        <Contain data-aos="fade-up">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}
          >
            <TextContain>
              <img style={{ width: '200px', height: '50px' }} src={images.tokenomicName} alt="" />
              <img style={{ width: '250px', height: '50px' }} src={images.tokenomicSupply} alt="" />
            </TextContain>
            <WrapImage className="collect__left wrapimage">
              <CustomImage
                style={{
                  width: '100%',
                }}
                src={images.tokenomic}
                alt=""
              />
            </WrapImage>
          </div>
          <img style={{ width: '550px' }} src={images.bannerDark} alt="" />
          {/* <TokenContain>
            <CustomImage src={images.tokenomicBanner} alt="" />
            <div
              style={{
                height: '2px',
                background: '#000',
                width: '100%',
              }}
            />
            <DarkContain>
              <Image src={images.tokenDark} alt="" />
              <Image src={images.dark} alt="" />
            </DarkContain>
            <TokenButton>
              <TokenText>In-Game Purchases</TokenText>
              <TokenText>Governance</TokenText>
              <TokenText>Staking</TokenText>
              <TokenText>Marketplace</TokenText>
            </TokenButton>
          </TokenContain> */}
        </Contain>
      </section>
      <section className="mysterybox collect">
        <ContainerCollect className="container" data-aos="fade-up">
          <WrapImage className="collect__left wrapimage">
            <CustomImage src={images.leaderBoard} alt="" />
          </WrapImage>
          <Collect className="collect__right">
            <TextBox className="textbox --content">
              <Subtitle
                className="subtitle"
                style={{
                  WebkitTextStroke: `1px ${COLOR.bgTitleMarket}`,
                  WebkitTextStrokeWidth: '1px',
                  color: COLOR.white,
                }}
              >
                LEADERBOARD RACE AND RECEIVE $DARK
              </Subtitle>
              <Content className="content">
                Join the game experience and race top to receive weekly top rewards.
              </Content>
              <Link to={APP_ROUTES.leaderBoard}>
                <ButtonLarge
                  style={{
                    padding: '20px 85px',
                  }}
                >
                  CHECK OUT THE LEADERBOARD{' '}
                </ButtonLarge>
              </Link>
            </TextBox>
          </Collect>
        </ContainerCollect>
      </section>
      <Collect className="mysterybox collect --reverse">
        <ContainerMystery className="container" data-aos="fade-up">
          <Collect className="collect__right">
            <TextBox className="textbox --content">
              <Subtitle
                className="subtitle"
                style={{
                  WebkitTextStroke: `1px ${COLOR.bgTitleMarket}`,
                  WebkitTextStrokeWidth: '1px',
                  color: COLOR.white,
                }}
              >
                IT&apos;S TIME TO OPEN YOUR MYSTERY BOXES
              </Subtitle>
              <Content className="content">
                The time has finally come! Open your Mystery Boxes and use the contents inside to begin crafting.
              </Content>
            </TextBox>
          </Collect>
          <WrapImage className="collect__left wrapimage">
            <CustomImage src={images.mysteryBox} alt="" />
          </WrapImage>
        </ContainerMystery>
      </Collect>
    </>
  )
}
