import { Text } from "@pancakeswap/uikit";
import styled from "styled-components";
import color from "./color";
export const Title01 = styled(Text)`
color: ${color.title};

text-align: center;
font-family: Ethnocentric;
font-size: 64px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 1.92px;
text-transform: uppercase;
@media screen and (max-width: 900px) {
    font-size: 32px;
}
@media screen and (max-width: 575px) {
    font-size: 24px;
}
`
export const Title02 = styled(Text)`
color: ${color.titleWhite};
text-align: center;
font-family: Ethnocentric;
font-size: 40px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 1.2px;
text-transform: uppercase;
@media screen and (max-width: 900px) {
    font-size: 32px;
}
@media screen and (max-width: 575px) {
    font-size: 24px;
}   
`
export const Tietle03 = styled(Text)`
color: ${color.titleWhite};
font-family: Poppins,sans-serif;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
@media screen and (max-width: 900px) {
    font-size: 22px;
}
@media screen and (max-width: 575px) {
    font-size: 18px;
}
`
export const Label1 = styled(Text)`
color: ${color.label};
font-family: Poppins,sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
@media screen and (max-width: 575px) {
    font-size: 14px;
    width: 100%;
}
`
export const Label2 = styled(Text)`
color: ${color.brown};
text-align: center;
font-family: Poppins,sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
@media screen and (max-width: 575px) {
    font-size: 14px;
}
`
