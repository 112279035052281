import images from 'constant/PICTURE/images'

export const MysteryCard = [
  {
    desc: 'Users who participate in the Airdrop will receive 500 $DARK and will have a chance at an exclusive draw.',
    img: `${images.tokenAirdrop}`,
  },
  {
    desc: 'In particular, 15 lucky users will receive 1 of 3 Mystery Boxes (HERO BOX, CHAMPION BOX and WARRIOR BOX).',
    img: `${images.chest}`,
  },

  {
    desc: 'Lucky users will be announced after the airdrop ends.',
    img: `${images.m3}`,
  },
]
