import { AllType, ProductType } from 'components/Data/models/ProductType'

export const DefaultProduct: ProductType[] = [
  {
    id: '1',
    attack: 10,
    critical: 10,
    accuracy: 10,
    img: '/images/DarkKnight/Card.png',
    price: 10.1,
    description: '123',
    all: AllType.normal,
  },
  {
    id: '2',
    attack: 20,
    critical: 20,
    accuracy: 20,
    img: '/images/DarkKnight/Card1.png',
    price: 20.2,
    description: '123',
    all: AllType.rare,
  },
  {
    id: '3',
    attack: 30,
    critical: 30,
    accuracy: 30,
    img: '/images/DarkKnight/Card2.png',
    price: 30.3,
    description: '123',
    all: AllType.epic,
  },
  {
    id: '4',
    attack: 40,
    critical: 40,
    accuracy: 40,
    img: '/images/DarkKnight/Card3.png',
    price: 40.4,
    description: '123',
    all: AllType.legendary,
  },
  {
    id: '5',
    attack: 50,
    critical: 50,
    accuracy: 50,
    img: '/images/DarkKnight/Card4.png',
    price: 50.5,
    description: '123',
    all: AllType.mythical,
  },
  {
    id: '6',
    attack: 60,
    critical: 60,
    accuracy: 60,
    img: '/images/DarkKnight/Card5.png',
    price: 60.6,
    description: '123',
    all: AllType.normal,
  },
  {
    id: '7',
    attack: 70,
    critical: 70,
    accuracy: 70,
    img: '/images/DarkKnight/Card6.png',
    price: 70.7,
    description: '123',
    all: AllType.rare,
  },
  {
    id: '8',
    attack: 80,
    critical: 80,
    accuracy: 80,
    img: '/images/DarkKnight/Card7.png',
    price: 80.8,
    description: '123',
    all: AllType.epic,
  },
  {
    id: '9',
    attack: 90,
    critical: 90,
    accuracy: 90,
    img: '/images/DarkKnight/Card8.png',
    price: 90.9,
    description: '123',
    all: AllType.legendary,
  },
  {
    id: '10',
    attack: 100,
    critical: 100,
    accuracy: 100,
    img: '/images/DarkKnight/Card9.png',
    price: 100.1,
    description: '123',
    all: AllType.mythical,
  },
  {
    id: '11',
    attack: 110,
    critical: 110,
    accuracy: 110,
    img: '/images/DarkKnight/Card10.png',
    price: 110.11,
    description: '123',
    all: AllType.normal,
  },
  {
    id: '12',
    attack: 120,
    critical: 120,
    accuracy: 120,
    img: '/images/DarkKnight/Card11.png',
    price: 120.2,
    description: '123',
    all: AllType.rare,
  },
  {
    id: '13',
    attack: 130,
    critical: 130,
    accuracy: 130,
    img: '/images/DarkKnight/Card12.png',
    price: 130.3,
    description: '123',
    all: AllType.epic,
  },
  {
    id: '14',
    attack: 140,
    critical: 140,
    accuracy: 140,
    img: '/images/DarkKnight/Card13.png',
    price: 140.4,
    description: '123',
    all: AllType.legendary,
  },
  {
    id: '15',
    attack: 150,
    critical: 150,
    accuracy: 150,
    img: '/images/DarkKnight/Card14.png',
    price: 150.5,
    description: '123',
    all: AllType.mythical,
  },
]
