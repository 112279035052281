import COLOR from 'constant/COLOR'
import images from 'constant/PICTURE/images'

export const DataBox = [
  {
    id: 1,
    name: 'HERO BOX',
    desc: 'Contains one Legendary or Epic Mystery Box, resolving into 1 Tradable Asset.',
    img: `${images.heroBox}`,
    border: `${COLOR.borderHero}`,
    shadow: `${COLOR.shadowHero}`,
    price: 50,
    core: 0.1,
    soldOut: `${images.heroSO}`,
  },
  {
    id: 2,
    name: 'WARRIOR BOX',
    desc: 'Contains one Epic or Rare Mystery Box, resolving into 1 Tradable Asset.',
    img: `${images.championBox}`,
    border: `${COLOR.borderChampion}`,
    shadow: `${COLOR.shadowChampion}`,
    price: 100,
    core: 0.2,
    soldOut: `${images.warriorSO}`,
  },

  {
    id: 3,
    name: 'CHAMPION BOX',
    desc: 'Contains one Rare, Common or Uncommon Mystery Box, resolving into 1 Tradable Asset.',
    img: `${images.warriorBox}`,
    border: `${COLOR.borderWarrior}`,
    shadow: `${COLOR.shadowWarrior}`,
    price: 200,
    core: 0.5,
    soldOut: `${images.championSO}`,
  },
]
