import { Button, Flex, Grid, Menu, MenuButton, MenuList, Stack, Text } from '@chakra-ui/react'
import 'aos/dist/aos.css'
import ArrowB from 'components/Svg/ArrowB'
import COLOR from 'constant/COLOR'
import images from 'constant/PICTURE/images'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useResponsive from 'hooks/useResponsive'
import React, { useEffect, useState } from 'react'
import { listLeaderBoard } from 'service/UserService'
import styled from 'styled-components'
import numeral from 'numeral'
import moment from 'moment'

const Wrapper = styled.div`
  font-style: normal;
  overflow: hidden;
  background: ${COLOR.bg};
  loading: lazy;
  background-image: url(${images.sectionLeaderBoard});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ButtonTop = styled.a`
  padding: 10px;
  cursor: pointer;
  background-image: url(${images.bttTop});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  white-space: nowrap;
  touch-action: manipulation;
  transition: all 235ms ease-in-out;
  &:hover {
    text-decoration: none;
    color: ${COLOR.hoverBtt};
    transform: translate3d(0, 2px, 0);
    text-shadow: 0px 4px 4px ${COLOR.hoverYellow};
  }
  @media (max-width: 900px) {
    padding: 15px;
    font-size: 14px;
    min-width: 135px;
  }
`
const LeaderBoard: React.FC<React.PropsWithChildren> = () => {
  const { isMobile, isMobileOrTablet, isTabletOrLaptop, isBigScreen } = useResponsive()
  const shortenURL = (s: string, max: number) => {
    return s.length > max ? s.substring(0, max / 2 - 1) + '...' + s.substring(s.length - max / 2 + 2, s.length) : s
  }
  const [user, setUser] = useState([])
  const [totalUser, setTotalUser] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const pageLimit = 10
  const { account } = useActiveWeb3React()
  const CHAIN_ID = Number(process.env.NEXT_PUBLIC_DEFAULT_CHAIN)
  const [selectedType, setSelectedType] = useState('level')
  const timeDisplayLong = (seconds: number) => {
    let y = Math.floor(seconds / 31536000)
    let mo = Math.floor((seconds % 31536000) / 2628000)
    let d = Math.floor(((seconds % 31536000) % 2628000) / 86400)
    let h = Math.floor((seconds % (3600 * 24)) / 3600)
    let m = Math.floor((seconds % 3600) / 60)
    let s = Math.floor(seconds % 60)

    let yDisplay = y > 0 ? y + (y === 1 ? ' Y ' : ' Y ') : ''
    let moDisplay = mo > 0 ? mo + (mo === 1 ? ' M, ' : ' M ') : ''
    let dDisplay = d > 0 ? d + (d === 1 ? ' D ' : ' D ') : ''
    let hDisplay = h > 0 ? (h < 10 ? '0' + h + (h === 1 ? ':' : ':') : h + (h === 1 ? ':' : ':')) : ''
    let mDisplay = m > 0 ? (m < 10 ? '0' + m + (m === 1 ? ':' : ':') : m + (m === 1 ? ':' : ':')) : ''
    let sDisplay = s > 0 ? (s < 10 ? '0' + s : s) : ''
    return yDisplay + moDisplay + dDisplay + hDisplay + mDisplay + sDisplay
  }
  const getTypeDisplayText = (type: string): string => {
    switch (type) {
      case 'level':
        return 'Top Level'
      case 'online_time':
        return 'Top Online'
      case 'power':
        return 'Top Power'
      case 'deposit':
        return 'Top Deposit'
      default:
        return ''
    }
  }

  const handleTypeChange = (type: string) => {
    setSelectedType(type)
    getUser(pageNumber, type)
  }
  const getUser = async (page, type) => {
    try {
      const data = await listLeaderBoard(page, pageLimit, type)
      setUser(data.data.users || [])

      setTotalUser(data.data.totalRecord)
    } catch (error) {
      console.error('Error fetching user data:', error)
    }
  }
  useEffect(() => {
    getUser(pageNumber, selectedType)
  }, [pageNumber])
  const showBackButton = pageNumber > 1
  const showNextButton = user.length === pageLimit

  const handleBack = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1)
    }
  }

  const handleNext = () => {
    setPageNumber(pageNumber + 1)
  }
  return (
    <>
      <img src={images.bannerLeaderBoard} alt="" loading="lazy" />

      <Wrapper>
        <Stack justifyContent="center" alignItems="center" p={isMobile ? '20px 0' : '50px 70px'}>
          <Flex justifyContent="space-between" w="full" p={isMobileOrTablet ? '20px' : 0} alignItems="center">
            <Flex alignItems={isMobile ? 'flex-start' : 'flex-end'} gap="10px" direction={isMobile ? 'column' : 'row'}>
              <Text
                color="#161616"
                textAlign="center"
                sx={{
                  WebkitTextStroke: '3px #01f0ff',
                  WebkitTextStrokeWidth: isMobile ? '1px' : '3px',
                }}
                textShadow="0px 10px 22px rgba(48, 254, 255, 0.50)"
                fontSize={isMobile ? '20px' : '40px'}
                fontStyle="normal"
                fontWeight={600}
                lineHeight="normal"
              >
                TOP 10
              </Text>
              <Text
                sx={{
                  color: COLOR.white,
                  fontWeight: 600,
                  fontFamily: 'Anek Bangla',
                  fontSize: '17px',
                  lineHeight: '13px',
                }}
              >
                ( {pageNumber > 1 ? user.length + pageLimit * (pageNumber - 1) : user.length} / {totalUser} )
              </Text>
            </Flex>
            <Menu>
              <ButtonTop>
                <MenuButton
                  _hover={{ bg: 'none' }}
                  as={Button}
                  bg="none"
                  border="none"
                  borderRadius="12px"
                  p="27px 10px"
                  rightIcon={<ArrowB />}
                  h="4px"
                  w="150px"
                  cursor="pointer"
                  colorScheme="none"
                >
                  <Text>{getTypeDisplayText(selectedType)}</Text>
                </MenuButton>
              </ButtonTop>
              <MenuList
                bg={COLOR.modalB}
                borderRadius="12px"
                p="30px"
                w="320px"
                h="230px"
                zIndex={1}
                border={`1px solid ${COLOR.borderModalB}`}
              >
                <Stack>
                  <Flex
                    _hover={{
                      borderRadius: '10px',
                      background: '#474C58',
                      cursor: 'pointer',
                      padding: '10px',
                    }}
                    onClick={() => handleTypeChange('level')}
                    transition="all 235ms ease-in-out"
                    border="1px solid transparent"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Text
                      color="#FFF"
                      fontFamily="Anek Bangla"
                      fontSize="18px"
                      fontStyle="normal"
                      fontWeight={600}
                      lineHeight="normal"
                      m={0}
                    >
                      Top Level
                    </Text>
                    <img width="30px" src={images.levelIcon} alt="" />
                  </Flex>

                  <Flex
                    transition="all 235ms ease-in-out"
                    _hover={{
                      borderRadius: '10px',
                      background: '#474C58',
                      cursor: 'pointer',
                      padding: '10px',
                    }}
                    onClick={() => handleTypeChange('power')}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Text
                      color="#FFF"
                      fontFamily="Anek Bangla"
                      fontSize="18px"
                      fontStyle="normal"
                      fontWeight={600}
                      lineHeight="normal"
                      m={0}
                    >
                      Top Power
                    </Text>
                    <img width="30px" src={images.powerIcon} alt="" />
                  </Flex>
                  <Flex
                    transition="all 235ms ease-in-out"
                    _hover={{
                      borderRadius: '10px',
                      background: '#474C58',
                      cursor: 'pointer',
                      padding: '10px',
                    }}
                    onClick={() => handleTypeChange('online_time')}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text
                      color="#FFF"
                      fontFamily="Anek Bangla"
                      fontSize="18px"
                      fontStyle="normal"
                      fontWeight={600}
                      m={0}
                      lineHeight="normal"
                    >
                      Top Online
                    </Text>
                    <img width="30px" src={images.timeIcon} alt="" />
                  </Flex>
                  <Text
                    color="#FFF"
                    fontFamily="Anek Bangla"
                    fontSize="18px"
                    transition="all 235ms ease-in-out"
                    fontStyle="normal"
                    fontWeight={600}
                    m={0}
                    lineHeight="normal"
                    _hover={{
                      borderRadius: '10px',
                      background: '#474C58',
                      cursor: 'pointer',
                      padding: '10px',
                    }}
                    onClick={() => handleTypeChange('deposit')}
                  >
                    Top Deposit
                  </Text>
                </Stack>
              </MenuList>
            </Menu>
          </Flex>

          {isMobile ? (
            <Stack
              borderRadius="12px"
              border="2px solid #616161"
              bg="rgba(255, 255, 255, 0.10)"
              backdropFilter="blur(50.5px)"
              p="20px"
            >
              {user.map((item, index) => (
                <Grid
                  gridRowGap="10px"
                  gridColumnGap="10px"
                  gridTemplateColumns="1fr 1fr"
                  key={index}
                  borderRadius="8px"
                  p="20px"
                  w="320px"
                  alignItems="center"
                  bg={
                    index === 0 && pageNumber <= 1
                      ? COLOR.bgTop1
                      : index === 1 && pageNumber <= 1
                      ? COLOR.bgTop2
                      : index === 2 && pageNumber <= 1
                      ? COLOR.bgTop3
                      : COLOR.bgBoard
                  }
                >
                  {index === 0 && pageNumber <= 1 ? (
                    <img width="52px" src={images.top1} alt="" />
                  ) : index === 1 && pageNumber <= 1 ? (
                    <img width="52px" src={images.top2} alt="" />
                  ) : index === 2 && pageNumber <= 1 ? (
                    <img width="52px" src={images.top3} alt="" />
                  ) : (
                    <Stack w="52px" h="52px" alignItems="center" justifyContent="center">
                      <Text m={0} fontSize="15px" fontFamily="Anek Bangla" fontWeight={600} key={item} color="#fff">
                        {pageNumber > 1 ? index + 1 + pageLimit * (pageNumber - 1) : index + 1}
                      </Text>
                    </Stack>
                  )}
                  <Flex alignItems="center" gap="7px">
                    <img style={{ width: '20px' }} src={images.walletIcon} />

                    <Text
                      sx={{ color: item.walletAddress === account ? COLOR.lime : COLOR.white }}
                      fontSize="15px"
                      fontFamily="Anek Bangla"
                      fontWeight={600}
                      key={item}
                      wordBreak="break-word"
                    >
                      {shortenURL(`${item.walletAddress}`, 10)}
                    </Text>
                  </Flex>

                  <Flex gap="10px">
                    <Flex display={selectedType === 'level' ? 'flex' : 'none'} alignItems="center" gap="10px">
                      <img width="30px" src={images.levelIcon} alt="" />
                      <Text
                        sx={{ color: item.walletAddress === account ? COLOR.lime : COLOR.white }}
                        fontSize="15px"
                        fontFamily="Anek Bangla"
                        fontWeight={600}
                        key={item}
                        textAlign="center"
                      >
                        {item.level}
                      </Text>
                    </Flex>
                    <Flex display={selectedType === 'power' ? 'flex' : 'none'} alignItems="center" gap="10px">
                      <img width="30px" src={images.powerIcon} alt="" />
                      <Text
                        sx={{ color: item.walletAddress === account ? COLOR.lime : COLOR.white }}
                        fontSize="15px"
                        fontFamily="Anek Bangla"
                        fontWeight={600}
                        key={item}
                        textAlign="center"
                      >
                        {numeral(item.power).format('0,0')}
                      </Text>
                    </Flex>
                    <Flex display={selectedType === 'online_time' ? 'flex' : 'none'} alignItems="center" gap="5px">
                      <img width="30px" src={images.timeIcon} alt="" />
                      <Text
                        sx={{ color: item.walletAddress === account ? COLOR.lime : COLOR.white }}
                        fontSize="15px"
                        fontFamily="Anek Bangla"
                        fontWeight={600}
                        key={item}
                        textAlign="center"
                        wordBreak="break-word"
                      >
                        {timeDisplayLong(item.totalOnline)}
                      </Text>
                    </Flex>

                    <Text
                      display={selectedType === 'deposit' ? 'flex' : 'none'}
                      sx={{ color: item.walletAddress === account ? COLOR.lime : COLOR.white }}
                      fontSize="15px"
                      fontFamily="Anek Bangla"
                      fontWeight={600}
                      key={item}
                      textAlign="center"
                    >
                      {item.totalDeposit}
                    </Text>
                  </Flex>
                  <Flex alignItems="center" gap="2px">
                    <img style={{ width: '30px' }} src={images.darkIcon} alt="" />
                    <Text
                      fontSize="15px"
                      fontFamily="Anek Bangla"
                      fontWeight={600}
                      key={item}
                      sx={{ color: item.walletAddress === account ? COLOR.lime : COLOR.white }}
                    >
                      {numeral(item.gold).format('0,0')}
                    </Text>
                  </Flex>
                </Grid>
              ))}
            </Stack>
          ) : (
            <Stack
              transition="all 300ms ease-in-out"
              p="30px"
              w={isTabletOrLaptop ? '750px' : '900px'}
              borderRadius="12px"
              border="2px solid #616161"
              bg="rgba(255, 255, 255, 0.10)"
              backdropFilter="blur(50.5px)"
            >
              <Flex
                justifyContent="space-between"
                p="10px 15px"
                alignItems="center"
                gap="20px"
                borderRadius="12px"
                bg="rgba(0, 0, 0, 0.30)"
              >
                <Text
                  color="#fff"
                  textAlign="center"
                  fontFamily="Anek Bangla"
                  fontSize="18px"
                  fontStyle="normal"
                  fontWeight={700}
                  lineHeight="normal"
                >
                  Rank
                </Text>
                <Text
                  color="#fff"
                  fontFamily="Anek Bangla"
                  fontSize="18px"
                  fontStyle="normal"
                  fontWeight={700}
                  lineHeight="normal"
                >
                  Wallet
                </Text>
                <Text
                  display={selectedType === 'level' ? 'flex' : 'none'}
                  color="#fff"
                  textAlign="center"
                  fontFamily="Anek Bangla"
                  fontSize="18px"
                  fontStyle="normal"
                  fontWeight={700}
                  lineHeight="normal"
                >
                  Level
                </Text>
                <Text
                  display={selectedType === 'power' ? 'flex' : 'none'}
                  color="#fff"
                  textAlign="center"
                  fontFamily="Anek Bangla"
                  fontSize="18px"
                  fontStyle="normal"
                  fontWeight={700}
                  lineHeight="normal"
                >
                  Power
                </Text>{' '}
                <Text
                  display={selectedType === 'online_time' ? 'flex' : 'none'}
                  color="#fff"
                  textAlign="center"
                  fontFamily="Anek Bangla"
                  fontSize="18px"
                  fontStyle="normal"
                  fontWeight={700}
                  lineHeight="normal"
                >
                  Online
                </Text>{' '}
                <Text
                  display={selectedType === 'deposit' ? 'flex' : 'none'}
                  color="#fff"
                  textAlign="center"
                  fontFamily="Anek Bangla"
                  fontSize="18px"
                  fontStyle="normal"
                  fontWeight={700}
                  lineHeight="normal"
                >
                  Deposit
                </Text>
                <Text
                  color="#fff"
                  textAlign="center"
                  fontFamily="Anek Bangla"
                  fontSize="18px"
                  fontStyle="normal"
                  fontWeight={700}
                  lineHeight="normal"
                >
                  Gold
                </Text>
              </Flex>
              {user.map((item, index) => (
                <Flex
                  key={index}
                  justifyContent="space-between"
                  borderRadius="8px"
                  p={isTabletOrLaptop ? '10px' : '5px 20px'}
                  alignItems="center"
                  gap="20px"
                  bg={
                    index === 0 && pageNumber <= 1
                      ? COLOR.bgTop1
                      : index === 1 && pageNumber <= 1
                      ? COLOR.bgTop2
                      : index === 2 && pageNumber <= 1
                      ? COLOR.bgTop3
                      : COLOR.bgBoard
                  }
                >
                  {index === 0 && pageNumber <= 1 ? (
                    <img width="52px" src={images.top1} alt="" />
                  ) : index === 1 && pageNumber <= 1 ? (
                    <img width="52px" src={images.top2} alt="" />
                  ) : index === 2 && pageNumber <= 1 ? (
                    <img width="52px" src={images.top3} alt="" />
                  ) : (
                    <Stack w="52px" h="52px" alignItems="center" justifyContent="center">
                      <Text
                        m={0}
                        fontSize="15px"
                        fontFamily="Anek Bangla"
                        fontWeight={600}
                        key={item}
                        sx={{ color: item.walletAddress === account ? COLOR.lime : COLOR.white }}
                      >
                        {pageNumber > 1 ? index + 1 + pageLimit * (pageNumber - 1) : index + 1}
                      </Text>
                    </Stack>
                  )}
                  <Text
                    sx={{ color: item.walletAddress === account ? COLOR.lime : COLOR.white }}
                    fontSize="15px"
                    fontFamily="Anek Bangla"
                    fontWeight={600}
                    key={item}
                    wordBreak="break-word"
                  >
                    {shortenURL(`${item.walletAddress}`, 15)}
                  </Text>

                  <Flex gap="10px">
                    <Flex display={selectedType === 'level' ? 'flex' : 'none'} alignItems="center" gap="10px">
                      <img width="30px" src={images.levelIcon} alt="" />
                      <Text
                        sx={{ color: item.walletAddress === account ? COLOR.lime : COLOR.white }}
                        fontSize="15px"
                        fontFamily="Anek Bangla"
                        fontWeight={600}
                        key={item}
                        textAlign="center"
                      >
                        {item.level}
                      </Text>
                    </Flex>
                    <Flex display={selectedType === 'power' ? 'flex' : 'none'} alignItems="center" gap="10px">
                      <img width="30px" src={images.powerIcon} alt="" />
                      <Text
                        sx={{ color: item.walletAddress === account ? COLOR.lime : COLOR.white }}
                        fontSize="15px"
                        fontFamily="Anek Bangla"
                        fontWeight={600}
                        key={item}
                        textAlign="center"
                      >
                        {numeral(item.power).format('0,0')}
                      </Text>
                    </Flex>
                    <Flex display={selectedType === 'online_time' ? 'flex' : 'none'} alignItems="center" gap="10px">
                      <img width="30px" src={images.timeIcon} alt="" />
                      <Text
                        sx={{ color: item.walletAddress === account ? COLOR.lime : COLOR.white }}
                        fontSize="15px"
                        fontFamily="Anek Bangla"
                        fontWeight={600}
                        key={item}
                        textAlign="center"
                        wordBreak="break-word"
                      >
                        {timeDisplayLong(item.totalOnline)}
                      </Text>
                    </Flex>

                    <Text
                      display={selectedType === 'deposit' ? 'flex' : 'none'}
                      sx={{ color: item.walletAddress === account ? COLOR.lime : COLOR.white }}
                      fontSize="15px"
                      fontFamily="Anek Bangla"
                      fontWeight={600}
                      key={item}
                      textAlign="center"
                    >
                      {item.totalDeposit}
                    </Text>
                  </Flex>
                  <Flex alignItems="center" justifyContent="space-between" w={isMobileOrTablet ? '70px' : '80px'}>
                    <img style={{ width: '30px' }} src={images.darkIcon} alt="" />
                    <Text
                      fontSize="15px"
                      fontFamily="Anek Bangla"
                      fontWeight={600}
                      key={item}
                      sx={{ color: item.walletAddress === account ? COLOR.lime : COLOR.white }}
                    >
                      {numeral(item.gold).format('0,0')}
                    </Text>
                  </Flex>
                </Flex>
              ))}
            </Stack>
          )}
          <Flex justifyContent="center" alignItems="center" mt="20px">
            {showBackButton && (
              <Button
                onClick={handleBack}
                bg="none"
                transition="all 235ms ease-in-out"
                _hover={{
                  background: 'none',
                  transform: 'translate3d(0, 2px, 0)',
                }}
              >
                <img src={images.backBtt} />
              </Button>
            )}
            {showNextButton && (
              <Button
                bg="none"
                onClick={handleNext}
                transition="all 235ms ease-in-out"
                _hover={{
                  background: 'none',
                  transform: 'translate3d(0, 2px, 0)',
                }}
              >
                <img src={images.nextBtt} />
              </Button>
            )}
          </Flex>
        </Stack>
      </Wrapper>
    </>
  )
}

export default LeaderBoard
