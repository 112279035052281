import styled from 'styled-components'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { useEffect, useMemo, useState } from 'react'
import HatIcon from 'components/Svg/HatIcon'
import SwordIcon from 'components/Svg/SwordIcon'
import ForgeIcon from 'components/Svg/ForgeIcon'
import ArmoryIcon from 'components/Svg/ArmoryIcon'
import CustomImage from 'components/CustomSkeleton/CustomImage'
import images from 'constant/PICTURE/images'
import COLOR from 'constant/COLOR'
import { Flex } from '@chakra-ui/react'

const Container = styled.div`
  display: flex;
  /* width: 1578px; */
  padding: 50px 0px;
  flex-direction: column;
  align-items: center;
  gap: 45px;
  overflow: hidden;
`
const WrapImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: transform 330ms ease-in-out;

  &:hover {
    transform: translateY(-25px);
  }
  @media (max-width: 900px) {
    &:hover {
      transform: none;
    }
  }
  .utilize__mid {
    display: flex;
    align-items: center;
    gap: 40px;

    .utilize__mid-right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }

    @media (width <=1280px) {
      flex-wrap: wrap;
      gap: 30px;
      justify-content: center;
    }
  }
`
const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 45px;
  color:${COLOR.white};
  text-align: center;

  .heading {
    font-family: Russo One;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
  @media (max-width: 900px) {
    gap: 20px;
    .heading {
      font-size: 25px;
    }
  }
  @media (max-width: 575px) {
    .heading {
      font-size: 20px;
    }
`
const TextBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 45px;
  color: ${COLOR.white};
  text-align: center;
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    max-width: 550px;
     gap: 20px;
    align-items: flex-start;
  .heading {
    font-family: Anek Bangla;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
  @media (max-width: 900px) {
    gap: 20px;
    .heading {
      font-size: 25px;
    }
  }
  @media (max-width: 575px) {
      gap: 10px;
      text-align: center;
      align-items: center;
    .heading {
      font-size: 20px;
    }
`
const Desc = styled.text`
  font-family: Anek Bangla;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 50px;
  text-align: center;

  @media (max-width: 900px) {
    font-size: 20px;
    line-height: normal;
  }
  @media (max-width: 575px) {
    font-size: 16px;
  }
`
const Title = styled.text`
  // border-bottom: 1px solid ${COLOR.white};
  font-family: Russo One;
  font-size: 65px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  @media (max-width: 900px) {
    font-size: 30px;
    line-height: normal;
  }
  @media (max-width: 575px) {
    font-size: 25px;
  }
`

const Heading = styled.text`
  font-family: Russo One;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  @media (max-width: 900px) {
    font-size: 25px;
  }
  @media (max-width: 575px) {
    font-size: 20px;
  }
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const Utilize = styled.div`
  .utilize__mid {
    display: flex;
    align-items: center;
    gap: 40px;

    .utilize__mid-right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }

    @media (width <=1280px) {
      flex-wrap: wrap;
      gap: 30px;
      justify-content: center;
    }
  }
`
const Subtitle = styled.text`
  font-family: Anek Bangla;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
  color: ${COLOR.subtitle};
  @media (max-width: 900px) {
    font-size: 25px;
    line-height: normal;
  }
  @media (max-width: 575px) {
    font-size: 18px;
  }
`
const ListItem = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: center;
`
const Item = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 235ms ease-in-out;
  touch-action: manipulation;
  justify-content: center;
  @media (max-width: 425px) {
    max-width: 40px;
  }
  &:hover {
    transform: translate3d(0, 2px, 0);
  }
`
const Content = styled.text`
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  max-width: 550px;
  @media (max-width: 900px) {
    font-size: 16px;
    line-height: initial;
  }
  @media (max-width: 900px) {
    font-size: 14px;
    text-align: center;
  }
`
export const Crafting = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 })
  }, [])
  const collect = [
    {
      id: 1,
      tab: <HatIcon />,
      img: images.equipment,
      active: images.activeHat,
    },
    {
      id: 2,
      tab: <SwordIcon />,
      img: images.swordCollect,
      active: images.activeSword,
    },
  ]
  const list = [
    {
      id: 1,
      tab: <ForgeIcon />,
      title: 'FORGE',
      banner: images.train,
      active: images.activeForge,
      img: images.sword,
      description:
        'Take your weapons to the next level by using the Forge. Using $DARK and other materials you can craft and upgrade an assortment of weapon collectibles that will leave your enemies quivering.',
    },
    {
      id: 2,
      tab: <ArmoryIcon />,
      title: 'ARMORY',
      banner: images.armory,
      active: images.activeArmor,
      img: images.armor2,
      description:
        'Make yourself stand out by crafting and upgrading visually distinct armor pieces using the Armory. Creating your own unique individual look has never been easier. The only limit is your imagination.',
    },
  ]
  const [tab, setTab] = useState(1)
  const [collectTab, setCollectTab] = useState(1)
  const currentTab = useMemo(() => collect.find((item) => item.id === collectTab), [collectTab])
  const currentSlide = useMemo(() => list.find((item) => item.id === tab), [tab])
  return (
    <>
      <Utilize className="utilize">
        <div className="craft">
          <Container className="container">
            <TextBox className="textbox" data-aos="fade-up">
              <Flex gap="20px">
                <img src={images.tag} alt="" />
                <Title
                  className="title"
                  style={{
                    WebkitTextStroke: `3px ${COLOR.white}`,
                    WebkitTextStrokeWidth: '2px',
                    textShadow: `0px 5px 5px ${COLOR.bgTitleMarket}`,
                    color: '#030205',
                  }}
                >
                  LET THE CRAFTING BEGIN
                </Title>
                <img src={images.tag} alt="" />
              </Flex>

              <Desc className="desc" style={{ textAlign: 'center' }}>
                For the first time, players can craft weapons and armor cosmetics, from common all the way to unique.
              </Desc>
            </TextBox>
          </Container>
        </div>
        <Container className="container" data-aos="fade-up">
          <Utilize className="utilize__head head">
            <TextBox className="textbox">
              <Heading
                className="heading"
                style={{
                  WebkitTextStroke: `1px ${COLOR.bgTitleMarket}`,
                  WebkitTextStrokeWidth: '1px',
                  color: COLOR.white,
                }}
              >
                UTILIZE YOUR WORKSHOPS
              </Heading>
            </TextBox>

            <ListItem
              className="listitem"
              style={{
                marginTop: '20px',
              }}
            >
              {list.map((item) => (
                <div key={item.id} onClick={() => setTab(item.id)}>
                  <Item className="item">{tab === item.id ? <Image src={item.active} /> : item.tab}</Item>
                </div>
              ))}
            </ListItem>
          </Utilize>
          <Utilize className="utilize__mid">
            <WrapImage>
              <CustomImage src={currentSlide.banner} alt="" />
            </WrapImage>
            <Utilize>
              <TextBoxContent>
                <Subtitle>{currentSlide.title}</Subtitle>
                <Content>{currentSlide.description}</Content>
              </TextBoxContent>
              <div>
                <CustomImage src={currentSlide.img} alt="" />
              </div>
            </Utilize>
          </Utilize>
        </Container>
      </Utilize>

      <Utilize className="collectibles utilize">
        <Container className="container" data-aos="fade-up">
          <div className="collectibles__head head">
            <TextBox className="textbox">
              <Heading
                className="heading"
                style={{
                  WebkitTextStroke: `1px ${COLOR.bgTitleMarket}`,
                  WebkitTextStrokeWidth: '1px',
                  color: COLOR.white,
                }}
              >
                NEW COLLECTIBLES
              </Heading>
            </TextBox>
            <ListItem className="listitem">
              {collect.map((item) => (
                <div key={item.id} onClick={() => setCollectTab(item.id)}>
                  <Item className="item">{collectTab === item.id ? <Image src={item.active} /> : item.tab}</Item>
                </div>
              ))}
            </ListItem>
          </div>
          <div className="collectibles__banner wrapimage">
            <CustomImage src={currentTab.img} alt="" />
          </div>
        </Container>
      </Utilize>
    </>
  )
}
