const COLOR = {
  white: '#fff',
  black: '#000',
  bg: '#0a0a0b',
  red: 'red',
  boxShadow: 'rgba(255, 255, 255, 0.50)',
  bgMarket: '#6C7B81',
  divider: 'rgba(140, 140, 140, 0.28)',
  titleMarket: '#29809D',
  bgTitleMarket: '#40C9F7',
  shadowTitleMarket: 'rgba(193, 246, 253, 0.29)',
  id: '#f0f0f0',
  menu: '#74BBE8',
  shadowCard: 'rgba(255, 251, 244, 0.94)',
  idCard: '#1D6090',
  cardPrice: '#797575',
  borderDetail: '#8C8C8C',
  titleDetail: '#0A1E25',
  detailId: '#09366C',
  button: '#00f0ff',
  hoverBtt: '#01f0ff',
  hoverYellow: '#ebab1a',
  bottom: 'rgba(44, 36, 43, 0.6)',
  bttTextShadow: 'rgba(255, 255, 255, 0.25)',
  subtitle: '#75bdff',
  bgToken: '#454545',
  profileText: '#566181',
  profileShadow: 'rgba(108, 200, 210, 0.4)',
  bttTeam: '#33a1c6',
  teamShadow: 'rgba(63, 198, 244, 0.6)',
  wProduct: '#181a1a',
  hProduct: '#00000044',
  sProduct: ' #00000099',
  fixContain: 'rgba(0, 26, 47, 0.4)',
  active: 'rgb(255, 211, 42)',
  itemList: 'rgba(0, 0, 0, 0.02)',
  bgDropMenu: 'rgba(175, 137, 238, 0.2)',
  bttGetBox: '#00EFFF',
  bgButton: '#434A57',
  borderHero: '#89919C',
  shadowHero: '#9098A4',
  borderChampion: '#A78A56',
  shadowChampion: '#A98D57',
  borderWarrior: '#57B4D7',
  shadowWarrior: '#A8D5E7',
  gray: '#858584',
  transparent: 'rgba(59, 59, 59, 0.50)',
  bgAirdrop: '#041f55',
  bgBoxAirdrop: 'rgba(67, 74, 87, 0.80)',
  BoxAirdrop: '#3B3B3B',
  dividerModal: 'rgba(127, 167, 242, 0.10)',
  bttLinear: ' var(--b, linear-gradient(180deg, rgba(0, 161, 252, 0.00) 0%, #00C9FF 0.01%, #00F0FF 34.83%))',
  bttShadow: 'rgba(0, 161, 252, 0.00))',
  lime: '#00FF00',
  bgBoard: '#56585C',
  bgTop1: '#8191AA',
  bgTop2: '#757C87',
  bgTop3: '#747577',
  modalB: '#3c414c',
  borderModalB: '#8E6136',
}
export default COLOR
