import axios from 'axios'
const axiosClient = axios.create({
  withCredentials: true,
  baseURL: process.env.NEXT_PUBLIC_HOST,
  headers: {
    'content-type': 'application/json',
  },
})

axiosClient.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem('auth/token')

    if (!accessToken) return config

    config.headers.Authorization = `Bearer ${accessToken}`

    return config
  },
  (err) => Promise.reject(err),
)

export default axiosClient
