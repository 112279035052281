export type ProductType = {
  id: string
  img: string
  attack: number
  critical: number
  accuracy: number
  price: number
  all: AllType
  description?: string
}

export enum AllType {
  normal = 'Normal',
  rare = 'Rare',
  epic = 'Epic',
  legendary = 'Legendary',
  mythical = 'Mythical',
}
