import { Button, ButtonProps } from '@pancakeswap/uikit'
import { useWallet } from 'hooks/useWallet'
import styled from 'styled-components'
// @ts-ignore
// eslint-disable-next-line import/extensions
import { useActiveHandle } from 'hooks/useEagerConnect.bmp.ts'
import Trans from './Trans'

const StyledButton = styled.a`
  display: flex;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  background-image: url('/images/DarkKnight/button.png');
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-width: 350px;
  color: #00f0ff;
  text-shadow: 0px 2px 0px rgba(255, 255, 255, 0.25);
  font-family: Anek Bangla;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
  width: fit-content;
  white-space: nowrap;
  touch-action: manipulation;
  transition: all 235ms ease-in-out;
  &:hover {
    text-decoration: none;
    color: #01f0ff;
    transform: translate3d(0, 2px, 0);
    text-shadow: 0px 4px 4px #ebab1a;
  }
  @media (max-width: 767px) {
    padding: 10px 15px;
    font-size: 14px;
    min-width: 135px;
  }
`

const ConnectWalletButton = ({ children, ...props }: ButtonProps) => {
  const handleActive = useActiveHandle()
  const { onPresentConnectModal } = useWallet()

  const handleClick = () => {
    if (typeof __NEZHA_BRIDGE__ !== 'undefined') {
      handleActive()
    } else {
      onPresentConnectModal()
    }
  }

  return (
    <StyledButton onClick={handleClick} {...props}>
      {children || <Trans>Connect Wallet</Trans>}
    </StyledButton>
  )
}

export default ConnectWalletButton
