const images = {
  thumbnail: '/images/DarkKnight/Thumbnail.png',
  bgDetail: '/images/DarkKnight/bgDetail.png',
  bgDetail2: '/images/DarkKnight/bgDetail2.svg',
  bgHome: '/images/DarkKnight/Home2.png',
  bgInfo: '/images/DarkKnight/bgInfo.svg',
  bgInfo2: '/images/DarkKnight/bgInfo2.svg',
  access1: '/images/DarkKnight/access1.png',
  access2: '/images/DarkKnight/access2.png',
  access3: '/images/DarkKnight/access3.png',
  howAccess: '/images/DarkKnight/howtogetaccess.png',
  button: '/images/DarkKnight/button.png',
  buttonLarge: '/images/DarkKnight/buttonLarge.png',
  roadmap: '/images/DarkKnight/rD.png',
  roadmapMobile: '/images/DarkKnight/rM.png',
  equipment: '/images/DarkKnight/Equipment.png',
  activeHat: 'images/DarkKnight/activeHat.png',
  swordCollect: '/images/DarkKnight/SwordCollect.png',
  activeSword: 'images/DarkKnight/activeSword.png',
  train: '/images/DarkKnight/train.png',
  activeForge: 'images/DarkKnight/activeForge.png',
  sword: '/images/DarkKnight/sword.png',
  armory: '/images/DarkKnight/Armory.png',
  activeArmor: 'images/DarkKnight/activeArmor.png',
  armor2: '/images/DarkKnight/Armor2.png',
  boss: '/images/DarkKnight/boss.png',
  darkToken: '/images/DarkKnight/darkToken.svg',
  tokenomicTitle: '/images/DarkKnight/tknT.png',
  tokenomic: '/images/DarkKnight/tkn.png',
  tokenomicName: '/images/DarkKnight/TDN.png',
  tokenomicSupply: '/images/DarkKnight/TS.png',
  bannerDark: '/images/DarkKnight/darkN.png',
  tokenomicBanner: '/images/DarkKnight/TokenBanner.svg',
  tokenDark: '/images/DarkKnight/tokenDark.svg',
  dark: '/images/DarkKnight/$DARK.svg',
  leaderBoard: '/images/DarkKnight/leaderboard.png',
  mysteryBox: '/images/DarkKnight/mysteryBox.svg',
  logo: '/images/DarkKnight/logo.svg',
  profileCover: '/images/DarkKnight/profileCover.png',
  paperProfile: '/images/DarkKnight/paperProfile.png',
  avtFrame: '/images/DarkKnight/avtFrame.svg',
  walletIcon: '/images/DarkKnight/wallet.svg',
  darkIcon: '/images/DarkKnight/tokenDark.svg',
  myCollection: '/images/DarkKnight/myCollection.svg',
  bgCard: '/images/DarkKnight/bgCard.svg',
  bgCard2: '/images/DarkKnight/bgCard2.svg',
  attackIcon: '/images/DarkKnight/1.svg',
  criticalIcon: '/images/DarkKnight/2.svg',
  accuracyIcon: '/images/DarkKnight/3.svg',
  tokenDetailIcon: '/images/DarkKnight/tokenDetail.svg',
  checkIcon: '/images/DarkKnight/check.svg',
  openSeaIcon: 'images/DarkKnight/opensea.svg',
  dotIcon: 'images/DarkKnight/dot.svg',
  moreIcon: 'images/DarkKnight/more.svg',
  bgInput: '/images/DarkKnight/bginput.svg',
  bgButton: '/images/DarkKnight/bgbutton.png',
  load: '/images/DarkKnight/load.svg',
  cardButton: '/images/DarkKnight/cardButton.svg',
  bttBuy: '/images/DarkKnight/buttonBuy.png',
  bttOffer: '/images/DarkKnight/buttonOffer.svg',
  bttDetail: '/images/DarkKnight/buttonDetail.svg',
  bgTitle: '/images/DarkKnight/bgTitle.svg',
  bttTeam: '/images/DarkKnight/teamButton.png',
  bttPD: '/images/DarkKnight/buttonPD.png',
  bttSale: '/images/DarkKnight/saleButton.png',
  warriorBox: '/images/DarkKnight/warriorBox.png',
  heroBox: '/images/DarkKnight/heroBox.png',
  championBox: '/images/DarkKnight/championBox.png',
  boxMystery: '/images/DarkKnight/boxMystery.png',
  bannerGetBox: '/images/DarkKnight/bannerGetBox.png',
  fairy: '/images/DarkKnight/fairy.png',
  sakura: '/images/DarkKnight/sakura.png',
  rebirth: '/images/DarkKnight/rebirth.png',
  purgatory: '/images/DarkKnight/purgatory.png',
  m1: '/images/DarkKnight/m1.png',
  m2: '/images/DarkKnight/m2.png',
  m3: '/images/DarkKnight/m3.png',
  eyeIcon: '/images/DarkKnight/Eye.svg',
  pacmanIcon: '/images/DarkKnight/pacman.svg',
  bgFooter: '/images/DarkKnight/Footer.png',
  bigAirdrop: '/images/DarkKnight/bigAirdrop.png',
  checkAirdropIcon: '/images/DarkKnight/checkAirdrop.svg',
  unCheckAirdropIcon: '/images/DarkKnight/unCheckAirdrop.svg',
  tokenAirdrop: '/images/DarkKnight/tokenAirdrop.png',
  chest: '/images/DarkKnight/chestAirdrop.png',
  bannerAirdrop: '/images/DarkKnight/bannerAirdrop.png',
  successIcon: '/images/DarkKnight/success.svg',
  errorIcon: '/images/DarkKnight/error.svg',
  approve: '/images/DarkKnight/approve.svg',
  hideIcon: '/images/DarkKnight/Hide.svg',
  rightIcon: '/images/DarkKnight/rightIcon.svg',
  leftIcon: '/images/DarkKnight/leftIcon.svg',
  bannerLeaderBoard: '/images/DarkKnight/bannerLeaderBoard.png',
  sectionLeaderBoard: '/images/DarkKnight/sectionLeaderBoard.png',
  bttTop: '/images/DarkKnight/bttTop.svg',
  navLeaderBoard: '/images/DarkKnight/navLB.png',
  top1: '/images/DarkKnight/iconTop1.png',
  top2: '/images/DarkKnight/iconTop2.png',
  top3: '/images/DarkKnight/iconTop3.png',
  backBtt: '/images/DarkKnight/boardBack.svg',
  nextBtt: '/images/DarkKnight/boardNext.svg',
  powerIcon: '/images/DarkKnight/power.png',
  timeIcon: '/images/DarkKnight/online.png',
  levelIcon: '/images/DarkKnight/level.png',
  heroSO: '/images/DarkKnight/heroSO.png',
  warriorSO: '/images/DarkKnight/warriorSO.png',
  championSO: '/images/DarkKnight/championSO.png',
  tag: '/images/DarkKnight/tag.svg',
  bgConnectApp: '/images/DarkKnight/bgConnectApp1.png',
}

export default images
