export type BoxType = {
  img: string
  type: string
  all: BoxAllType
  border: string
  shadow: string
  name: string
  desc: string
}

export enum BoxAllType {
  hero = 'Hero',
  champion = 'Champion',
  warrior = 'Warrior',
}
