import { Box, Button, Flex, InjectedModalProps, LinkExternal, Message, Skeleton, Text } from '@pancakeswap/uikit'
import { ChainId } from '@pancakeswap/sdk'
import { FetchStatus } from 'config/constants/types'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useTranslation } from '@pancakeswap/localization'
import useAuth from 'hooks/useAuth'
import useNativeCurrency from 'hooks/useNativeCurrency'
import { useGetCakeBalance } from 'hooks/useTokenBalance'
import { ChainLogo } from 'components/Logo/ChainLogo'

import { getBlockExploreLink, getBlockExploreName } from 'utils'
import { formatBigNumber } from 'utils/formatBalance'
import { useBalance } from 'wagmi'
import CopyAddress from './CopyAddress'
import Image from 'next/image'
import COLOR from 'constant/COLOR'
import images from 'constant/PICTURE/images'

interface WalletInfoProps {
  hasLowNativeBalance: boolean
  onDismiss: InjectedModalProps['onDismiss']
}

const WalletInfo: React.FC<WalletInfoProps> = ({ hasLowNativeBalance, onDismiss }) => {
  const { t } = useTranslation()
  const { account, chainId } = useActiveWeb3React()
  const { data, isFetched } = useBalance({ addressOrName: account })
  const native = useNativeCurrency()
  const { balance: cakeBalance, fetchStatus: cakeFetchStatus } = useGetCakeBalance()
  const { logout } = useAuth()

  const handleLogout = () => {
    onDismiss?.()
    logout()
  }

  const isBSC = native.chainId === ChainId.BSC
  const shortAccount = `${account.substring(0, 4)}...${account.substring(account.length - 10)}`

  return (
    <>
      <Text
        style={{ color: COLOR.white, fontFamily: 'Anek Bangla' }}
        fontSize="20px"
        textTransform="uppercase"
        fontWeight="bold"
        mb="8px"
      >
        {t('Your Address')}
      </Text>
      <CopyAddress style={{ stroke: COLOR.bttGetBox }} account={shortAccount} mb="24px" />
      {hasLowNativeBalance && (
        <Message variant="warning" mb="24px">
          <Box>
            <Text fontWeight="bold" style={{ color: COLOR.white, fontWeight: 700 }}>
              {t('%currency% Balance Low', {
                currency: native.symbol,
              })}
            </Text>
            <Text as="p" style={{ color: COLOR.white, fontWeight: 700 }}>
              {t('You need %currency% for transaction fees.', {
                currency: native.symbol,
              })}
            </Text>
          </Box>
        </Message>
      )}
      <Flex alignItems="center" justifyContent="space-between">
        <Flex>
          {!isBSC && <ChainLogo chainId={native.chainId} />}
          <Text ml={isBSC ? 0 : '8px'} style={{ color: COLOR.white, fontWeight: 700 }}>
            {native.symbol} {t('Balance')}
          </Text>
        </Flex>
        {isFetched && data && data.value ? (
          <Text style={{ color: COLOR.white, fontWeight: 700 }}>{formatBigNumber(data.value, 6)}</Text>
        ) : (
          0
        )}
      </Flex>
      <Flex margin="10px 0" style={{ justifyContent: 'space-between' }}>
        <Flex>
          <img style={{ width: '30px' }} src={images.darkIcon} />

          <Text ml={isBSC ? 0 : '8px'} style={{ color: COLOR.white, fontWeight: 700 }}>
            DARK {t('Balance')}
          </Text>
        </Flex>
        {isFetched && data && data.value ? <Text style={{ color: COLOR.white, fontWeight: 700 }}>0</Text> : 0}
      </Flex>

      {/* <Flex alignItems="center" justifyContent="space-between" mb="24px" mt="12px">
        <Flex alignItems="center">
          {!isBSC && <Image src="/images/V3/Logosocial.png" width={24} height={24} alt="" />}
          <Text ml={isBSC ? 0 : '8px'} color="textSubtle">
            {t('TREND Balance')}
          </Text>
        </Flex>
        {cakeFetchStatus !== FetchStatus.Fetched ? (
          <Skeleton height="22px" width="60px" />
        ) : (
          <Text>{formatBigNumber(cakeBalance, 3)}</Text>
        )}
      </Flex> */}
      <Flex alignItems="center" justifyContent="end" mb="24px">
        <LinkExternal style={{ color: COLOR.white }} href={getBlockExploreLink(account, 'address', chainId)}>
          {t('View on %site%', {
            site: getBlockExploreName(chainId),
          })}
        </LinkExternal>
      </Flex>
      <Button
        style={{
          backgroundImage: `url(${images.button})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          border: 'none',
        }}
        variant="secondary"
        width="100%"
        onClick={handleLogout}
      >
        <Text
          style={{
            fontWeight: 600,
            color: COLOR.button,
          }}
        >
          {t('Disconnect Wallet')}
        </Text>
      </Button>
    </>
  )
}

export default WalletInfo
