import { Flex, Stack, Text } from '@chakra-ui/react'
import 'aos/dist/aos.css'
import CustomSlide from 'components/Slide'
import COLOR from 'constant/COLOR'
import images from 'constant/PICTURE/images'
import useResponsive from 'hooks/useResponsive'
import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import Section1 from './Section1'
import Section2 from './Section2'
const Wrapper = styled.div`
  font-style: normal;
  overflow: hidden;
  background: ${COLOR.bg};
  background-image: url(${images.bgHome});
  background-position: center;
  background-repeat: repeat;
  background-size: contain;
`

const data = [
  {
    title: 'Normal 50%',
  },
  {
    title: 'Rare 30%',
  },
  {
    title: 'Epic 15%',
  },
  {
    title: 'Legendary 4.9%',
  },
  {
    title: 'Mythic 0.1%',
  },
]
const slides = [
  {
    name: 'HERO BOX',
    image: `${images.heroBox}`,
    price: '$50',
    dark: '50.000 $DARK',
    description: '100% GET 50xx $DARK',
    itemsChar: {
      knight: '(1%)',
      archer: '(1%)',
    },
    items: {
      eLv1: '(40%)',
      elv2: '(10%)',
      elv3: '(10%)',
      HP: '(10%)',
      MP: '(10%)',
      citrine: '(4%)',
      amethyst: '(4%)',
      ruby: '(4%)',
      charmX2: '(3%)',
      charmX3: '(2%)',
      colorStone: '(1%)',
    },
    normal: '50%',
    rare: '30%',
    epic: '15%',
    legendary: '4.9%',
    mythic: '0.1%',
    character: '(2%)',
    item: '(98%)',
  },
  {
    name: 'WARRIOR BOX',
    image: `${images.championBox}`,
    price: '$100',
    dark: '100.000 $DARK',
    description: '100% GET 100xx $DARK',
    itemsChar: {
      knight: '(20%)',
      archer: '(20%)',
    },
    items: {
      eLv1: '(10%)',
      elv2: '(10%)',
      elv3: '(10%)',
      HP: '(10%)',
      MP: '(10%)',
      citrine: '(5%)',
      amethyst: '(5%)',
      ruby: '(4%)',
      charmX2: '(3%)',
      charmX3: '(2%)',
      colorStone: '(1%)',
    },
    normal: '50%',
    rare: '30%',
    epic: '15%',
    legendary: '4.9%',
    mythic: '0.1%',
    character: '(40%)',

    item: '(60%)',
  },
  {
    name: 'CHAMPION BOX',
    image: `${images.warriorBox}`,
    price: '$200',
    dark: '200.000 $DARK',
    description: '100% GET 200xx $DARK',
    itemsChar: {
      knight: '(40%)',
      archer: '(40%)',
    },
    items: {
      eLv1: '',
      elv2: '',
      elv3: '',
      HP: '',
      MP: '',
      citrine: '5%)',
      amethyst: '(5%)',
      ruby: '(4%)',
      charmX2: '(3%)',
      charmX3: '(2%)',
      colorStone: '(1%)',
    },
    normal: '50%',
    rare: '30%',
    epic: '15%',
    legendary: '4.9%',
    mythic: '0.1%',
    character: '(80%)',

    item: '(20%)',
  },
]

const GetBox: React.FC<React.PropsWithChildren> = () => {
  const { isMobile, isMobileOrTablet, isTabletOrLaptop, isBigScreen } = useResponsive()

  const link = window.location.href

  const reviewBox = useCallback(() => {
    if (link.indexOf('GetBox') !== -1) {
      window.location.href = '#BoxStore'
      window.history.replaceState(null, null, window.location.href)
    }
  }, [link])

  useEffect(() => {
    reviewBox()
  }, [reviewBox])

  return (
    <>
      <Wrapper>
        <Section1 />
        <Stack
          justifyContent="center"
          alignItems="center"
          p={isMobile ? '50px 20px' : '50px 0'}
          gap="30px"
          sx={{
            backgroundImage: `url(${images.bannerGetBox})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <Text color={COLOR.white} fontSize={isMobileOrTablet ? '40px' : '51px'} fontWeight={600} textAlign="center">
            Mystery Boxes
          </Text>
          <Flex
            direction={isMobile ? 'column' : 'row'}
            borderRadius="20px"
            border={`1px solid ${COLOR.bttGetBox}`}
            bg={COLOR.transparent}
            p={isMobileOrTablet ? '30px 10px ' : '20px'}
            justifyContent="space-between"
            w={isMobileOrTablet ? '100%' : '60%'}
          >
            <CustomSlide slides={slides} />
          </Flex>
        </Stack>
        <Section2 />
      </Wrapper>
    </>
  )
}

export default GetBox
