import React from 'react'
import styled from 'styled-components'
import { useMatchBreakpoints } from '../../../../packages/uikit/src/contexts'
import { Link } from 'react-router-dom'
import color from 'configs/color'
import {
  Button,
  Divider,
  Flex,
  Input,
  Menu,
  MenuButton,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import COLOR from 'constant/COLOR'
const Wrapper = styled.div`
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 10px 0;
  background: #252525;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.2);
  padding-bottom: env(safe-area-inset-bottom);
  html[data-useragent*='TokenPocket_iOS'] & {
    padding-bottom: 45px;
  }
  z-index: 20;
  display: flex;
  justify-content: space-around;
`
const StyledBottomNavItem = styled.button`
  display: flex;
  border: 0;
  justify-content: center;
  background: transparent;
  cursor: pointer;
  height: 44px;
  gap: 10px;
  padding: 0 10px;
  &:hover {
    border-radius: 16px;
  }
  &:hover,
  &:hover div {
    background: #353547;
    border-radius: 12px;
  }
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
`
const StyledBottomNavText = styled(Text)`
  height: 41px;
  padding: 7px 14px;
  display: -webkit-box;
  border-radius: 30px;
  border: 1px solid rgba(250, 250, 250, 0.25);
  background: #252525;
  box-shadow: 2px 2px 0px 0px rgba(255, 255, 255, 0.5);
  overflow: hidden;
  user-select: none;
  color: #fafafa;
  font-family: Anek Bangla;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
`
const BottomNavV2 = ({ data, items, activeItem, activeSubItem }) => {
  const { isMobile, isTablet } = useMatchBreakpoints()
  const [dropDown, setDropDown] = React.useState(false)
  const getDropDown = (i) => {
    if (i > 0) {
      setDropDown(!dropDown)
    } else {
      setDropDown(false)
    }
  }
  const handleLabelClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
  return (
    <Wrapper>
      {data.map((items, index) => (
        <div key={index}>
          <Link
            style={{ display: items.label === '' || items.label === 'LEADERBOARD' ? 'none' : 'flex' }}
            to={items.link}
            onClick={() => getDropDown(items.dropdownMenu.length)}
          >
            <StyledBottomNavItem
              onClick={handleLabelClick}
              style={{ display: items.label === '/' || items.label === 'Roadmap' ? 'none' : 'flex' }}
            >
              {/* <img src={items.img} /> */}
              <StyledBottomNavText>{items.label}</StyledBottomNavText>
            </StyledBottomNavItem>
          </Link>

          {items.dropdownMenu.length > 0 && dropDown === true && (
            <Modal onClose={() => setDropDown(false)} isOpen={dropDown} isCentered>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>GAME</ModalHeader>
                <ModalCloseButton />
                <Divider />
                <ModalBody>
                  <Text>
                    <Stack>
                      {items.dropdownMenu.map((i, index) => (
                        <a key={index} href={i.href}>
                          {i.label}
                        </a>
                      ))}
                    </Stack>
                  </Text>
                </ModalBody>
              </ModalContent>
            </Modal>
          )}
        </div>
      ))}
    </Wrapper>
  )
}
export default BottomNavV2
