const API_ROUTES = {
  user: {
    subscribes: '/subscribe',
    me: '/me',
    airdrop: '/users/airdrop',
    listAirdrop: '/airdrop',
  },
  twitter: {
    connect: '/twitter/connect',
    authConnect: '/twitter/authenticate',
    logout: '/twitter/logout',
    info: '/twitter',
    airdrop: 'twitter/airdrop',
  },
  cards: {
    card: '/cards',
  },
  leaderBoard: {
    board: '/leaderboard',
  },
}

export default API_ROUTES
