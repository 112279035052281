import { Button, Flex, Input, Modal, ModalBody, ModalCloseButton, ModalContent, Stack, Text } from '@chakra-ui/react'
import { useToast } from '@pancakeswap/uikit'
import 'aos/dist/aos.css'
import CircleLoader from 'components/Loader/CircleLoader'
import ProgressBar from 'components/ProgressBar'
import { ToastDescriptionWithTx } from 'components/Toast'
import boxAbi from 'config/abi/box.json'
import mintBoxAbi from 'config/abi/mintBox.json'
import contracts from 'config/constants/contracts'
import APP_ROUTES from 'constant/APP_ROUTES'
import COLOR from 'constant/COLOR'
import images from 'constant/PICTURE/images'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useApproveConfirmTransaction from 'hooks/useApproveConfirmTransaction'
import useBuyConfirmTransaction from 'hooks/useBuyConfirmTransaction'
import useResponsive from 'hooks/useResponsive'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { getContract } from 'utils/contractHelpers'
import { useBalance, useSigner } from 'wagmi'
import { ChainId } from '../../../packages/swap-sdk/src/constants'
import { DataBox } from './DataBox'
import { ethers } from 'ethers'
import { numeral } from 'numeral'
import { ChainLogo } from 'components/Logo/ChainLogo'

import styled, { keyframes } from 'styled-components'
import BigNumber from 'bignumber.js'
import { getFullDecimalMultiplier } from 'utils/getFullDecimalMultiplier'
import useNativeCurrency from 'hooks/useNativeCurrency'
import { formatBigNumber } from 'utils/formatBalance'
const mover = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
`

const AnimatedImage = styled.img`
  cursor: pointer;
  // transition: transform 0.5s;

  &:hover {
    animation: ${mover} 1s infinite alternate;
    transform: translateY(-20px);
  }
`
const GlowingStack = styled(Stack)`
  border: 5px solid ${(props) => props.theme.colors.bronze};
  border-radius: 20px;
  box-shadow: 0px 0px 10px 0px ${COLOR.shadowHero};

  transition: border-color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    border-color: ${COLOR.bttGetBox};
    box-shadow: 0px 0px 20px 0px ${COLOR.bttGetBox};
  }
`
const BoxStore: React.FC<React.PropsWithChildren> = () => {
  const MAX_UNIT = '115792089237316195423570985008687907853269984665640564039457584007913129639935'
  const { account, chainId } = useActiveWeb3React()
  const CHAIN_ID = chainId === undefined ? ChainId.BSC_TESTNET : chainId
  const { isMobile, isMobileOrTablet, isTabletOrLaptop, isBigScreen } = useResponsive()
  const { data: signer } = useSigner()
  const [isModalOpen, setModalOpen] = useState(false)
  const [selectedBox, setSelectedBox] = useState(null)
  const totalBoxes = 1000
  const [amounts, setAmounts] = useState(1)
  const [loading, setLoading] = useState(false)
  const [isApprove, setIsApprove] = useState(false)
  const { toastSuccess, toastError } = useToast()
  const [boxRemain, setBoxRemain] = useState([])
  const [boxPrice, setBoxPrice] = useState(0)
  const [open, setOpen] = useState('flex')
  const [openCountdown, setOpenCountDown] = useState('none')
  const [isDisable, setIsDisable] = useState(false)
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)
  const [days, setDays] = useState(0)
  const [corePrice, setCorePrice] = useState([])
  const native = useNativeCurrency()
  const { data, isFetched } = useBalance({ addressOrName: account })
  const isBSC = native.chainId === ChainId.BSC
  const timeStamp = Math.floor(Date.now() / 1000)
  // let countDown = useRef(null)
  // useEffect(() => {
  //   let handler = (e) => {
  //     if (!countDown.current?.contains(e.target)) {
  //       setOpen('none')
  //     }
  //   }

  //   document.addEventListener('mousedown', handler)

  //   return () => {
  //     document.removeEventListener('mousedown', handler)
  //   }
  // })

  // useEffect(() => {
  //   let countdown = 1706533200 - timeStamp

  //   const updateCountdown = () => {
  //     setHours(Math.floor((countdown % 86400) / 3600))
  //     setMinutes(Math.floor((countdown % 3600) / 60))
  //     setSeconds(countdown % 60)
  //   }

  //   const timer = setInterval(() => {
  //     if (countdown > 0) {
  //       updateCountdown()
  //       countdown--
  //     } else {
  //       clearInterval(timer)
  //       setOpen('none')
  //       setOpenCountDown('flex')
  //       setIsDisable(false)
  //     }
  //   }, 1000)

  //   return () => {
  //     clearInterval(timer)
  //   }
  // }, [timeStamp, open])
  const getBox = getContract({
    address: contracts.box[CHAIN_ID],
    abi: boxAbi,
    chainId: CHAIN_ID,
    signer,
  })
  const mintBox = getContract({
    address: contracts.mintBox[CHAIN_ID],
    abi: mintBoxAbi,
    chainId: CHAIN_ID,
    signer,
  })
  const getRemain = async () => {
    const arr = [1, 2, 3]
    const data = arr.map(async (i) => {
      const remain = await mintBox.remain(i)
      return { remain }
    })
    const newData = await Promise.all(data)
    setBoxRemain(newData)
  }

  const getPriceCoreBox = async () => {
    const priceCore = await Promise.all(
      DataBox.map((item) => mintBox.USD2CORE(new BigNumber(item.price).times(getFullDecimalMultiplier(18)).toString())),
    )
    setCorePrice(priceCore)
  }
  const getPriceBox = async (r) => {
    const priceBox = await mintBox.prices(r)
    const priceBox2USD = await mintBox.USD2CORE(priceBox.toString())
    setBoxPrice(priceBox2USD.toString())
  }
  // const checkApprove = async () => {
  //   if (account) {
  //     const isAllowed = await getBox.isApprovedForAll(account, contracts.mintBox[CHAIN_ID])

  //     if (isAllowed === true) {
  //       setIsApprove(true)
  //     } else {
  //       setIsApprove(false)
  //     }
  //   }
  // }

  // const { handleApprove } = useApproveConfirmTransaction({
  //   onRequiresApproval: async () => {
  //     return Promise.resolve(true)
  //   },
  //   onApprove: () => {
  //     setLoading(true)
  //     return getBox.setApprovalForAll(contracts.mintBox[CHAIN_ID], MAX_UNIT)
  //   },
  //   onApproveSuccess: async ({ receipt }) => {
  //     toastSuccess('Approve Successfully ', <ToastDescriptionWithTx txHash={receipt.transactionHash} />)
  //     checkApprove()
  //     setLoading(false)
  //   },
  //   onConfirm: async (receipt) => {
  //     return receipt
  //   },
  //   onSuccess: async ({ receipt }) => {
  //     setLoading(false)
  //     return Promise.resolve(1)
  //   },
  // })
  const { handleBuy } = useBuyConfirmTransaction({
    onRequiresBuy: async () => {
      return Promise.resolve(true)
    },
    onBuy: async () => {
      setLoading(true)

      const buy = Number(boxPrice) * amounts
      return mintBox.mint(selectedBox + 1, amounts, {
        value: buy.toString(),
      })
    },
    onBuySuccess: async ({ receipt }) => {
      toastSuccess('Purchase Successfully ', <ToastDescriptionWithTx txHash={receipt.transactionHash} />)
      getRemain()
      setModalOpen(false)
      setLoading(false)
    },
    onConfirm: (receipt) => {
      return receipt
    },
    onSuccess: async ({ receipt }) => {
      setLoading(false)
      return Promise.resolve(1)
    },
  })

  const handleChange = async (event) => {
    const min = 0
    const max = 1000
    const limit = Math.max(min, Math.min(max, Number(event)))
    setAmounts(limit)
  }

  const handleBoxClick = (index) => {
    setSelectedBox(index)
    setModalOpen(true)
    getPriceBox(index + 1)
  }

  const closeModal = () => {
    setSelectedBox(null)
    setModalOpen(false)
  }
  const handleLabelClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
  useEffect(() => {
    // checkApprove()
    getPriceCoreBox()
    getRemain()
  }, [account])
  return (
    <>
      <Stack justifyContent="center" gap="30px">
        <Text
          textTransform="uppercase"
          color={COLOR.white}
          fontFamily="Anek Bangla"
          fontSize="38px"
          fontWeight={600}
          textAlign="center"
        >
          Box Store
        </Text>
        <Text
          color={COLOR.white}
          fontFamily="Anek Bangla"
          fontSize={isMobile ? '20px' : '25px'}
          fontWeight={400}
          textAlign="center"
        >
          Estimated minimum and maximum Mystery Box value may vary due to changes in $DARK and entry ticket price.
        </Text>
        <Flex justifyContent="center" gap="30px" direction={isMobileOrTablet ? 'column' : 'row'}>
          {DataBox.map((i, index) => (
            <GlowingStack
              key={index}
              bg={COLOR.bgButton}
              border={`5px solid ${i.border}`}
              borderRadius="20px"
              boxShadow={`0px 0px 10px 0px ${i.shadow}`}
              p="30px"
            >
              <Stack justifyContent="center" alignItems="center" gap="10px">
                {Number(boxRemain[index]?.remain.toString()) === 0 ? (
                  <AnimatedImage src={i.soldOut} alt="" />
                ) : (
                  <AnimatedImage src={i.img} alt="" />
                )}
                {boxRemain.length > 0 && (
                  <ProgressBar current={Number(boxRemain[index].remain.toString()) || 0} total={totalBoxes} />
                )}

                <Text color={COLOR.white} fontFamily="Anek Bangla" fontSize="22px" fontWeight={600} textAlign="center">
                  {i.name}
                </Text>

                <Flex alignItems="center" gap="10px">
                  <Text fontFamily="Anek Bangla" fontWeight={600} fontSize="22px" color={COLOR.white}>
                    {corePrice.length > 0 && (
                      <>
                        $ {i.price} ~ {Number(ethers.utils.formatEther(corePrice[index])).toFixed(2)} CORE
                      </>
                    )}
                  </Text>
                </Flex>
              </Stack>
              <Flex alignItems="start" gap="10px" justifyContent="space-between">
                <Text fontFamily="Anek Bangla" fontWeight={400} fontSize="18px" color={COLOR.white} opacity={0.7}>
                  Max Supply
                </Text>
                <Text fontFamily="Anek Bangla" fontWeight={600} fontSize="18px" color={COLOR.white}>
                  {totalBoxes}
                </Text>
              </Flex>{' '}
              <Flex alignItems="start" gap="10px" justifyContent="space-between">
                <Text fontFamily="Anek Bangla" fontWeight={400} fontSize="18px" color={COLOR.white} opacity={0.7}>
                  Total sold
                </Text>
                <Text fontFamily="Anek Bangla" fontWeight={600} fontSize="18px" color={COLOR.white}>
                  {boxRemain.length > 0 && totalBoxes - Number(boxRemain[index].remain.toString())}
                </Text>
              </Flex>
              {isDisable || Number(boxRemain[index]?.remain.toString()) === 0 ? (
                <Button
                  transition="all 235ms ease-in-out"
                  cursor="not-allowed"
                  filter="grayscale(100%)"
                  p="0 30px"
                  h="44px"
                  borderRadius="20px"
                  border={`2px solid ${COLOR.bttGetBox}`}
                  bg={COLOR.bgButton}
                  color={COLOR.bttGetBox}
                  _hover={{
                    bg: 'none',
                    transform: 'translate3d(0, 2px, 0)',
                  }}
                >
                  <Text color={COLOR.bttGetBox} fontFamily="Anek Bangla" fontSize="18px" fontWeight={600}>
                    Buy Now
                  </Text>
                </Button>
              ) : (
                <>
                  {account ? (
                    <Stack justifyContent="center" alignItems="center">
                      <Button
                        onClick={() => handleBoxClick(index)}
                        transition="all 235ms ease-in-out"
                        p="0 30px"
                        h="44px"
                        borderRadius="20px"
                        border={`2px solid ${COLOR.bttGetBox}`}
                        bg={COLOR.bgButton}
                        color={COLOR.bttGetBox}
                        _hover={{
                          bg: 'none',
                          transform: 'translate3d(0, 2px, 0)',
                        }}
                      >
                        <Text color={COLOR.bttGetBox} fontFamily="Anek Bangla" fontSize="18px" fontWeight={600}>
                          Buy Now
                        </Text>
                      </Button>
                    </Stack>
                  ) : (
                    <Text
                      color={COLOR.bttGetBox}
                      fontFamily="Anek Bangla"
                      fontSize="13px"
                      fontWeight={600}
                      textAlign="center"
                    >
                      You need to connect your wallet to buy box
                    </Text>
                  )}
                </>
              )}
            </GlowingStack>
          ))}
          {selectedBox !== null && (
            <Modal isOpen={isModalOpen} onClose={closeModal} isCentered>
              {/* <ModalOverlay /> */}
              <ModalContent
                bg={COLOR.bgButton}
                borderRadius="20px"
                border={`5px solid ${DataBox[selectedBox].border}`}
                boxShadow={`0px 0px 10px 0px ${COLOR.shadowHero}`}
                containerProps={{ paddingTop: isMobile ? '20rem' : 'none' }}
              >
                <ModalCloseButton
                  sx={{
                    color: COLOR.white,
                  }}
                />
                <ModalBody justifyContent="center" display="flex" flexDirection="column" p="40px" gap="10px">
                  <Stack alignItems="center">
                    <Text
                      sx={{
                        color: COLOR.white,
                      }}
                      fontFamily="Anek Bangla"
                      fontWeight={600}
                      fontSize="32px"
                    >
                      {DataBox[selectedBox].name}
                    </Text>
                    {/* <img style={{ width: '150px' }} src={DataBox[selectedBox].img} alt="" /> */}
                    <Flex alignItems="center" gap="10px">
                      {/* <img style={{ width: '30px' }} src={images.darkIcon} alt="" />
                       */}
                      <Text
                        fontFamily="Anek Bangla"
                        fontWeight={600}
                        fontSize="25px"
                        sx={{
                          color: COLOR.white,
                        }}
                      >
                        {corePrice.length > 0 && (
                          <>
                            $ {DataBox[selectedBox].price} ~
                            {amounts * +Number(ethers.utils.formatEther(corePrice[selectedBox])).toFixed(2)} CORE
                          </>
                        )}
                      </Text>
                    </Flex>
                    <Text fontWeight="bold" style={{ color: COLOR.white, fontWeight: 700 }}>
                      <Flex alignItems="center" justifyContent="space-between" gap="10px">
                        <Flex>
                          {!isBSC && <ChainLogo chainId={native.chainId} />}
                          <Text ml={isBSC ? 0 : '8px'} style={{ color: COLOR.white, fontWeight: 700 }}>
                            {native.symbol}
                          </Text>
                        </Flex>
                        {isFetched && data && data.value ? (
                          <Text style={{ color: COLOR.white, fontWeight: 700 }}>{formatBigNumber(data.value, 2)}</Text>
                        ) : (
                          0
                        )}
                      </Flex>
                    </Text>
                  </Stack>
                  <Flex justifyContent="space-between">
                    <Text
                      sx={{
                        color: COLOR.white,
                      }}
                      fontFamily="Anek Bangla"
                      fontWeight={600}
                      fontSize="22px"
                    >
                      Quantity:
                    </Text>

                    <Input
                      w="40%"
                      sx={{
                        color: COLOR.white,
                      }}
                      type="number"
                      value={amounts === 0 ? '1-100' : amounts}
                      onChange={(e) => {
                        handleChange(e.target.value)
                      }}
                    />
                  </Flex>
                  <Stack justifyContent="flex-end" alignItems="flex-end" mr="10px">
                    {Number(ethers.utils.formatEther(data.value)) <
                      amounts * +Number(ethers.utils.formatEther(corePrice[selectedBox])).toFixed(2) && (
                      <Text
                        sx={{
                          fontSize: '10px',
                          color: COLOR.red,
                        }}
                      >
                        You don&apos;t have enough Core
                      </Text>
                    )}
                  </Stack>
                  {loading === true ? (
                    <Stack justifyContent="center" alignItems="center">
                      <CircleLoader size="50px" />
                    </Stack>
                  ) : Number(ethers.utils.formatEther(data.value)) <
                    amounts * +Number(ethers.utils.formatEther(corePrice[selectedBox])).toFixed(2) ? (
                    <a href="https://www.okx.com/vi/trade-spot/core-usdt">
                      <Button
                        borderRadius="8px"
                        background={COLOR.bttLinear}
                        color={COLOR.white}
                        border={`1px solid ${COLOR.bttShadow}`}
                        w="full"
                        mt="10px"
                        _hover={{ bg: COLOR.bttShadow }}
                      >
                        Get Core
                      </Button>
                    </a>
                  ) : (
                    <Button
                      onClick={() => {
                        if (amounts < 1) {
                          toastError('Error', 'Minimum is 1')
                        } else handleBuy()
                      }}
                      // onClick={handleBuy}
                      borderRadius="8px"
                      background={COLOR.bttLinear}
                      color={COLOR.white}
                      border={`1px solid ${COLOR.bttShadow}`}
                      w="full"
                      mt="10px"
                      _hover={{ bg: COLOR.bttShadow }}
                    >
                      Buy Now
                    </Button>
                  )}
                </ModalBody>
              </ModalContent>
            </Modal>
          )}
        </Flex>
        <Stack>
          {account && (
            <Link to={APP_ROUTES.userBox}>
              <Stack justifyContent="center" alignItems="center">
                <Button
                  onClick={handleLabelClick}
                  p="0 50px"
                  h="60px"
                  borderRadius="20px"
                  border={`1px solid ${COLOR.bttGetBox}`}
                  bg={COLOR.bgButton}
                  color={COLOR.bttGetBox}
                  _hover={{ bg: 'none' }}
                >
                  <Text color={COLOR.bttGetBox} fontFamily="Anek Bangla" fontSize="18px" fontWeight={600}>
                    My Box
                  </Text>
                </Button>
              </Stack>
            </Link>
          )}
        </Stack>
      </Stack>
    </>
  )
}

export default BoxStore
