import { useMatchBreakpoints } from '@pancakeswap/uikit/src/contexts'
import Aos from 'aos'
import 'aos/dist/aos.css'
import CustomImage from 'components/CustomSkeleton/CustomImage'
import COLOR from 'constant/COLOR'
import images from 'constant/PICTURE/images'
import { useEffect } from 'react'
import styled from 'styled-components'

const RoadmapWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 45px;
  overflow: hidden;
  @media (max-width: 575px) {
    padding: 25px 0;
    gap: 20px;
  }
`

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 45px;
  color: ${COLOR.white};
  text-align: center;

  .heading {
    font-family: Anek Bangla;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
  @media (max-width: 900px) {
    gap: 20px;
    .heading {
      font-size: 25px;
    }
  }
  @media (max-width: 575px) {
    .heading {
      font-size: 20px;
    }
`

const Title = styled.text`
  // border-bottom: 1px solid ${COLOR.white};
  color: #030205;

  font-family: Russo One;
  font-size: 65px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  @media (max-width: 900px) {
    font-size: 30px;
    line-height: normal;
  }
  @media (max-width: 575px) {
    font-size: 30px;
  }
`

const Banner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: transform 330ms ease-in-out;
  &:hover {
    transform: translateY(-25px);
  }
  @media (max-width: 900px) {
    &:hover {
      transform: none;
    }
  }
`

export const Roadmap = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 })
  }, [])
  const { isMobile } = useMatchBreakpoints()

  return (
    <>
      <section>
        <RoadmapWrap data-aos="fade-up">
          <TextBox>
            <Title
              style={{
                WebkitTextStroke: `3px ${COLOR.white}`,
                WebkitTextStrokeWidth: '2px',
                textShadow: `0px 5px 5px ${COLOR.bgTitleMarket}`,
              }}
            >
              Roadmap
            </Title>
          </TextBox>
          <Banner>
            <CustomImage loading="lazy" src={isMobile ? images.roadmapMobile : images.roadmap} />
          </Banner>
        </RoadmapWrap>
      </section>
    </>
  )
}
