import Aos from 'aos'
import 'aos/dist/aos.css'
import COLOR from 'constant/COLOR'
import images from 'constant/PICTURE/images'
import useAuth from 'hooks/useAuth'
import { useWallet } from 'hooks/useWallet'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useWeb3React } from '../../../packages/wagmi/src/useWeb3React'
import { GetMe } from 'service/UserService'
import { Divider, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import ReactDOM from 'react-dom/client'
import { QRCodeSVG } from 'qrcode.react'
import html2canvas from 'html2canvas'
import { url } from 'inspector'

const HeroSection = styled.div`
  width: 100vw;
  // height: 100vh;
  position: relative;
  background-image: url(${images.thumbnail});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  // @media (max-width: 575px) {
  //   height: 30vh;
  // }
  video {
    object-fit: cover;
  }
`
const BottomSection = styled.div`
  width: 100%;
  padding: 30px 0px;

  background: ${COLOR.bottom};

  position: absolute;
  bottom: 0px;
  .flexwrap {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
  }
  @media (max-width: 1200px) {
    padding: 15px 0px;
  }
  @media (max-width: 767px) {
    .flexwrap {
      gap: 25px;
    }
  }
`
const Button = styled.a`
  display: flex;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  background-image: url(${images.button});
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-width: 250px;
  color: ${COLOR.button};
  text-shadow: 0px 2px 0px ${COLOR.bttTextShadow};
  font-family: Anek Bangla;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
  width: fit-content;
  white-space: nowrap;
  touch-action: manipulation;
  transition: all 235ms ease-in-out;
  &:hover {
    text-decoration: none;
    color: ${COLOR.hoverBtt};
    transform: translate3d(0, 2px, 0);
    text-shadow: 0px 4px 4px ${COLOR.hoverYellow};
  }
  @media (max-width: 767px) {
    padding: 5px 15px;
    font-size: 14px;
    min-width: 135px;
  }
`

export const Hero = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 })
  }, [])
  const { connetSocial } = useAuth()
  const { account } = useWeb3React()
  const { onPresentConnectModal } = useWallet()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = async () => {
    await setIsModalOpen(true)
    createQRCode(200)
  }
  const handleOk = () => {
    setIsModalOpen(false)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const createQRCode = (size = 128) => {
    const token = localStorage.getItem('TokenGame')
    const qrCodeContainer = document.getElementById('qrCode')
    const root = ReactDOM.createRoot(qrCodeContainer)

    if (!token) {
      root.render(<p style={{ color: 'white' }}>Please, Login</p>)
      return null
    }
    root.render(<QRCodeSVG style={{ border: 'solid 3px white' }} value={token} size={size} />)
    return null
  }

  const downloadQRCode = () => {
    const qrCodeElement = document.getElementById('qrCode')
    if (qrCodeElement) {
      html2canvas(qrCodeElement).then((canvas) => {
        const link = document.createElement('a')
        link.href = canvas.toDataURL('image/png')
        link.download = 'qrcode.png'
        link.click()
      })
    }
  }

  const checkOpenGame = async () => {
    const token = localStorage.getItem('TokenGame')

    // const time = localStorage.getItem('ExpireDay')
    if (!account) {
      onPresentConnectModal()
      return
    }
    try {
      const data = await GetMe(token)
    } catch (error) {
      connetSocial()
      return
    }
    window.location.href = `https://game.darkknight.games/?token=${localStorage.getItem('TokenGame')}`

    // window.open(`https://game.darkknight.games/?token=${localStorage.getItem('TokenGame')}`)
  }
  // useEffect(() => {
  //   // if (!account) {
  //   //   onPresentConnectModal()
  //   // }
  //   checkOpenGame()
  // }, [])
  return (
    <HeroSection>
      <video width="100%" height="100%" autoPlay loop muted playsInline>
        <source src="/images/DarkKnight/VDemo.mp4" type="video/mp4"></source>
      </video>
      <BottomSection>
        <div className="flexwrap">
          <Button>GET A DARK</Button>
          <Button onClick={checkOpenGame}>PLAY NOW</Button>
          <Button onClick={showModal}>CONNECT APP</Button>
          <Modal onClose={handleCancel} isOpen={isModalOpen} isCentered>
            <ModalOverlay />
            <ModalContent
              style={{
                background: 'none',
                backgroundImage: `url(${images.bgConnectApp})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
            >
              <ModalHeader style={{ color: 'white', textAlign: 'center' }}>Scan To Connect App</ModalHeader>
              <ModalCloseButton style={{ color: 'white' }} />
              <Divider />
              <ModalBody style={{ textAlign: 'center' }}>
                <div
                  id="qrCode"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '20px 0',
                  }}
                ></div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button onClick={downloadQRCode}>Download</Button>
                </div>
              </ModalBody>
            </ModalContent>
          </Modal>
        </div>
      </BottomSection>
    </HeroSection>
  )
}
