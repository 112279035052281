import { Flex, Stack, Text } from '@chakra-ui/react'
import 'aos/dist/aos.css'
import BoxStore from 'components/BoxStore'
import COLOR from 'constant/COLOR'
import useResponsive from 'hooks/useResponsive'
import React from 'react'
import styled from 'styled-components'
import { DataMystery } from '../DataMystery'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
  padding: 100px 350px;
  @media (max-width: 1024px) {
    padding: 50px;
  }
  @media (max-width: 900px) {
    margin-top: 50px;
    padding: 20px;
  }
  @media (max-width: 575px) {
    font-size: 25px;
    padding: 20px;
    margin-top: 50px;
  }
`

const Section2: React.FC<React.PropsWithChildren> = () => {
  const { isMobile, isMobileOrTablet, isTabletOrLaptop, isBigScreen } = useResponsive()

  return (
    <>
      <Container>
        <Stack id="BoxStore">
          <BoxStore />
        </Stack>

        <Stack justifyContent="center" gap="30px">
          <Text color={COLOR.white} fontFamily="Anek Bangla" fontSize="38px" fontWeight={600} textAlign="center">
            How Do Mystery Boxes work?
          </Text>
          <Text
            color={COLOR.white}
            fontFamily="Anek Bangla"
            fontSize={isMobile ? '20px' : '25px'}
            fontWeight={400}
            textAlign="center"
          >
            Mystery Box gives you the chance to collect NFTs Cards at great prices. They are of limited number and for a
            limited time only.
          </Text>
          <Flex justifyContent="center" gap="30px" direction={isMobile ? 'column' : 'row'}>
            {DataMystery.map((i, index) => (
              <Stack
                key={index}
                bg={COLOR.bgButton}
                border={`1px solid ${COLOR.bttGetBox}`}
                borderRadius="20px"
                p="10px 30px 30px 30px"
              >
                <img style={{ width: '100%' }} src={i.img} alt="" />
                <Text
                  color={COLOR.white}
                  fontFamily="Anek Bangla"
                  fontSize={isMobileOrTablet ? '18px' : '22px'}
                  fontWeight={400}
                  textAlign="center"
                >
                  {i.desc}
                </Text>
              </Stack>
            ))}
          </Flex>
        </Stack>
      </Container>
    </>
  )
}

export default Section2
