import COLOR from 'constant/COLOR'
import images from 'constant/PICTURE/images'

export const BoxData = [
  {
    name: 'HERO BOX',
    img: `${images.heroBox}`,
    border: `${COLOR.borderHero}`,
    shadow: `${COLOR.shadowHero}`,
  },
  {
    name: 'WARRIOR BOX',
    img: `${images.championBox}`,
    border: `${COLOR.borderChampion}`,
    shadow: `${COLOR.shadowChampion}`,
  },

  {
    name: 'CHAMPION BOX',
    img: `${images.warriorBox}`,
    border: `${COLOR.borderWarrior}`,
    shadow: `${COLOR.shadowWarrior}`,
  },
]
