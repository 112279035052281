import { Box, Progress, Text } from '@chakra-ui/react'
import COLOR from 'constant/COLOR'
import useResponsive from 'hooks/useResponsive'

interface ProgressBarProps {
  current: number
  total: number
}

const ProgressBar: React.FC<ProgressBarProps> = ({ current, total }) => {
  const { isMobile, isMobileOrTablet, isTabletOrLaptop, isBigScreen } = useResponsive()

  return (
    <Box textAlign="center" mt={4} position="relative">
      <Progress
        colorScheme="cyan"
        value={(current / total) * 100}
        w="200px"
        border={`1px solid ${COLOR.bttGetBox}`}
        borderRadius="36px"
        bg={COLOR.bgButton}
        h="20px"
      />
      <Text
        position="absolute"
        left="40%"
        fontSize="15px"
        top="-50%"
        color={COLOR.white}
        fontFamily="Anek Bangla"
        fontWeight={800}
        mt={2}
      >{`${current}/${total}`}</Text>
    </Box>
  )
}

export default ProgressBar
